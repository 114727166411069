export const translations = {
  administration: {
    applySettings: {
      ar: 'تطبيق الإعدادات',
      cs: 'Použít nastavení',
      de: 'Einstellungen anwenden',
      en: 'Apply settings',
      fr: 'Appliquer les paramètres',
    },
    backlog: {
      deleteBacklog: {
        ar: 'هل تريد حذف عنصر قائمة المهام؟',
        cs: 'Smazat položku backlogu?',
        de: 'Backlog-Element löschen?',
        en: 'Delete backlog item?',
        fr: "Supprimer l'élément de backlog ?",
      },
      deleteModal: {
        ar: 'العنصر الذي توشك على حذفه له روابط بالطريق إلى {{number}}. هل أنت متأكد؟ الحذف لا يمكن التراجع عنه واسترداد العنصر.',
        cs: 'Položka backlogu, kterou chcete smazat má vazbu na {{number}} cestu. Jste si jistí? Smazání je nevratné.',
        de: 'Das Backlog-Element, das Sie eben löschen wollen, figuriert in {{number}} Pfad. Wollen Sie dies wirklich? Das Löschen ist nicht reversibel.',
        en: 'The backlog item you are about to delete figures in the {{number}} journey. Are you sure? Deleting it is irreversible.',
        fr: "L'élément de backlog que vous êtes sur le point de supprimer figure dans {{number}} parcours. Êtes-vous sûr ? La suppression est irréversible.",
      },
      emptyDeleteModalDescription: {
        ar: 'هل أنت متأكد أنك تريد حذف عنصر من عناصر قائمة المهام؟ الحذف لا رجعة فيه ',
        cs: 'Opravdu si ji přejete smazat položku backlogu? Smazání je nevratné.',
        de: 'Wollen Sie das Backlog-Item wirklich löschen? Das Löschen ist nicht reversibel.',
        en: 'Do you really want to delete backlog item? The deletion is irreversible.',
        fr: 'Désirez-vous vraiment supprimer l’élément de backlog ? La suppression est irréversible.',
      },
      estimation: {
        ar: 'تقدير كثافة العمل',
        cs: 'Odhad pracnosti',
        de: 'Arbeitsaufwandschätzung',
        en: 'Workload estimation',
        fr: 'Estimation',
      },
      id: {
        ar: 'المعرف',
        cs: 'ID',
        de: 'ID',
        en: 'ID',
        fr: 'ID',
      },
    },
    limits: {
      backlog: {
        acceptanceCriterium: {
          ar: 'معيار القبول:',
          cs: 'Akceptační kritérium:',
          de: 'Akzeptationskriterium:',
          en: 'Acceptance criteria:',
          fr: "Critère d'acceptation :",
        },
        backlogLimit: {
          ar: '3000 أقصى قيمة ممكنة هي',
          cs: 'Maximální možná hodnota je 3 000',
          de: 'Der höchstzulässige Wert ist 3000',
          en: 'The maximum possible value is 3,000',
          fr: 'La valeur maximum possible est de 3000',
        },
        backlogName: {
          ar: 'الاسم:',
          cs: 'Název:',
          de: 'Name:',
          en: 'Name:',
          fr: 'Nom :',
        },
        limitBacklog: {
          ar: 'حدود طول الحقل - قائمة المهام',
          cs: 'Limit délky polí - Backlog',
          de: 'Feldlänge-Limit - Backlog',
          en: 'Field length limits - Backlog',
          fr: 'Limites de longueur de champs - Backlog',
        },
        title: {
          ar: 'الوصف:',
          cs: 'Popis:',
          de: 'Beschreibung:',
          en: 'Description:',
          fr: 'Description :',
        },
      },
      journey: {
        authorName: {
          ar: 'ما سمعناه - اسم المؤلف:',
          cs: 'Co o nás říkají - jméno autora:',
          de: 'Was sie über uns sagen - Name des Autors:',
          en: "What they say about us - Author's name:",
          fr: "Ce qu'ils disent sur nous – nom de l'auteur :",
        },
        authorTitle: {
          ar: 'ما سمعناه - وصف المؤلف:',
          cs: 'Co o nás říkají - popis autora:',
          de: 'Was sie über uns sagen - Beschreibung des Autors:',
          en: "What they say about us - Author's description:",
          fr: "Ce qu'ils disent sur nous – description de l'auteur :",
        },
        comment: {
          ar: 'ما سمعناه - تعليق:',
          cs: 'Co o nás říkají - komentář:',
          de: 'Was sie über uns sagen - Kommentar:',
          en: 'What they say about us - Comment:',
          fr: "Ce qu'ils disent sur nous - commentaire :",
        },
        content: {
          ar: 'محتوى النص والصورة:',
          cs: 'Obsah T&I:',
          de: 'Inhalt T&B:',
          en: 'T&I contents:',
          fr: 'Contenu T&I :',
        },
        journeyLimit: {
          ar: 'أقصى قيمة ممكنة هي 3000',
          cs: 'Maximální možná hodnota je 3 000',
          de: 'Der höchstzulässige Wert ist 3000',
          en: 'The maximum possible value is 3,000',
          fr: 'La valeur maximum possible est de 3000',
        },
        journeyName: {
          ar: 'اسم الرحلة:',
          cs: 'Název cesty:',
          de: 'Pfadname:',
          en: "Journey's name:",
          fr: 'Nom du parcours :',
        },
        journeyTitle: {
          ar: 'وصف الرحلة:',
          cs: 'Popis cesty:',
          de: 'Pfad-Beschreibung:',
          en: 'Journey description:',
          fr: 'Description du parcours :',
        },
        limitJourney: {
          ar: 'حدود طول الحقل - رحلات',
          cs: 'Limit délky polí - Cesty',
          de: 'Feldlänge-Limit - Pfade',
          en: 'Field length limits - Journeys',
          fr: 'Limites de longueur de champs - Parcours',
        },
        nameLayer: {
          ar: 'اسم الطبقة:',
          cs: 'Název vrstvy:',
          de: 'Layer-Name:',
          en: 'Layer name:',
          fr: 'Nom de la couche :',
        },
        otherLayer: {
          ar: 'محتوى الطبقات الأخرى (طبقات بوليت، وصف الحل):',
          cs: 'Obsah ostatních vrstev (Bulet vrstvy, popis solution):',
          de: 'Inhalt der übrigen Layer (Layer-Bullet, Solution-Beschreibung):',
          en: 'Contents of other layers (bullet layers, solution description):',
          fr: "Contenu d'autres couches (couche bullet, description de la solution) :",
        },
        phaseSubphase: {
          ar: 'الطور/ الطور الفرعي/ نقطة اتصال:',
          cs: 'Fáze / Subfáze /TP:',
          de: 'Phase / Sub-Phase /TP:',
          en: 'Phase / Subphase / TP:',
          fr: 'Phase / Sous-phase / TP :',
        },
        pointTitle: {
          ar: 'منحنى عاطفي - وصف النقطة: ',
          cs: 'Emoční křivka - popis bodu:',
          de: 'Emotionskurve - Punktbeschreibung:',
          en: 'Emotional Journey - Point description:',
          fr: 'Parcours émotionnel – description du point:',
        },
        solution: {
          ar: 'عنوان النص ت و ي T&I  والحل:',
          cs: 'Nadpis T&I, Solution:',
          de: 'Überschrift T&B, Solution:',
          en: 'T&I Title, solution:',
          fr: 'Titre T&I, solution :',
        },
        titleLayer: {
          ar: 'وصف الطبقة:',
          cs: 'Popis vrstvy:',
          de: 'Layer-Beschreibung:',
          en: 'Layer description:',
          fr: 'Description de la couche :',
        },
      },
      other: {
        limitOther: {
          ar: 'حدود طول الحقل - غير ذلك',
          cs: 'Limit délky polí - Ostatní',
          de: 'Feldlänge-Limit - Sonstiges',
          en: 'Field length limits - Other',
          fr: 'Limites de longueur de champs - Autres',
        },
        otherLimit: {
          ar: '3000 أقصى قيمة ممكنة هي',
          cs: 'Maximální možná hodnota je 3 000',
          de: 'Der höchstzulässige Wert ist 3000',
          en: 'The maximum possible value is 3,000',
          fr: 'La valeur maximum possible est de 3000',
        },
        otherName: {
          ar: 'أسماء المجلدات والأطالس:',
          cs: 'Názvy složek a atlasů:',
          de: 'Ordner- und Atlas-Namen:',
          en: 'Names of folders and atlases:',
          fr: "Noms de dossiers et d'atlas :",
        },
      },
      persona: {
        contentFiled: {
          ar: 'محتوى 5  حقول اختيارية:',
          cs: 'Obsah u volitelných polí:',
          de: 'Inhalt bei wählbaren Feldern:',
          en: 'Contents of 5 optional fields:',
          fr: 'Contenu des champs à option:',
        },
        contentFixField: {
          ar: 'محتوى 5 حقول  ثابتة:',
          cs: 'Obsah u 5 fixních polí:',
          de: 'Inhalt bei 5 fixen Feldern:',
          en: 'Contents of 5 fixed fields:',
          fr: 'Contenu des 5 champs fixes :',
        },
        limitPersony: {
          ar: 'حدود طول الحقل - شخصيات',
          cs: 'Limit délky polí - Persony',
          de: 'Feldlänge-Limit - Personen',
          en: 'Field length limits - Personas',
          fr: 'Limites de longueur de champs - Personas',
        },
        nameFiled: {
          ar: 'العنوان عند الحقول الاختيارية:',
          cs: 'Nadpis u volitelných polí:',
          de: 'Überschrift bei wählbaren Feldern:',
          en: 'Heading of 5 optional fields:',
          fr: 'Titre des champs à option :',
        },
        nameFixField: {
          ar: 'العنوان عند 5 حقول ثابتة:',
          cs: 'Nadpis u 5 fixních polí:',
          de: 'Überschrift bei 5 fixen Feldern:',
          en: 'Heading of 5 fixed fields:',
          fr: 'Titre des 5 champs fixes :',
        },
        personaLimit: {
          ar: '3000 أقصى قيمة ممكنة هي',
          cs: 'Maximální možná hodnota je 3 000',
          de: 'Der höchstzulässige Wert ist 3000',
          en: 'The maximum possible value is 3000',
          fr: 'La valeur maximum possible est de 3000',
        },
        personaName: {
          ar: 'اسم الشخصية:',
          cs: 'Název persony:',
          de: 'Personenbezeichnung:',
          en: 'Name of persona:',
          fr: 'Nom du persona:',
        },
        personaTitle: {
          ar: 'وصف الشخصية:',
          cs: 'Popis persony:',
          de: 'Beschreibung der Persona:',
          en: 'Persona description:',
          fr: 'Description du persona:',
        },
      },
    },
    placeholder: {
      arrayFive: {
        ar: '5 العنوان',
        cs: 'Nadpis 5',
        de: 'Überschrift 5',
        en: 'Heading 5',
        fr: 'Titre 5',
      },
      arrayFour: {
        ar: '4 العنوان',
        cs: 'Nadpis 4',
        de: 'Überschrift 4',
        en: 'Heading 4',
        fr: 'Titre 4',
      },
      arrayOne: {
        ar: '1 العنوان',
        cs: 'Nadpis 1',
        de: 'Überschrift 1',
        en: 'Heading 1',
        fr: 'Titre 1',
      },
      arrayThree: {
        ar: '3 العنوان',
        cs: 'Nadpis 3',
        de: 'Überschrift 3',
        en: 'Heading 3',
        fr: 'Titre 3',
      },
      arrayTwo: {
        ar: '2 العنوان',
        cs: 'Nadpis 2',
        de: 'Überschrift 2',
        en: 'Heading 2',
        fr: 'Titre 2',
      },
      colorOfTenant: {
        ar: 'اللون',
        cs: 'Barva',
        de: 'Farbe',
        en: 'Color',
        fr: 'Couleur',
      },
      email: {
        ar: 'بريد إلكتروني',
        cs: 'E-mail',
        de: 'E-Mail',
        en: 'Email',
        fr: 'E-mail',
      },
      workBacklog: {
        ar: 'اسم الوحدة',
        cs: 'Název jednotky',
        de: 'Name der Einheit',
        en: 'Unit name',
        fr: "Nom de l'unité",
      },
    },
    tenant: {
      applicationLogo: {
        ar: '',
        cs: '',
        de: '',
        en: 'Application logo',
        fr: '',
      },
      arrayFive: {
        ar: 'حقل 5:',
        cs: 'Pole 5:',
        de: 'Feld 5:',
        en: 'Field 5:',
        fr: 'Champ 5 :',
      },
      arrayFour: {
        ar: 'حقل 4:',
        cs: 'Pole 4:',
        de: 'Feld 4:',
        en: 'Field 4:',
        fr: 'Champ 4 :',
      },
      arrayOne: {
        ar: 'حقل 1:',
        cs: 'Pole 1:',
        de: 'Feld 1:',
        en: 'Field 1:',
        fr: 'Champ 1 :',
      },
      arrayThree: {
        ar: 'حقل 3:',
        cs: 'Pole 3:',
        de: 'Feld 3:',
        en: 'Field 3:',
        fr: 'Champ 3 :',
      },
      arrayTwo: {
        ar: 'حقل 2:',
        cs: 'Pole 2:',
        de: 'Feld 2:',
        en: 'Field 2:',
        fr: 'Champ 2 :',
      },
      categories: {
        ar: 'التصنيف',
        cs: 'Kategorie',
        de: 'Kategorie',
        en: 'Categories',
        fr: 'Catégories',
      },
      category: {
        addCategory: {
          ar: 'فئة جديدة',
          cs: 'Nová kategorie',
          de: 'Neue Kategorie',
          en: 'New category',
          fr: 'Nouvelle catégorie',
        },
        deleteCategory: {
          description: {
            ar: 'هل تريد حقًا حذف الفئة <strong>"{{name}}"</strong>؟',
            cs: 'Opravdu chcete odstranit kategorii <strong>"{{name}}"</strong>?',
            de: 'Wollen Sie wirklich die Kategorie <strong>"{{name}}"</strong> entfernen?',
            en: 'Do you really want to delete the category <strong>"{{name}}"</strong>?',
            fr: 'Voulez-vous vraiment supprimer la catégorie <strong>« {{name}} »</strong> ?',
          },
          title: {
            ar: 'هل تريد حذف الفئة؟',
            cs: 'Odstranit kategorii',
            de: 'Kategorie entfernen',
            en: 'Delete the category?',
            fr: 'Supprimer la catégorie ?',
          },
        },
        editCategory: {
          ar: 'تعديل القسم',
          cs: 'Editovat kategorii',
          de: 'Kategorie bearbeiten',
          en: 'Edit category',
          fr: 'Modifier la catégorie',
        },
        lettersAndSpacesOnly: {
          ar: 'الحروف والمسافات فقط',
          cs: 'Pouze písmena a mezery',
          de: 'Nur Buchstaben und Leerzeichen',
          en: 'Letters and spaces only',
          fr: 'Lettres et espaces uniquement',
        },
      },
      colorOfTenant: {
        ar: 'لون المحركات:',
        cs: 'Barva akčních prvků:',
        de: 'Farbe der Aktionselemente:',
        en: "Actuators' color:",
        fr: "Couleur des éléments d'action :",
      },
      confirmChanges: {
        ar: 'تطبيق التغييرات',
        cs: 'Aplikovat změny',
        de: 'Änderungen anwenden',
        en: 'Apply changes',
        fr: 'Appliquer les modifications',
      },
      email: {
        ar: 'ملاحظات البريد الإلكتروني:',
        cs: 'E-mail zpětné vazby',
        de: 'Feedback-E-Mail',
        en: 'Feedback email',
        fr: 'Courriel de la rétroaction',
      },
      estimateUnit: {
        ar: 'وحدة تقديرات الأعمال المتأخرة',
        cs: 'Jednotka pracnosti backlogu',
        de: 'Einheit für Backlog-Arbeitsaufwand',
        en: 'Unit for backlog estimates',
        fr: 'Unité pour les estimations du backlog',
      },
      kpmgLogo: {
        ar: 'عرض شعار كيه بي إم جي',
        cs: 'Zobrazovat logo KPMG',
        de: 'KPMG-Logo abbilden',
        en: 'Show KPMG logo',
        fr: 'Afficher le logo de KPMG',
      },
      localizationLanguage: {
        ar: 'التوطين الافتراضي',
        cs: 'Výchozí lokalizace',
        de: 'Standardlokalisierung',
        en: 'Default localization',
        fr: 'Localisation par défaut',
      },
      manageTenant: {
        ar: 'إدارة المستأجر',
        cs: 'Správa tenanta',
        de: 'Tenant-Verwaltung',
        en: 'Management of Tenant',
        fr: 'Administration du tenant',
      },
      nameOfCustomer: {
        ar: 'اسم الزبون',
        cs: 'Název zákazníka',
        de: 'Name des Kunden',
        en: 'Customer name',
        fr: 'Nom du client',
      },
      personaTittle: {
        ar: 'الشخصيات - عناوين الحقول الثابتة',
        cs: 'Persony - nadpisy fixních polí',
        de: 'Personen - Überschriften der fixen Felder',
        en: 'Persona - fixed field headings',
        fr: 'Personas - titres de champs fixes',
      },
      personas: {
        ar: 'الأفراد',
        cs: 'Persony',
        de: 'Personen',
        en: 'Personas',
        fr: 'Personas',
      },
      personasDetail: {
        ar: 'العناوين المحددة مسبقًا',
        cs: 'Předdefinované nadpisy',
        de: 'Vordefinierte Überschriften',
        en: 'Predefined headings',
        fr: 'Titres prédéfinis',
      },
      showLogo: {
        ar: '',
        cs: '',
        de: '',
        en: 'Show KPMG logo',
        fr: '',
      },
      templateCategories: {
        ar: 'فئات القالب',
        cs: 'Kategorie šablon',
        de: 'Vorlagen-Kategorie',
        en: 'Template categories',
        fr: 'Catégories de modèles',
      },
      tenantDescription: {
        ar: 'وصف المستأجر',
        cs: 'Popis tenanta',
        de: 'Tenant-Beschreibung',
        en: 'Tenant description',
        fr: 'Description du tenant',
      },
      tenantDescriptionPlaceholder: {
        ar: 'إدخال الوصف',
        cs: 'Popis',
        de: 'Beschreibung',
        en: 'Enter description',
        fr: 'Saisir la description',
      },
      tenantDetail: {
        ar: 'تفاصيل المستأجر',
        cs: 'Detaily tenanta',
        de: 'Tenant-Details',
        en: 'Tenant details',
        fr: 'Détail du tenant',
      },
      tenantSector: {
        ar: 'قطاع المستأجر',
        cs: 'Sektor tenanta',
        de: 'Tenant-Sektor',
        en: 'Tenant sector',
        fr: 'Secteur du tenant',
      },
      useAsTemplate: {
        ar: 'استخدم كقالب',
        cs: 'Použít jako šablonu',
        de: 'Als Vorlage verwenden',
        en: 'Use as template',
        fr: 'Utiliser comme modèle',
      },
      workBacklog: {
        ar: 'وحدة لتقديرات جهود العمل لدى قائمة المهام:',
        cs: 'Jednotka pracnosti backlogu:',
        de: 'Einheit für Backlog-Arbeitsaufwand:',
        en: 'Unit for backlog estimates:',
        fr: 'Unité pour les estimations du backlog :',
      },
    },
    usersAndGroups: {
      action: {
        ar: 'الفعل',
        cs: 'Akce',
        de: 'Aktion',
        en: 'Action',
        fr: 'Action',
      },
      groups: {
        changeGroupNameModal: {
          error: {
            content: {
              ar: 'حاول مرة أخرى أو لاحقاً',
              cs: 'Zkuste to znovu nebo později.',
              de: 'Versuchen Sie es erneut oder später.',
              en: 'Try it again or try it later.',
              fr: 'Réessayez ou essayez plus tard.',
            },
            title: {
              ar: 'فشل تغيير اسم المجموعة',
              cs: 'Změna názvu skupiny se nezdařila',
              de: 'Änderung des Gruppennamens fehlgeschlagen',
              en: "Changing group's name failed",
              fr: 'Échec du changement de nom du groupe',
            },
          },
          form: {
            inputLabel: {
              ar: 'اسم جديد',
              cs: 'Nový název',
              de: 'Neuer Name',
              en: 'New name',
              fr: 'Nouveau nom',
            },
          },
          success: {
            content: {
              ar: 'تم تغيير اسم المجموعة بنجاح.',
              cs: 'Název skupiny byl úspěšně změněn.',
              de: 'Der Gruppenname wurde erfolgreich geändert.',
              en: 'Group name changed successfully.',
              fr: 'Le nom du groupe a été modifié avec succès.',
            },
          },
          title: {
            ar: 'تغيير الاسم',
            cs: 'Změna názvu',
            de: 'Den Namen ändern',
            en: 'Change name',
            fr: 'Changer le nom',
          },
        },
        createGroupModal: {
          error: {
            content: {
              ar: 'حاول مرة أخرى أو لاحقاً',
              cs: 'Zkuste to znovu nebo později.',
              de: 'Versuchen Sie es erneut oder später.',
              en: 'Try it again or try it later.',
              fr: 'Réessayez ou essayez plus tard.',
            },
            title: {
              ar: 'فشل إنشاء مجموعة ',
              cs: 'Vytvoření skupiny se nezdařilo',
              de: 'Erstellen einer Gruppe fehlgeschlagen',
              en: 'Creating group failed',
              fr: 'La création du groupe a échoué',
            },
          },
          success: {
            content: {
              ar: 'تم بنجاح انشاء المجموعة <strong>"{{name}}"</strong>.',
              cs: 'Skupina <strong>"{{name}}"</strong> byla úspěšně vytvořena.',
              de: 'Die Gruppe <strong>"{{name}}"</strong> wurde erfolgreich erstellt.',
              en: 'Group <strong>"{{name}}"</strong> has been successfully created.',
              fr: 'Le groupe <strong>"{{name}}"</strong> a été créé avec succès.',
            },
          },
          title: {
            ar: 'انشاء مجموعة جديدة',
            cs: 'Vytvoření nové skupiny',
            de: 'Erstellen einer neuen Gruppe',
            en: 'Create a new group',
            fr: 'Créer un nouveau groupe',
          },
        },
        deleteGroupModal: {
          content: {
            ar: 'هل تريد فعلاً حذف المجموعة <strong>"{{name}}"</strong>؟',
            cs: 'Opravdu chcete odstranit skupinu <strong>"{{name}}"</strong>?',
            de: 'Wollen Sie die Gruppe <strong>"{{name}}"</strong> wirklich entfernen?',
            en: 'Do you really want to delete group <strong>"{{name}}"</strong>?',
            fr: 'Voulez-vous vraiment supprimer le groupe <strong>"{{name}}"</strong>?',
          },
          error: {
            content: {
              ar: 'حاول مرة أخرى أو لاحقاً',
              cs: 'Zkuste to znovu nebo později.',
              de: 'Versuchen Sie es erneut oder später.',
              en: 'Try it again or try it later.',
              fr: 'Réessayez ou essayez plus tard.',
            },
            title: {
              ar: 'لم يتم حذف المجموعة ',
              cs: 'Odstranění skupiny se nezdařilo',
              de: 'Entfernen der Gruppe fehlgeschlagen',
              en: 'Deleting group failed',
              fr: 'La suppression du groupe a échoué',
            },
          },
          groupWithoutNamePlaceholder: {
            ar: 'مجموعة بدون اسم',
            cs: 'Skupina bez názvu',
            de: 'Gruppe ohne Namen',
            en: 'Group without name',
            fr: 'Groupe sans nom',
          },
          success: {
            content: {
              ar: 'تم بنجاح حذف مجموعة  <strong>"{{name}}"</strong>.',
              cs: 'Skupina <strong>"{{name}}"</strong> byla úspěšně odstraněna.',
              de: 'Die Gruppe <strong>"{{name}}"</strong> wurde erfolgreich entfernt.',
              en: 'Group <strong>"{{name}}"</strong> has been successfully deleted.',
              fr: 'Le groupe <strong>"{{name}}"</strong> a été supprimé avec succès.',
            },
            title: {
              ar: 'عملية حذف مجموعة ',
              cs: 'Odstranění skupiny',
              de: 'Entfernen der Gruppe',
              en: 'Delete the group',
              fr: 'Supprimer le groupe',
            },
          },
          title: {
            ar: 'أحذف هذه المجموعة',
            cs: 'Odstranit skupinu',
            de: 'Gruppe entfernen',
            en: 'Delete group',
            fr: 'Supprimer le groupe',
          },
        },
        groupExistsError: {
          ar: 'هناك مجموعة أخرى بهذا الاسم ',
          cs: 'Skupina s vybraným názvem již existuje!',
          de: 'Gruppe mit ausgewähltem Namen existiert bereits!',
          en: 'Group with selected name exists already!',
          fr: 'Le groupe portant le nom sélectionné existe déjà !',
        },
        groups: {
          actions: {
            changeName: {
              ar: 'تغيير الاسم',
              cs: 'Změnit název',
              de: 'Namen ändern',
              en: 'Change name',
              fr: 'Modifier le nom',
            },
            deleteGroup: {
              ar: 'احذف هذه المجموعة',
              cs: 'Odstranit skupinu',
              de: 'Gruppe entfernen',
              en: 'Delete group',
              fr: 'Supprimer le groupe',
            },
            setAtlas: {
              ar: 'إعداد الاطلس ',
              cs: 'Nastavit atlas',
              de: 'Atlas einstellen',
              en: 'Set atlas',
              fr: "Définir l'atlas",
            },
          },
          table: {
            group: {
              ar: 'مجموعة',
              cs: 'Skupina',
              de: 'Gruppe',
              en: 'Group',
              fr: 'Groupe',
            },
          },
        },
        setAtlasModal: {
          bundleActionText: {
            ar: 'تطبيق الحالة على الأطالس المحددة:',
            cs: 'Použít stav pro vybrané atlasy:',
            de: 'Den Stand für ausgewählte Atlasse anwenden:',
            en: 'Apply status for selected atlases:',
            fr: 'Appliquer le statut pour les atlas sélectionnés :',
          },
          description: {
            ar: 'اسم المجموعة/ حدد حالة الأطلس (أو عدد من الأطالس) الذي تريد تعيينها لمجموعة <strong>"{{groupName}}"</strong>.',
            cs: 'Název skupiny: Vyberte status atlasu(ů), který chcete nastavit pro <br/>skupinu <strong>"{{groupName}}"</strong>.',
            de: 'Gruppenname: Wählen Sie den Status des/der Atlasses/Atlasse, den Sie für <br/>die Gruppe <strong>"{{groupName}}"</strong> setzen möchten.',
            en: 'Please select the status of the atlas(es) you want to set for <br/>the group <strong>"{{groupName}}"</strong>.',
            fr: 'Veuillez sélectionner le statut des atlas que vous souhaitez définir pour <br/>le groupe <strong>"{{groupName}}"</strong>.',
          },
          error: {
            content: {
              ar: 'حاول مرة أخرى أو لاحقاً.',
              cs: 'Zkuste to znovu nebo později.',
              de: 'Versuchen Sie es erneut oder später.',
              en: 'Try it again or try it later.',
              fr: 'Réessayez ou essayez plus tard.',
            },
            title: {
              ar: 'فشل تغيير الاطلس (أو الأطالس)',
              cs: 'Změna atlasu/ů skupiny se nezdařila',
              de: 'Das Ändern des/der Atlasses/Atlasse der Gruppe fehlgeschlagen',
              en: "Changing group's atlas/es failed",
              fr: "La modification de l'atlas/des atlas du groupe a échoué",
            },
          },
          table: {
            atlases: {
              ar: 'الأطالس',
              cs: 'Atlasy',
              de: 'Atlasse',
              en: 'Atlases',
              fr: 'Atlas',
            },
          },
          title: {
            ar: 'إعداد الاطالس',
            cs: 'Nastavení atlasů',
            de: 'Einstellung der Atlasse',
            en: 'Settings atlases',
            fr: 'Paramètres des atlas',
          },
        },
        status: {
          editable: {
            ar: 'قابل للتعديل ',
            cs: 'Editovatelný',
            de: 'Bearbeitbar',
            en: 'Editable',
            fr: 'Modifiable',
          },
          invisible: {
            ar: 'خفى، لا يرى',
            cs: 'Neviditelný',
            de: 'Unsichtbar',
            en: 'Invisible',
            fr: 'Non visible',
          },
          visible: {
            ar: 'قابل للقراءة فقط',
            cs: 'Pouze pro čtení',
            de: 'Schreibgeschützt',
            en: 'Read only',
            fr: 'Lecture seule',
          },
        },
      },
      modeSwitch: {
        groups: {
          ar: 'المجموعات',
          cs: 'Skupiny',
          de: 'Gruppen',
          en: 'Groups',
          fr: 'Groupes',
        },
        users: {
          ar: 'المستخدمون',
          cs: 'Uživatelé',
          de: 'Benutzer',
          en: 'Users',
          fr: 'Utilisateurs',
        },
      },
      searchBar: {
        atlas: {
          ar: 'البحث عن أطلس',
          cs: 'Vyhledat atlas',
          de: 'Atlas suchen',
          en: 'Search atlas',
          fr: 'Rechercher un atlas',
        },
        groups: {
          ar: 'البحث عن المجموعة',
          cs: 'Vyhledat skupinu',
          de: 'Gruppe suchen',
          en: 'Search group',
          fr: 'Recherche par groupe',
        },
        tenant: {
          ar: 'البحث عن مكان الإستئجار',
          cs: 'Vyhledat tenant',
          de: 'Tenant suchen',
          en: 'Search tenant',
          fr: "Recherche d'un tenant",
        },
      },
      setToChecked: {
        ar: 'ترتيب الأمر للمختارين',
        cs: 'Vybraným nastavit',
        de: 'Bei Ausgewählten einstellen',
        en: 'Set to checked',
        fr: 'Paramétrer la selection',
      },
      setUnset: {
        set: {
          ar: 'تمت عملية الإعداد ',
          cs: 'Nastaveno',
          de: 'Eingestellt',
          en: 'Set',
          fr: 'Défini',
        },
        unset: {
          ar: 'لم يتم الاعداد بعد',
          cs: 'Nenastaveno',
          de: 'Nicht eingestellt',
          en: 'Not set',
          fr: 'Non défini',
        },
      },
      statusDescription: {
        group: {
          allSet: {
            ar: 'تم إعداد الأطلس',
            cs: 'Atlas nastaven',
            de: 'Atlas eingestellt',
            en: 'Atlas set',
            fr: 'Atlas activé',
          },
          none: {
            ar: 'لم يتم اعداد الأطلس',
            cs: 'Atlas není nastaven',
            de: 'Atlas nicht eingestellt',
            en: 'Atlas not set',
            fr: 'Atlas non activé',
          },
          set: {
            ar: 'تم إعداد الأطلس',
            cs: 'Atlas nastaven',
            de: 'Atlas eingestellt',
            en: 'Atlas set',
            fr: 'Atlas activé',
          },
        },
        kpmg: {
          allSet: {
            ar: 'تم إعداد جميع المستأجرين',
            cs: 'Všichni tenanti nastaveni',
            de: 'Alle Tenants eingestellt',
            en: 'All tenants set',
            fr: 'Tous les tenants activés',
          },
          none: {
            ar: 'لم يتم إعداد مكان الاستئجار',
            cs: 'Tenant není nastaven',
            de: 'Tenant nicht eingestellt',
            en: 'Tenant not set',
            fr: 'Tenant non activé',
          },
          set: {
            ar: 'تم إعداد مكان الاستئجار',
            cs: 'Tenant nastaven',
            de: 'Tenant eingestellt',
            en: 'Tenant set',
            fr: 'Tenant défini',
          },
        },
        tenant: {
          allSet: {
            ar: 'تم اعداد كافة المجموعات',
            cs: 'Všechny skupiny nastaveny',
            de: 'Alle Gruppen eingestellt',
            en: 'All groups set',
            fr: 'Tous les groupes définis',
          },
          none: {
            ar: 'لم يتم إعداد المجموعة',
            cs: 'Skupina není nastavena',
            de: 'Gruppe nicht eingestellt',
            en: 'Group not set',
            fr: 'Groupe non défini',
          },
          set: {
            ar: 'تم اعداد المجموعة',
            cs: 'Skupina nastavena',
            de: 'Gruppe eingestellt',
            en: 'Group set',
            fr: 'Groupe défini',
          },
        },
      },
      userSwitch: {
        kpmgUsers: {
          ar: 'داخلي',
          cs: 'Interní',
          de: 'Intern',
          en: 'Internal',
          fr: 'Interne',
        },
        tenantUsers: {
          ar: 'خارجي',
          cs: 'Externí',
          de: 'Extern',
          en: 'External',
          fr: 'Externe',
        },
      },
      users: {
        allTenants: {
          ar: 'جميع المستأجرين',
          cs: 'Přehled tenantů',
          de: 'Tenants im Überblick',
          en: 'All tenants',
          fr: 'Tous les tenants',
        },
        chooseTenant: {
          ar: 'اختيار  مكان الاستئجار',
          cs: 'Zvolit tenant',
          de: 'Tenant auswählen',
          en: 'Choose tenant',
          fr: 'Choisir un tenant',
        },
        createUserModal: {
          error: {
            aadUserNotFoundInOrganization: {
              title: {
                ar: 'مستخدم غير موجود داخل المؤسسة',
                cs: 'Neexistující uživatel v rámci organizace',
                de: 'Nicht vorhandener Benutzer innerhalb der Organisation',
                en: 'Non-existent user within organization',
                fr: "Utilisateur inexistant au sein de l'organisation",
              },
            },
            default: {
              content: {
                ar: 'فشل في إضافة مستخدم جديد. حاول مرة أخرى أو حاول لاحقًا.',
                cs: 'Nepodařilo se přidat nového uživatele. Zkuste to znovu nebo to zkuste později.',
                de: 'Der neue Benutzer konnte nicht hinzugefügt werden. Versuchen Sie es erneut oder zu einem späteren Zeitpunkt',
                en: "A new user couldn't be added. Try again or try later.",
                fr: 'Échec de l’ajout du nouvel utilisateur. Réessayez, maintenant ou plus tard.',
              },
              title: {
                ar: '‫خطأ‬',
                cs: 'Chyba',
                de: 'Fehler',
                en: 'Error',
                fr: 'Erreur',
              },
            },
          },
          form: {
            email: {
              ar: 'البريد الإلكتروني لمستخدم جديد',
              cs: 'E-mail nového uživatele',
              de: 'E-Mail eines neuen Benutzers',
              en: "New user's email address",
              fr: 'Adresse courriel du nouvel utilisateur',
            },
            emailLabel: {
              ar: 'عنوان الإيميل',
              cs: 'E-mailová adresa',
              de: 'E-Mail-Adresse',
              en: 'Email address',
              fr: 'Adresse e-mail',
            },
            emailPlaceholder: {
              ar: 'john.smith@example.com',
              cs: 'john.smith@example.com',
              de: 'john.smith@example.com',
              en: 'john.smith@example.com',
              fr: 'john.smith@example.com',
            },
            roleLabel: {
              ar: 'الدور',
              cs: 'Role',
              de: 'Rolle',
              en: 'Role',
              fr: 'Rôle',
            },
            tenantLabel: {
              ar: 'مكان الاستئجار',
              cs: 'Tenant',
              de: 'Tenant',
              en: 'Tenant',
              fr: 'Tenant',
            },
          },
          success: {
            content: {
              ar: 'تمت إضافة المستخدم ذو البريد الإلكتروني<strong>"{{email}}"</strong>  بنجاح.',
              cs: 'Uživatel s e-mailem <strong>"{{email}}"</strong> byl úspěšně vytvořen.',
              de: 'Der Benutzer mit der E-Mail <strong>"{{email}}"</strong> wurde erfolgreich erstellt.',
              en: 'A user with the email address <strong>"{{email}}"</strong> has been created.',
              fr: 'L’utilisateur ayant l’adresse courriel <strong>"{{email}}"</strong> a été créé.',
            },
          },
          title: {
            ar: 'إضافة مستخدم جديد',
            cs: 'Vytvoření nového uživatele',
            de: 'Erstellen eines neuen Benutzers',
            en: 'Create a new user',
            fr: 'Créer un nouvel utilisateur',
          },
        },
        deleteUserModal: {
          content: {
            ar: 'هل ترغب حقًا في حذف المستخدم <strong>"{{name}}"</strong>؟ لن يعود المستخدم قادرًا على تسجيل الدخول.',
            cs: 'Opravdu si přejete smazat uživatele <strong>"{{name}}"</strong>? Uživatel se nebude moci přihlásit.',
            de: 'Möchten Sie den Benutzer <strong>"{{name}}"</strong> wirklich löschen? Der Benutzer wird sich dann nicht anmelden können.',
            en: 'Do you really want to delete the user <strong>"{{name}}"</strong>? The user will no longer be able to log in.',
            fr: 'Souhaitez-vous vraiment supprimer l’utilisateur <strong>"{{name}}"</strong>? L’utilisateur ne pourra pas se connecter.',
          },
          error: {
            content: {
              ar: 'غير قادر على إضافة مستخدم بالبريد الإلكتروني <strong>"{{email}}"</strong>. حاول مرة أخرى أو حاول لاحقًا',
              cs: 'Uživatele s e-mailem <strong>"{{email}}"</strong> nelze přidat. Zkuste to znovu nebo to zkuste později.',
              de: 'Der Benutzer mit der E-Mail-Adresse <strong>"{{email}}"</strong> kann nicht hinzugefügt werden. Versuchen Sie es erneut oder zu einem späteren Zeitpunkt',
              en: 'The user with email <strong>"{{email}}"</strong> can\'t be added. Try again or try later.',
              fr: 'Impossible d’ajouter un utilisateur dont l’adresse électronique est <strong>"{{email}}"</strong>. Réessayez, maintenant ou plus tard.',
            },
            title: {
              ar: 'حدث خطأ',
              cs: 'Vyskytla se chyba',
              de: 'Ein Fehler ist aufgetreten',
              en: 'An error occurred',
              fr: 'Une erreur s’est produite',
            },
          },
          title: {
            ar: 'هل تريد حذف مستخدم؟',
            cs: 'Smazat uživatele?',
            de: 'Den Benutzer löschen?',
            en: 'Delete the user?',
            fr: 'Supprimer l’utilisateur ?',
          },
        },
        newGroup: {
          ar: 'مجموعة جديدة',
          cs: 'Nová skupina',
          de: 'Neue Gruppe',
          en: 'New Group',
          fr: 'Nouveau groupe',
        },
        notInAzure: {
          description: {
            ar: 'معرف OID غير معروف، انقر فوق رمز الإنذار لتسريع عملية  مزامنة المستخدم مع التطبيق.',
            cs: 'Neexistující OID, klikněte na ikonu alarmu, abyste urychlili proces synchronizace uživatele s aplikací.',
            de: 'Nicht existierende OID, klicken Sie auf das Alarmsymbol, um den Prozess der Synchronisierung des Benutzers mit der Anwendung zu beschleunigen.',
            en: 'Non-existent OID, click on the alarm icon to speed up the process of synchronizing the user with the application.',
            fr: "OID inexistant, cliquez sur l'icône d'alarme pour accélérer le processus de synchronisation de l'utilisateur avec l'application.",
          },
          title: {
            ar: 'الإنذار',
            cs: 'Alarm',
            de: 'Alarm',
            en: 'Alarm',
            fr: 'Alarme',
          },
        },
        setGroupsModal: {
          bundleActionText: {
            ar: 'تطبيق الحالة على مجموعات محددة:',
            cs: 'Použít stav pro vybrané skupiny:',
            de: 'Status für ausgewählte Gruppen anwenden:',
            en: 'Apply status for selected groups:',
            fr: "Appliquer l'état pour les groupes sélectionnés :",
          },
          description: {
            ar: 'يُرجى تحديد المجموعات التي تريد تكوينها <br/> للمستخدم <strong>"{{userName}}"</strong>.',
            cs: 'Vyberte skupiny, které chcete nakonfigurovat pro uživatele <br/> <strong>"{{userName}}"</strong>.',
            de: 'Wählen Sie die Gruppen, die Sie für den Benutzer <br/> <strong>"{{userName}}"</strong> konfigurieren möchten.',
            en: 'Please select the groups you want to configure for the  <br/> user <strong>"{{userName}}"</strong>.',
            fr: 'Veuillez sélectionner les groupes que vous souhaitez configurer pour l\'utilisateur <br/>"{{userName}}"</strong>.',
          },
          filter: {
            notSetGroups: {
              ar: 'مجموعات لم يتم إعدادها بعد',
              cs: 'Nenastavené skupiny',
              de: 'Nicht eingestellte Gruppen',
              en: 'Not set groups',
              fr: 'Groupes non définis',
            },
            setGroups: {
              ar: 'مجموعات تم إعدادها',
              cs: 'Nastavené skupiny',
              de: 'Eingestellte Gruppen',
              en: 'Set groups',
              fr: 'Groupes définis',
            },
          },
          table: {
            groups: {
              ar: 'المجموعات',
              cs: 'Skupiny',
              de: 'Gruppen',
              en: 'Groups',
              fr: 'Groupes',
            },
          },
          title: {
            ar: 'إعداد المجموعات',
            cs: 'Nastavení skupin',
            de: 'Gruppeneinstellung',
            en: 'Settings groups',
            fr: 'Paramètres des groupes',
          },
        },
        setTenantsModal: {
          bundleActionText: {
            ar: 'تطبيق الحالة على أماكن استئجار محددة',
            cs: 'Použít stav pro vybrané tenanty:',
            de: 'Status für ausgewählte Tenants anwenden:',
            en: 'Apply status for selected tenants:',
            fr: 'Appliquer le statut pour les tenants sélectionnés :',
          },
          description: {
            ar: 'يُرجى تحديد المستأجرين الذين تريد تهيئتهم <br/> للمستخدم <strong>"{{userName}}"</strong>.',
            cs: 'Vyberte tenanty, které chcete nakonfigurovat pro uživatele<br/> <strong>"{{userName}}"</strong>.',
            de: 'Bitte wählen Sie die Tenants aus, die Sie für den Benutzer<br/> <strong>"{{userName}}"</strong> konfigurieren möchten.',
            en: 'Please select the tenants you want to configure for the<br/> user <strong>"{{userName}}"</strong>.',
            fr: 'Veuillez sélectionner les tenants que vous souhaitez configurer pour l\'utilisateur <strong>"{{userName}}"</strong>.',
          },
          filter: {
            notSetTenants: {
              ar: 'تم إعداد المستأجرين',
              cs: 'Tenanti nenastavení',
              de: 'Nicht eingestellte Tenants',
              en: 'Not set tenants',
              fr: 'Ne pas définir de tenants',
            },
            setTenants: {
              ar: 'اعداد الأماكن المستأجرة',
              cs: 'Nastavit tenanty',
              de: 'Tenants einstellen',
              en: 'Set tenants',
              fr: 'Définir les tenants',
            },
          },
          table: {
            tenants: {
              ar: 'المستأجرون',
              cs: 'Tenanti',
              de: 'Tenants',
              en: 'Tenants',
              fr: 'Tenants',
            },
          },
          tenantMustBeSet: {
            ar: 'الآن سيفقد المستخدم <strong>"{{userName}}"</strong> جميع المستأجرين المحددين. قم بإعداد المستأجرين المطلوبين في الدور المستخدم الجديد.',
            cs: 'Nyní uživatel <strong>"{{userName}}"</strong> ztratí všechny nastavené nájemníky. Nastavte požadované nájemníky v nové roli Uživatel.',
            de: 'Der Benutzer <strong>"{{userName}}"</strong> verliert nun alle eingestellten Tenants. Stellen Sie die gewünschten Tenants in der neuen Rolle als Benutzer ein.',
            en: 'Now user <strong>"{{userName}}"</strong> loses all set tenants. Please set up the required tenants in the new role as a User.',
            fr: 'L\'utilisateur <strong>"{{userName}}"</strong> a perdu tous les tenants définis. Veuillez configurer les tenants requis dans le nouveau rôle d\'utilisateur.',
          },
          title: {
            ar: 'إعداد أماكن الاستئجار',
            cs: 'Nastavení tenantů',
            de: 'Einstellung der Tenants',
            en: 'Settings tenants',
            fr: 'Paramètres des tenants',
          },
        },
        tenantMissingName: {
          ar: '(اسم المستأجر مفقود)',
          cs: '(Chybí název tenanta)',
          de: '(Tenant-Name fehlt)',
          en: '(tenant missing name)',
          fr: '(le nom du tenant est manquant)',
        },
        userExistsError: {
          ar: 'المستخدم موجود بالفعل.',
          cs: 'Uživatel již existuje.',
          de: 'Der Benutzer existiert bereits.',
          en: 'User already exists',
          fr: 'L‘utilisateur existe déjà.',
        },
        users: {
          actions: {
            deleteUser: {
              ar: 'حذف المستخدم',
              cs: 'Smazat uživatele',
              de: 'Benutzer löschen',
              en: 'Delete user',
              fr: "Supprimer l'utilisateur",
            },
            setGroup: {
              ar: 'إعداد المجموعة',
              cs: 'Nastavit skupinu',
              de: 'Gruppe einstellen',
              en: 'Set group',
              fr: 'Définir le groupe',
            },
            setTenant: {
              ar: 'إعداد مكان الاستئجار',
              cs: 'Nastavit tenant',
              de: 'Tenant einstellen',
              en: 'Set tenant',
              fr: 'Définir un tenant',
            },
          },
          admin: {
            ar: 'Admin',
            cs: 'Admin',
            de: 'Admin',
            en: 'Admin',
            fr: 'Admin',
          },
          powerUser: {
            ar: 'Power user',
            cs: 'Power user',
            de: 'Power User',
            en: 'Power user',
            fr: 'Power user',
          },
          role: {
            ar: 'الدور',
            cs: 'Role',
            de: 'Rolle',
            en: 'Role',
            fr: 'Rôle',
          },
          user: {
            ar: 'المستخدم',
            cs: 'User',
            de: 'User',
            en: 'User',
            fr: 'User',
          },
        },
      },
    },
  },
  alertModal: {
    journeyDeleted: {
      ar: 'تم حذف رحلة <strong>"{{name}}"</strong> .',
      cs: 'Cesta <strong>"{{name}}"</strong> byla smazána.',
      de: 'Der <strong>"{{name}}"</strong> Pfad wurde gelöscht.',
      en: 'Journey <strong>"{{name}}"</strong> was deleted.',
      fr: 'Le parcours <strong>"{{name}}"</strong> a été effacé.',
    },
    journeyOnMobileDevice: {
      ar: 'لم يتم تحسين تطبيق KPMG Discovery للأجهزة المحمولة. لاستخدام وظائفه الكاملة، يرجى الوصول إليه من جهاز سطح المكتب - بالحالة المثالية عبر أحدث إصدارات غوغل كروم Google Chrome أو فايروركس  Firefox أو سافاري Safari.',
      cs: 'Aplikace KPMG Discovery není optimalizována pro mobilní zařízení. Chcete-li využívat její plnou funkčnost, přistupujte prosím ze stolního zařízení - ideálně prostřednictvím nejnovějších verzí prohlížečů Google Chrome, Firefox nebo Safari.',
      de: 'Die KPMG Discovery App ist nicht für mobile Geräte optimiert. Um den vollen Funktionsumfang zu nutzen, greifen Sie bitte von einem Desktop-Gerät aus zu - idealerweise über die neuesten Versionen der Browser Google Chrome, Firefox oder Safari.',
      en: 'The KPMG Discovery app is not optimized for mobile devices. To use its full functionality, please access from a desktop device - ideally through the latest versions of Google Chrome, Firefox or Safari browsers.',
      fr: "L'application KPMG Discovery n'est pas optimisée pour les appareils mobiles. Pour utiliser toutes ses fonctionnalités, veuillez accéder à l'application à partir d'un appareil de bureau - idéalement avec les dernières versions des navigateurs Google Chrome, Firefox ou Safari.",
    },
    leave: {
      ar: 'المغادرة',
      cs: 'Opustit',
      de: 'Verlassen',
      en: 'Leave',
      fr: 'Quitter',
    },
    messageAlert: {
      ar: 'عند مغادرة هذه الصفحة، ستفقد التغييرات غير المحفوظة في المستند.',
      cs: 'Opuštěním této stránky ztratíte neuložené změny v dokumentu.',
      de: 'Durch Verlassen dieser Seite verlieren Sie nicht gespeicherte Änderungen im Dokument.',
      en: 'By leaving this page you will lose the unsaved changes in the document.',
      fr: 'En quittant cette page, vous perdez les changements du document non sauvegardés.',
    },
    titleAlert: {
      ar: 'تنبيه',
      cs: 'Upozornění',
      de: 'Hinweis',
      en: 'Alert',
      fr: 'Avertissement',
    },
  },
  api: {
    axios: {
      defaultError: {
        title: {
          ar: 'حدث خطأ أثناء الاتصال بواجهة برمجة التطبيقات (API).',
          cs: 'Při volání API nastala chyba',
          de: 'Beim API-Abruf ist ein Fehler aufgetreten',
          en: 'API call failed',
          fr: 'Une erreur s’est produite lors de l’appel de l’API',
        },
      },
    },
    beErrorCodes: {
      aadAddUserError: {
        ar: 'حدث خطأ في اللون الازرق الفاتح أثناء إنشاء مستخدم في الدليل النشط',
        cs: 'Během vytváření uživatele v Azure Active Directory nastala chyba.',
        de: 'Beim Anlegen eines Benutzers in der Azure Active Directory ist ein Fehler aufgetreten.',
        en: 'An error occurred while creating user in the Azure Active Directory.',
        fr: 'Une erreur s’est produite lors de la création de l’utilisateur dans Azure Active Directory.',
      },
      aadCreateGroupError: {
        ar: 'حدث خطأ في اللون الازرق الفاتح أثناء إنشاء مجموعة في الدليل النشط ',
        cs: 'Během vytváření skupiny v Azure Active Directory nastala chyba.',
        de: 'Beim Anlegen einer Gruppe in der Azure Active Directory ist ein Fehler aufgetreten.',
        en: 'An error occurred while creating group in the Azure Active Directory.',
        fr: 'Une erreur s’est produite lors de la création du groupe dans Azure Active Directory.',
      },
      aadDeleteGroupError: {
        ar: 'حدث خطأ في اللون الازرق الفاتح أثناء حذف مجموعة في الدليل  النشط',
        cs: 'Během mazání skupiny v Azure Active Directory nastala chyba.',
        de: 'Beim Löschen einer Gruppe in der Azure Active Directory ist ein Fehler aufgetreten.',
        en: 'An error occurred while deleting group in the Azure Active Directory.',
        fr: 'Une erreur s’est produite lors de la suppression du groupe dans Azure Active Directory.',
      },
      aadDeleteUserError: {
        ar: 'حدث خطأ في اللون الازرق الفاتح أثناء حذف مستخدم في الدليل النشط',
        cs: 'Během mazání uživatele v Azure Active Directory nastala chyba.',
        de: 'Beim Löschen eines Benutzers in der Azure Active Directory ist ein Fehler aufgetreten.',
        en: 'An error occurred while deleting user in the Azure Active Directory.',
        fr: 'Une erreur s’est produite lors de la suppression de l’utilisateur dans Azure Active Directory.',
      },
      aadRemoveUserFromGroupError: {
        ar: ' حدث خطأ في اللون الازرق الفاتح أثناء إزالة مستخدم من مجموعة الدليل النشط',
        cs: 'Během odebírání uživatele ze skupiny v Azure Active Directory nastala chyba.',
        de: 'Beim Entfernen eines Benutzers aus einer Gruppe in der Azure Active Directory ist ein Fehler aufgetreten.',
        en: 'An error occurred while removing user from group in the Azure Active Directory.',
        fr: 'Une erreur s’est produite lors du retrait de l’utilisateur d’un groupe dans Azure Active Directory.',
      },
      aadUserExistsInGroupError: {
        ar: 'المستخدم موجود بالفعل في المجموعة',
        cs: 'Uživatel již ve skupině existuje.',
        de: 'Der Benutzer existiert bereits in der Gruppe.',
        en: 'User already exists in group.',
        fr: 'L’utilisateur existe déjà dans le groupe.',
      },
      aadUserNotFoundInOrganization: {
        ar: 'لم يتم العثور على المستخدم في نطاق المؤسسة، يرجى الاتصال بفريق الدعم.',
        cs: 'Uživatel není zaveden v doméně organizace, kontaktujte prosím tým podpory.',
        de: 'Der Benutzer ist nicht in der Domäne der Organisation eingerichtet. Bitte wenden Sie sich an das Support-Team.',
        en: 'The user is not established in the organization domain, please contact the support team.',
        fr: "L'utilisateur n'est pas établi dans le domaine de l'organisation, veuillez contacter l'équipe de support.",
      },
      accessDeniedInsufficientAccessLevel: {
        ar: 'ليس للمستخدم حقوق في الأطلس',
        cs: 'Uživatel nemá právo na atlas.',
        de: 'Der Benutzer hat keine Atlas-Berechtigung.',
        en: "User doesn't have permission to the atlas.",
        fr: 'L’utilisateur n’a pas le droit à l’atlas.',
      },
      accessDeniedInsufficientUserRole: {
        ar: ' ليس لدى المستخدم تصاريح كافية ',
        cs: 'Uživatel nemá dostatečné oprávnění.',
        de: 'Die Berechtigung des Benutzers ist nicht ausreichend.',
        en: 'Insufficient permission.',
        fr: 'L’utilisateur ne dispose pas de l’autorisation suffisante.',
      },
      atlasNotExists: {
        ar: 'الأطلس غير موجود',
        cs: 'Atlas neexistuje.',
        de: 'Der Atlas existiert nicht.',
        en: "Atlas doesn't exist.",
        fr: 'L’atlas n’existe pas.',
      },
      capabilitiesImportDataNotValid: {
        ar: 'البيانات الخاصة بإمكانيات الاستيراد غير صالحة',
        cs: 'Data pro import kapabilit nejsou validní.',
        de: 'Die Daten für Importfunktionen sind nicht gültig.',
        en: 'Data for importing capabilities is not valid.',
        fr: 'Les données relatives à l’importation des capacités ne sont pas valides.',
      },
      documentNotValid: {
        ar: 'الوثيقة غير صالحة',
        cs: 'Dokument není validní.',
        de: 'Das Dokument ist nicht gültig.',
        en: 'Document is not valid.',
        fr: 'Le document n’est pas valide.',
      },
      duplicateMsgId: {
        ar: 'مرف رسالة مكرر',
        cs: 'Duplicitní ID zprávy.',
        de: 'Doppelte ID-Nachrichten.',
        en: 'Duplicate message ID.',
        fr: 'Duplicate message ID.',
      },
      duplicateMsgid: {
        ar: ' معرفة الرسالة المكررة',
        cs: 'Duplicitní ID zprávy.',
        de: 'Doppelte ID-Nachrichten.',
        en: 'Duplicate message ID.',
        fr: 'ID du message en double.',
      },
      excelImportBindingNotFound: {
        ar: 'حدث خطأ أثناء الاستيراد من اكسل - لم يتم العثور على رابط في خلية القائمة',
        cs: 'Chyba při importu z Excelu - nenalezena vazba na buňku v listu.',
        de: 'Fehler beim Import aus Excel – Link zur Blattzelle nicht gefunden.',
        en: 'Excel importing error - binding to cell not found.',
        fr: 'Erreur lors de l’importation à partir d’Excel – aucune liaison avec la cellule de la feuille n’a été trouvée.',
      },
      excelImportDuplicateBinding: {
        ar: 'خطأ عند النقل إلى تطبيق أكسل Excel - علاقة ربط مكرر بقيمة الخلية.',
        cs: 'Chyba při importu do aplikace Excel - duplicitní vazba na hodnotu buňky.',
        de: 'Fehler beim Import in die Excel-App - Doppel-Link zum Zellwert.',
        en: 'Excel importing error - duplicate binding to cell value.',
        fr: "Erreur d'importation Excel - duplicate binding to cell value.",
      },
      excelImportDuplicitBinding: {
        ar: 'خطأ عند الاستيراد من اكسل - رابط مكرر في خلية القائمة',
        cs: 'Chyba při importu z Excelu - duplicitní vazba na buňku v listu.',
        de: 'Fehler beim Import aus Excel – Doppel-Link zur Blattzelle.',
        en: 'Excel importing error - duplicate binding to cell.',
        fr: 'Erreur lors de l’importation à partir d’Excel – duplication de la liaison avec la cellule de la feuille.',
      },
      excelImportErrorReadCell: {
        ar: 'خطأ عند الاستيراد من اكسل - خطأ في قراءة الخلية',
        cs: 'Chyba při importu z Excelu - chyba při čtení buňky.',
        de: 'Fehler beim Import aus Excel – Fehler beim Lesen einer Zelle.',
        en: 'Excel importing error - reading cell error.',
        fr: 'Erreur lors de l’importation à partir d’Excel – erreur lors de la lecture de la cellule.',
      },
      excelImportIncorrectLayerId: {
        ar: 'خطأ عند الاستيراد من اكسل - خاطئ في قائمة التعريفات',
        cs: 'Chyba při importu z Excelu - chybné ID vrstvy v listu.',
        de: 'Fehler beim Import aus Excel – falsche ID-Layer im Blatt.',
        en: 'Excel importing error - invalid layer ID.',
        fr: 'Erreur lors de l’importation à partir d’Excel – ID de la couche dans la feuille incorrect.',
      },
      excelImportIncorrectValueInCell: {
        ar: 'خطأ عند الاستيراد من اكسل - قيمة خاطئة في خلية القائمة',
        cs: 'Chyba při importu z Excelu - chybná hodnota v buňce listu.',
        de: 'Fehler beim Import aus Excel – falscher Wert in einer Blattzelle.',
        en: 'Excel importing error - invalid value in cell.',
        fr: 'Erreur lors de l’importation à partir d’Excel – valeur incorrecte dans la cellule de la feuille.',
      },
      excelImportListNotExists: {
        ar: 'حدث خطأ أثناء الاستيراد من اكسل - الورقة غير موجودة',
        cs: 'Chyba při importu z Excelu - list neexistuje.',
        de: 'Fehler beim Import aus Excel – Blatt existiert nicht',
        en: "Excel importing error - list doesn't exist.",
        fr: 'Erreur lors de l’importation à partir d’Excel – la feuille n’existe pas.',
      },
      excelImportNoValueInCell: {
        ar: 'حدث خطأ أثناء الاستيراد من اكسل - قيمة مفقودة في قائمة الخلية',
        cs: 'Chyba při importu z Excelu - chybějící hodnota v buňce listu.',
        de: 'Fehler beim Import aus Excel – fehlender Wert in einer Blattzelle.',
        en: 'Excel importing error - missing value in cell.',
        fr: 'Erreur lors de l’importation à partir d’Excel – valeur manquante dans la cellule de la feuille.',
      },
      excelImportNotNumericValueInCell: {
        ar: 'خطأ عند الاستيراد من اكسل - قيمة رقمية خاطئة في خلية القائمة',
        cs: 'Chyba při importu z Excelu - chybná numerická hodnota v buňce listu.',
        de: 'Fehler beim Import aus Excel – falscher numerischer Wert in einer Blattzelle.',
        en: 'Excel importing error - invalid numeric value in cell.',
        fr: 'Erreur lors de l’importation à partir d’Excel – valeur numérique incorrecte dans la cellule de la feuille.',
      },
      excelImportUnknownBindingType: {
        ar: 'حدث خطأ أثناء الاستيراد من اكسل - نوع الربط غير معروف',
        cs: 'Chyba při importu z Excelu - neznámý typ vazby.',
        de: 'Fehler beim Import aus Excel – unbekannter Link-Typ',
        en: 'Excel importing error - unknown binding type.',
        fr: 'Erreur lors de l’importation à partir d’Excel – type de liaison inconnu.',
      },
      excelImportUnknownCode: {
        ar: 'خطأ عند الاستيراد من اكسل - رمز غير معروف في كود القائمة',
        cs: 'Chyba při importu z Excelu - neznámý kód v buňce listu.',
        de: 'Fehler beim Import aus Excel – unbekannter Code in einer Blattzelle.',
        en: 'Excel importing error - unknown code in cell.',
        fr: 'Erreur lors de l’importation à partir d’Excel – code inconnu dans la cellule de la feuille.',
      },
      excelImportUnknownLayerModel: {
        ar: 'خطأ في الاستيراد من اكسل - نموذج غير معروف',
        cs: 'Chyba při importu z Excelu - neznámý model vrstvy.',
        de: 'Fehler beim Import aus Excel – unbekanntes Layer-Modell.',
        en: 'Excel importing error - invalid layer model.',
        fr: 'Erreur lors de l’importation à partir d’Excel – modèle de couche inconnu.',
      },
      folderNotExists: {
        ar: 'المجلد غير موجود',
        cs: 'Složka neexistuje.',
        de: 'Ordner existiert nicht.',
        en: "Folder doesn't exist.",
        fr: 'Le dossier n’existe pas.',
      },
      internalServerError: {
        ar: 'خطأ داخلي في الخادم.',
        cs: 'Vnitřní chyba serveru.',
        de: 'Interner Serverfehler.',
        en: 'Internal server error.',
        fr: 'Erreur interne du serveur.',
      },
      invalidAppVersion: {
        ar: 'هذه نسخة غير صالحة من التطبيق',
        cs: 'Neplatná verze aplikace.',
        de: 'Ungültige Anwendungsversion.',
        en: 'Invalid application version.',
        fr: "Version de l'application non valide.",
      },
      invalidRequestFormat: {
        ar: 'التنسيق الشكلي للبيانات المرسلة غير صالح',
        cs: 'Odeslaná data mají nevalidní formát.',
        de: 'Das Format der abgesendeten Daten ist nicht gültig.',
        en: 'The request data has an invalid structure.',
        fr: 'Les données envoyées ont un format non valide.',
      },
      invalidTenantName: {
        ar: 'اسم المستأجر غير صالح',
        cs: 'Název tenanta je nevalidní.',
        de: 'Der Tenant-Name ist nicht gültig.',
        en: 'Invalid tenant name.',
        fr: 'Le nom du tenant n’est pas valide.',
      },
      invalidTenantTemplateId: {
        ar: ' معرف قالب المستأجر غير صالح، أو قالب بهذا المعرف غير موجود',
        cs: 'ID šablony tenanta je nevalidní, nebo šablona s tímto ID neexistuje.',
        de: 'Das ID der Tenant-Vorlage ist nicht gültig oder die Vorlage mit diesem ID existiert nicht.',
        en: "Invalid tenant template ID or template doesn't exist.",
        fr: 'L’ID du modèle de tenant n’est pas valide ou un modèle avec cet ID n’existe pas.',
      },
      journeyNotExists: {
        ar: 'المسار غير موجود',
        cs: 'Cesta neexistuje.',
        de: 'Pfad existiert nicht.',
        en: "Journey doesn't exist.",
        fr: 'Le parcours n’existe pas.',
      },
      missingTenantId: {
        ar: ' المستأجر بالهوية المطلوبة غير موجود',
        cs: 'Tenant s požadovaným ID neexistuje.',
        de: 'Der Tenant mit dem geforderten ID existiert nicht.',
        en: "Tenant with this ID doesn't exist.",
        fr: 'Le tenant avec l’ID demandé n’existe pas.',
      },
      noAccessTokenForMsGraph: {
        ar: ' فشل في الحصول على رمز الوصول لواجهة برمجة تطبيقات (MS Graph API)',
        cs: 'Nepovedlo se ziskat access token pro MS Graph API.',
        de: 'Das Access-Token für MS Graph-API konnte nicht abgerufen werden.',
        en: 'Missing access token for MS Graph API.',
        fr: 'Échec de l’obtention du token d’accès à l’API MS Graph.',
      },
      noTokenInRequest: {
        ar: 'الطلب يفتقد رمز المستخدم',
        cs: 'V požadavku chybí uživatelský token.',
        de: 'In der Anfrage fehlt das Benutzertoken.',
        en: "No user's token in request",
        fr: 'Le token est manquant dans la demande.',
      },
      personaNameExists: {
        ar: 'اسم الشخصية موجود بالفعل',
        cs: 'Jméno persony již existuje.',
        de: 'Der Persona-Name existiert bereits.',
        en: "Persona's name already exist.",
        fr: 'Le nom du persona existe déjà.',
      },
      personaNotExists: {
        ar: 'الشخصية غير موجودة',
        cs: 'Persona neexistuje',
        de: 'Persona existiert nicht',
        en: "Persona doesn't exist.",
        fr: 'Le persona n’existe pas.',
      },
      previousOrFollowingVersionNotExists: {
        ar: 'النسخة السابقة أو التالية غير موجودة',
        cs: 'Předchozí nebo následující verze neexistuje',
        de: 'Vorherige oder nachfolgende Version existiert nicht.',
        en: "Previous or following version doesn't exist",
        fr: "La version précédente ou suivante n'existe pas",
      },
      serverGeneralError: {
        ar: 'خطأ عام في الخادم/السيرفر',
        cs: 'Obecná chyba serveru',
        de: 'Allgemeiner Serverfehler',
        en: 'Server general error.',
        fr: 'Erreur générale du serveur',
      },
      tenantDeactivated: {
        ar: 'تم إلغاء تنشيط المستأجر المعطى',
        cs: 'Zadaný tenant byl smazán',
        de: 'Der eingegebene Tenant wurde gelöscht',
        en: 'The given tenant has been deactivated',
        fr: 'Le tenant donné a été désactivé',
      },
      tenantNotExists: {
        ar: 'المستأجر غير موجود',
        cs: 'Tenant neexistuje.',
        de: 'Der Tenant existiert nicht.',
        en: "Tenant doesn't exist.",
        fr: 'Le locataire n’existe pas.',
      },
      tokenNotValid: {
        ar: 'الرمز غير صالح',
        cs: 'Token není validní.',
        de: 'Das Token ist nicht gültig.',
        en: 'Token is not valid.',
        fr: 'Le token n’est pas valide.',
      },
      unknownAzureActiveDirectoryError: {
        ar: 'خطأ غير معروف في أزور أكتيف ديريكتوري Azure Active Directory',
        cs: 'Neznámá chyba v Azure Active Directory.',
        de: 'Unbekannter Fehler in Azure Active Directory.',
        en: 'Unknown error in Azure Active Directory.',
        fr: 'Erreur inconnue dans Azure Active Directory.',
      },
      unknownCodeSixPillarsLayer: {
        ar: 'خطأ عند الاستيراد من اكسل - رمز طبقة الركائز الستة غير معروف',
        cs: 'Chyba při importu z Excelu - neznámý kód vrstvy six pillars.',
        de: 'Fehler beim Import aus Excel – unbekannter Six-Pillars-Layer-Code.',
        en: 'Excel importing error - unknown code of six pillars layer.',
        fr: 'Erreur lors de l’importation à partir d’Excel – code inconnu pour la couche six pillars.',
      },
      unknownDatabaseError: {
        ar: 'خطأ غير معروف في قاعدة البيانات.',
        cs: 'Neznámá chyba databáze.',
        de: 'Unbekannter Datenbankfehler.',
        en: 'Unknown database error.',
        fr: 'Erreur inconnue dans la base de données.',
      },
      uploadExceededFileSize: {
        ar: 'تجاوز الحد الأقصى لحجم الملف الحد الأقصى {{size}} ميغا بايت',
        cs: 'Překročena maximální velikost souboru (max. {{size}} MB)',
        de: 'Maximale Dateigröße überschritten (max. {{size}} MB)',
        en: 'Maximum file size exceeded (max. {{size}} MB)',
        fr: 'La taille maximum du fichier dépassée (max. {{size}} MB)',
      },
      uploadNotAllowedContentType: {
        ar: 'نوع الملف غير قانوني',
        cs: 'Nepovolený typ souboru.',
        de: 'Ungültiger Dateityp.',
        en: 'Invalid file type.',
        fr: 'Type de fichier interdit.',
      },
      uploadNotAllowedFileExt: {
        ar: 'ملحق الملف غير صالح',
        cs: 'Nepovolená přípona souboru.',
        de: 'Ungültige Dateiendung.',
        en: 'Invalid file suffix.',
        fr: 'Extension de fichier interdite.',
      },
      userAlreadyExists: {
        ar: '.هناك مستخدم بنفس الإيميل',
        cs: 'Uživatel se stejným e-mailem již existuje.',
        de: 'Der Benutzer mit der gleichen E-Mail existiert bereits.',
        en: 'The user with the same email already exists.',
        fr: "L'utilisateur avec le même email existe déjà.",
      },
      userHasNoRole: {
        ar: ' ليس لدى المستخدم أي دور محدد،  لذلك ينبغي إدراجه في مجموعة KPMG، لكنه ليست كذلك',
        cs: 'Uživatel nemá nastavenou žádnou roli. Měl by tedy být zařazen v KPMG skupině, ale není.',
        de: 'Beim Benutzer ist keine Rolle eingestellt. Er sollte daher einer KPMG-Gruppe zugeordnet sein, ist es aber nicht.',
        en: "User has no role and should be assigned to the KPMG group but isn't.",
        fr: 'L’utilisateur n’a pas de rôle défini. Il devrait donc être inclus dans le groupe KPMG, mais ce n’est pas le cas.',
      },
      userIncludedToMoreTenants: {
        ar: ' المستخدم مسجل عند أكثر من مستأجر واحد',
        cs: 'Uživatel je zařazen do více než jednoho tenanta.',
        de: 'Der Benutzer ist mehr als einem Tenant zugeordnet.',
        en: 'User has more than one assigned tenant',
        fr: 'L’utilisateur est affecté à plusieurs tenants.',
      },
      userIncludedToMoreTenantsUnknownRole: {
        ar: ' المستخدم مسجل عند أكثر من مستأجر واحد، لذلك لا يمكن تحديد الدور الذي يقوم به',
        cs: 'Uživatel je zařazen do více než jednoho tenanta a tak není možné určit, jakou má roli.',
        de: 'Der Benutzer ist mehr als einem Tenant zugeordnet, seine Rolle ist mithin nicht feststellbar.',
        en: 'User has more than one assigned tenant; it is impossible to determine what role he has.',
        fr: 'L’utilisateur est affecté à plusieurs tenants et il n’est donc pas possible de déterminer son rôle.',
      },
      userMultipleAADGroups: {
        ar: 'لدى المستخدم أكثر من مجموعة AAD.',
        cs: 'Uživatel má více skupin AAD.',
        de: 'Der Benutzer hat mehrere AAD-Gruppen.',
        en: 'User has multiple AAD groups.',
        fr: "L'utilisateur a plusieurs groupes AAD.",
      },
      userMultipleAadGroups: {
        ar: 'عدد غير صالح من مجموعات AAD.',
        cs: 'Neplatný počet skupin AAD.',
        de: 'Ungültige AAD-Gruppenanzahl.',
        en: 'Invalid AAD group count.',
        fr: 'Nombre de groupes AAD non valide.',
      },
      userNoPermissionToAnyTenant: {
        ar: ' لا يملك المستخدم أي حق على المستأجر',
        cs: 'Uživatel nemá právo na žádného tenanta.',
        de: 'Benutzer ohne jedweden Tenant-Anspruch.',
        en: "User doesn't have permission for any tenant.",
        fr: 'L’utilisateur n’a le droit à aucun tenant.',
      },
      userNoPermissionToConfig: {
        ar: ' ليس للمستخدم الحق في الوصول إلى قاعدة بيانات التكوين',
        cs: 'Uživatel nemá právo přistupovat do konfigurační databáze.',
        de: 'Der Benutzer hat keinen Zugriff auf die Konfigurationsdatenbank.',
        en: "User doesn't have permission to access the config database.",
        fr: 'L’utilisateur n’a pas le droit d’accéder à la base de données de configuration.',
      },
      userNoPermissionToTenant: {
        ar: '  ليس للمستخدم حق في مستأجر',
        cs: 'Uživatel nemá právo na tenanta.',
        de: 'Benutzer ohne Tenant-Anspruch.',
        en: "User doesn't have permission for the tenant.",
        fr: 'L’utilisateur n’a pas le droit au tenant.',
      },
      userNotFound: {
        ar: 'لم يتم العثور على المستخدم',
        cs: 'Uživatel nebyl nalezen.',
        de: 'Der Benutzer wurde nicht gefunden.',
        en: 'User was not found.',
        fr: 'L’utilisateur n’a pas été trouvé.',
      },
      userWasDeleted: {
        ar: 'تم حذف المستخدم',
        cs: 'Uživatel byl smazán.',
        de: 'Der Benutzer wurde gelöscht.',
        en: 'User was deleted.',
        fr: 'L’utilisateur a été supprimé.',
      },
      userstoryIdNotExists: {
        ar: 'مستخدم القصة بالهوية المطلوبة غير موجود',
        cs: 'User-story s požadovaným ID neexistuje.',
        de: 'Die User-Story mit gefordertem ID existiert nicht.',
        en: "User-story with this ID doesn't exist.",
        fr: 'La user story avec l’ID demandé n’existe pas.',
      },
      userstoryNotExists: {
        ar: ' مستخدم القصة غير موجود',
        cs: 'User-story neexistuje.',
        de: 'Die User-Story existiert nicht.',
        en: "User-story doesn't exist.",
        fr: 'La user story n’existe pas.',
      },
    },
    networkOutage: {
      error: {
        ar: 'هناك انقطاع في الشبكة.',
        cs: 'Byl zjištěn výpadek sítě!',
        de: 'Es wurde ein Netzwerkausfall festgestellt!',
        en: 'Network outage detected!',
        fr: 'Panne de réseau détectée !',
      },
      notification: {
        outage: {
          ar: 'غير قلدر على الاتصال بالانترنت. نحاول إعادة اتصالك. يرجى التحقق من اتصال الشبكة عندك.',
          cs: 'Nelze se připojit k internetu. Pokoušíme se vás znovu připojit. Zkontrolujte prosím své síťové připojení.',
          de: 'Die Internetverbindung kann nicht hergestellt werden. Wir versuchen, Sie wieder zu verbinden. Überprüfen Sie bitte Ihre Netzwerkverbindung.',
          en: 'Unable to connect to the internet. We are trying to reconnect you. Please check your network connection.',
          fr: 'Impossible de se connecter à l’Internet. Nous essayons de vous reconnecter. Veuillez vérifier votre connexion réseau.',
        },
        outageTitle: {
          ar: 'اتصال الشبكة مفقود',
          cs: 'Připojení k síti bylo ztraceno',
          de: 'Die Netzwerkverbindung wurde unterbrochen',
          en: 'Network Connection Lost',
          fr: 'Perte de connexion au réseau',
        },
        reconnected: {
          ar: 'يمكنك مواصلة عملك الآن.',
          cs: 'Nyní můžete pokračovat ve své práci.',
          de: 'Jetzt können Sie Ihre Arbeit fortsetzen.',
          en: 'You can now continue your work further.',
          fr: 'Vous pouvez maintenant poursuivre votre travail.',
        },
        reconnectedTitle: {
          ar: 'تم استعادة اتصال الشبكة',
          cs: 'Připojení k síti bylo obnoveno',
          de: 'Die Netzwerkverbindung wurde wiederhergestellt',
          en: 'Network Connection Restored',
          fr: 'Connexion au réseau rétablie',
        },
        reconnecting: {
          ar: 'غير قلدر على الاتصال بالانترنت. نحاول إعادة اتصالك. ',
          cs: 'Nelze se připojit k internetu. Pokoušíme se vás znovu připojit.',
          de: 'Die Internetverbindung kann nicht hergestellt werden. Wir versuchen, Sie wieder zu verbinden.',
          en: 'Unable to connect to the internet. We are trying to reconnect you.',
          fr: 'Impossible de se connecter à l’Internet. Nous essayons de vous reconnecter.',
        },
      },
      outageError: {
        ar: 'هناك انقطاع في الشبكة.',
        cs: 'Byl zjištěn výpadek sítě!',
        de: 'Es wurde ein Netzwerkausfall festgestellt!',
        en: 'Network outage detected!',
        fr: 'Panne de réseau détectée !',
      },
      reconnecting: {
        ar: 'نحاول إعادة الاتصال بالشبكة.',
        cs: 'Pokoušíme se znovu připojit k serveru…',
        de: 'Wir versuchen, uns wieder mit dem Server zu verbinden…',
        en: 'Trying to reconnect to the server...',
        fr: 'Tentative de reconnexion au serveur…',
      },
    },
    ws: {
      logout: {
        btn: {
          ar: 'يغلق',
          cs: 'Zavřít',
          de: 'Schließen',
          en: 'Close',
          fr: 'Fermer',
        },
        content: {
          ar: 'لقد تم تسجيل خروجك من جميع الأجهزة. الرجاء الدخول على الحساب من جديد',
          cs: 'Byl jste odhlášen ze všech zařízení. Prosím, přihlaste se znovu.',
          de: 'Sie wurden von allen Geräten abgemeldet. Bitte melden Sie sich erneut an.',
          en: 'You were logged out from all devices. Please log in again.',
          fr: 'Vous avez été déconnecté de tous les appareils. Veuillez vous reconnecter.',
        },
        title: {
          ar: 'تسجيل الخروج من جميع الأجهزة',
          cs: 'Odhlášení ze všech zařízení',
          de: 'Abmeldung von allen Geräten',
          en: 'Log out from all devices',
          fr: 'Déconnexion de tous les appareils',
        },
      },
    },
  },
  applicationMenu: {
    backlog: {
      ar: 'قائمة المهام',
      cs: 'Backlog',
      de: 'Backlog',
      en: 'Backlog',
      fr: 'Backlog',
    },
    chooseLanguage: {
      ar: 'اختيار لغة',
      cs: 'Výběr jazyka',
      de: 'Sprachauswahl',
      en: 'Choose language',
      fr: 'Choix de langue',
    },
    configuration: {
      ar: 'تكوين',
      cs: 'Konfigurace',
      de: 'Konfiguration',
      en: 'Configuration',
      fr: 'Configuration',
    },
    connectedCapabilities: {
      ar: 'القدرات المتصلة',
      cs: 'Connected capabilities',
      de: 'Connected Capabilities',
      en: 'Connected capabilities',
      fr: 'Connected capabilities',
    },
    languages: {
      ar: 'AR',
      cs: 'CZ',
      de: 'DE',
      en: 'EN',
      fr: 'FR',
    },
    logOutAllDevices: {
      ar: 'تسجيل الخروج من جميع الأجهزة',
      cs: 'Odhlásit ze všech zařízení',
      de: 'Von allen Geräten abmelden',
      en: 'Log out from all devices',
      fr: 'Se déconnecter de tous les appareils',
    },
    logOutThisDevice: {
      ar: 'تسجيل خروج',
      cs: 'Odhlásit',
      de: 'Abmelden',
      en: 'Log out',
      fr: 'Se déconnecter',
    },
    personasManagement: {
      ar: 'إدارة الشخصيات',
      cs: 'Správa person',
      de: 'Personen-Verwaltung',
      en: 'Persona management',
      fr: 'Administration des personas',
    },
    recycleBin: {
      ar: 'سلة المحذوفات',
      cs: 'Koš',
      de: 'Papierkorb',
      en: 'Recycle bin',
      fr: 'Corbeille',
    },
    searching: {
      ar: 'جارٍ البحث',
      cs: 'Vyhledávání',
      de: 'Suche',
      en: 'Searching',
      fr: 'Recherche',
    },
    sendFeedback: {
      ar: 'إرسال تعليق',
      cs: 'Poslat zpětnou vazbu',
      de: 'Feedback schicken',
      en: 'Send feedback',
      fr: 'Envoyer une rétroaction',
    },
    shareJourney: {
      ar: 'مشاركة هذه الرحلة',
      cs: 'Sdílet cestu',
      de: 'Pfad teilen',
      en: 'Share the Journey',
      fr: 'Partager le parcours',
    },
    subMenu: {
      capabilityManagement: {
        ar: 'إدارة القدرات',
        cs: 'Správa kapabilit',
        de: 'Kapabilitätsmanagement',
        en: 'Management of capabilities',
        fr: 'Administration de capacités',
      },
      manageChannels: {
        ar: 'إدارة القنوات',
        cs: 'Správa kanálů',
        de: 'Kanalverwaltung',
        en: 'Management of Channels',
        fr: 'Administration de canaux',
      },
      manageTenant: {
        ar: 'إدارة المستأجر',
        cs: 'Správa tenanta',
        de: 'Tenant-Verwaltung',
        en: 'Management of Tenant',
        fr: 'Administration du tenant',
      },
      templateManagement: {
        ar: 'إدارة القوالب',
        cs: 'Správa šablon',
        de: 'Vorlagen-Verwaltung',
        en: 'Management of Templates',
        fr: 'Administration de modèles',
      },
      tenantLimits: {
        ar: 'حدود المستأجر',
        cs: 'Limity Tenanta',
        de: 'Tenant-Limits',
        en: 'Tenant limits',
        fr: 'Limites du tenant',
      },
      userManagement: {
        ar: 'إدارة المستخدمين',
        cs: 'Správa uživatelů',
        de: 'Benutzer-Verwaltung',
        en: 'Management of Users',
        fr: 'Administration des utilisateurs',
      },
      userManagementGroups: {
        ar: 'إدارة المجموعات',
        cs: 'Správa skupin',
        de: 'Gruppen-Verwaltung',
        en: 'Management of groups',
        fr: 'Administration des groupes',
      },
      userTenantManagement: {
        ar: 'إدارة أماكن استئجار لدى المستخدمين',
        cs: 'Správa tenant uživatelů',
        de: 'Verwaltung von Tenant-Benutzern',
        en: 'Management of Tenant Users',
        fr: 'Administration des utilisateurs tenants',
      },
    },
    tutorialIntroduction: {
      ar: 'مقدمة',
      cs: 'Úvod',
      de: 'Startseite',
      en: 'Introduction',
      fr: 'Introduction',
    },
    tutorials: {
      ar: 'المساعدة والدعم',
      cs: 'Nápověda a podpora',
      de: 'Assistenz und Unterstützung',
      en: 'Help and Support',
      fr: 'Aide et soutien',
    },
  },
  archivedJourneys: {
    archivedJourneys: {
      ar: 'الرحلات المحفتفظ بها في الأرشيف',
      cs: 'Archivované cesty',
      de: 'Archivierte Pfade',
      en: 'Archived journeys',
      fr: 'Parcours archivés',
    },
    deleted: {
      ar: 'تم الحذف',
      cs: 'smazáno',
      de: 'gelöscht',
      en: 'deleted',
      fr: 'supprimé',
    },
    listOfDeletedJourneys: {
      ar: 'قائمة الرحلات المحذوفة',
      cs: 'Seznam smazaných cest',
      de: 'Liste der gelöschten Pfade',
      en: 'List of deleted journeys',
      fr: 'Liste des parcours supprimés',
    },
  },
  atlasDetail: {
    addNewFolder: {
      ar: 'إضافة مجلد جديد',
      cs: 'Přidat novou složku',
      de: 'Neuen Ordner hinzufügen',
      en: 'Add new Folder',
      fr: 'Ajouter un nouveau dossier',
    },
    addNewJourney: {
      ar: 'إضافة رحلة جديدة',
      cs: 'Přidat novou cestu',
      de: 'Neuen Pfad hinzufügen',
      en: 'Add new Journey',
      fr: 'Ajouter un nouveau parcours',
    },
    newFolderTitle: {
      ar: 'إنشاء مجلد جديد',
      cs: 'Vytvořit novou složku',
      de: 'Neuen Ordner erstellen',
      en: 'Create a new folder',
      fr: 'Créer un nouveau dossier',
    },
  },
  atlases: {
    addNewAtlas: {
      ar: 'إنشاء أطلس جديد',
      cs: 'Vytvořit nový atlas',
      de: 'Neuen Atlas erstellen',
      en: 'Create a new atlas',
      fr: 'Créer un nouvel atlas',
    },
    complete: {
      ar: '',
      cs: '',
      de: '',
      en: 'Complete',
      fr: '',
    },
    editAtlas: {
      ar: '',
      cs: '',
      de: '',
      en: 'Edit atlas',
      fr: '',
    },
    searchAtlasesPlaceholder: {
      ar: 'البحث عن أطلس',
      cs: 'Vyhledat atlas',
      de: 'Atlas suchen',
      en: 'Search atlas',
      fr: 'Recherche d’atlas',
    },
    showingAtlasesSortBy: {
      ar: 'عرض الأطالس حسب ...',
      cs: 'Zobrazují se atlasy podle',
      de: 'Atlasse werden angezeigt nach',
      en: 'Showing atlases by',
      fr: 'Afficher les atlas par',
    },
  },
  atlasesPreview: {
    placeholder: {
      ar: 'أدخل الاسم',
      cs: 'Vložte název',
      de: 'Namen eingeben',
      en: 'Enter name',
      fr: 'Insérer nom',
    },
    showAll: {
      ar: 'عرض الكل',
      cs: 'Zobrazit vše',
      de: 'Alles anzeigen',
      en: 'Show all',
      fr: 'Afficher tout',
    },
    title: {
      ar: 'إنشاء أطلس جديد',
      cs: 'Vytvořit nový atlas',
      de: 'Neuen Atlas erstellen',
      en: 'Create a new atlas',
      fr: 'Créer un nouvel atlas',
    },
  },
  backlog: {
    criterium: {
      ar: 'معيار القبول',
      cs: 'Akceptační kritérium',
      de: 'Akzeptationskriterium',
      en: 'Acceptance criteria',
      fr: "Critères d'acceptation",
    },
    description: {
      ar: 'الوصف',
      cs: 'Popis',
      de: 'Beschreibung',
      en: 'Description',
      fr: 'Description',
    },
    descriptionPlaceholder: {
      ar: 'بما أني .... أستطيع .... إلى',
      cs: 'Jako .... mohu .... abych',
      de: 'Als .... kann ich .... damit',
      en: 'As a .... I can .... to',
      fr: 'En tant que .... je peux .... afin de',
    },
    high: {
      ar: 'مرتفع',
      cs: 'vysoká',
      de: 'hoch',
      en: 'high',
      fr: 'haute',
    },
    low: {
      ar: 'منخفض',
      cs: 'nízká',
      de: 'niedrig',
      en: 'low',
      fr: 'faible',
    },
    medium: {
      ar: 'متوسط',
      cs: 'střední',
      de: 'mittel',
      en: 'medium',
      fr: 'moyenne',
    },
    priority: {
      ar: 'الأولوية',
      cs: 'Priorita',
      de: 'Priorität',
      en: 'priority',
      fr: 'Priorité',
    },
    status: {
      done: {
        ar: 'تم',
        cs: 'hotová',
        de: 'fertiggestellt',
        en: 'done',
        fr: 'finie',
      },
      inProgress: {
        ar: 'في تقدم',
        cs: 'probíhá',
        de: 'in Arbeit',
        en: 'in progress',
        fr: 'en cours',
      },
      new: {
        ar: 'جديد',
        cs: 'nová',
        de: 'neu',
        en: 'new',
        fr: 'nouvelle',
      },
      terminated: {
        ar: 'تم الإنهاء',
        cs: 'zrušená',
        de: 'aufgehoben',
        en: 'terminated',
        fr: 'annulée',
      },
    },
  },
  capabilityManagement: {
    validationErrorModal: {
      description: {
        ar: 'يجب ملء كل من "الاسم" و"الوصف"!',
        cs: 'Musí být vyplněno jak "jméno", tak "popis"!',
        de: 'Sowohl der "Name" als auch die "Beschreibung" sind auszufüllen!',
        en: 'Both "name" and "description" has to be filled!',
        fr: 'Le « nom » et la « description » doivent être remplis !',
      },
      title: {
        ar: 'خطأ في التحقق من صحة النموذج',
        cs: 'Chyba ověření formuláře',
        de: 'Fehler bei der Formularüberprüfung',
        en: 'Form validation error',
        fr: 'Erreur de validation du formulaire',
      },
    },
  },
  channels: {
    description: {
      'broadcast-media': {
        ar: 'يمثل أي بث إذاعي أو تلفزيوني للمستهلكين.',
        cs: 'Představuje jakékoli rozhlasové nebo televizní vysílání pro spotřebitele.',
        de: 'Stellt jegliche Rundfunksendung für Verbraucher dar.',
        en: 'Represents any radio or television broadcast for consumers.',
        fr: 'Représente toute émission de radio ou de télévision pour les consommateurs.',
      },
      email: {
        ar: 'يمثل قناة اتصال رسمية ويمكن تتبعها، مثل الإشعارات وبيانات الحساب واستفسارات العملاء.',
        cs: 'Představuje formální a vysledovatelný komunikační kanál, např. oznámení, výpisy z účtu a dotazy zákazníků.',
        de: 'Stellt einen formalen und nachvollziehbaren Kommunikationskanal dar, z.B. Benachrichtigungen, Kontoauszüge und Kundenfragen.',
        en: 'Represents formal and traceable channel of communication, e.g. Notifications, account statements, and customer queries.',
        fr: 'Représente le canal de communication formel et traçable, par exemple les notifications, les relevés de compte ou les demandes des clients.',
      },
      'face-to-face': {
        ar: 'يمثل طريقة التواصل الشخصية القائمة على التواصل الجسدي اللفظي المتبادل بين شخصين أو أكثر.',
        cs: 'Představuje osobní způsob komunikace založený na vzájemné verbální fyzické komunikaci mezi dvěma nebo více lidmi.',
        de: 'Stellt einen persönlichen Kommunikationskanal dar, der auf einer gegenseitigen verbalen körperlichen Kommunikation zwischen zwei oder mehreren Personen beruht.',
        en: 'Represents personal method of communication based on mutual verbal physical communication between two or more people.',
        fr: 'Représente la méthode personnelle de communication basée sur la communication physique verbale mutuelle entre deux personnes ou plus.',
      },
      'instant-messaging': {
        ar: 'يمثل دعم العملاء الرقمي القائم على النص في الوقت الفعلي والتواصل مفيدًا لخدمة العملاء الفورية، ولكن غير التدخلية.',
        cs: 'Představuje textovou digitální zákaznickou podporu a komunikaci v reálném čase, která je užitečná pro okamžité, ale nerušivé zákaznické služby.',
        de: 'Stellt einen textbasierten digitalen Kundensupport und eine Echtzeitkommunikation dar, die für einen unmittelbaren, aber nicht aufdringlichen Kundenservice nützlich sind.',
        en: 'Represents real-time text-based digital customer support and communication useful for immediate, yet non-intrusive, customer service.',
        fr: 'Représente l’assistance à la clientèle et la communication numériques textuelles en temps réel, utiles pour un service à la clientèle immédiat, mais non intrusif.',
      },
      'mobile-app': {
        ar: 'يمثل التقنيات التفاعلية التي توفر الوصول الفوري إلى الخدمات والمعلومات. أمر بالغ الأهمية لمشاركة العملاء وتخصيصهم أثناء التنقل.',
        cs: 'Představuje interaktivní technologie, které poskytují okamžitý přístup ke službám a informacím. Rozhodující pro on-the-go zapojení zákazníků a personalizaci.',
        de: 'Stellt interaktive Technologien dar, die einen unmittelbaren Zugriff auf Dienstleistungen und Informationen ermöglichen. Entscheidend für die on-the-go-Einbindung von Kunden und Personalisierung.',
        en: 'Represents interactive technologies that provides instant access to services and information. Crucial for on-the-go customer engagement and personalization.',
        fr: 'Représente les technologies interactives qui fournissent un accès instantané aux services et à l’information. Crucial pour l’engagement et la personnalisation des clients en déplacement.',
      },
      phone: {
        ar: 'يمثل خطًا مباشرًا لإدارات أو موظفين محددين داخل المنظمة للتواصل الصوتي لحل المخاوف الفورية والمعقدة.',
        cs: 'Představuje přímou linku na konkrétní oddělení nebo personál v organizaci pro hlasovou komunikaci k řešení okamžitých a složitých problémů.',
        de: 'Stellt einen direkten Draht zu einer bestimmten Abteilung oder zu Mitarbeitern eines Unternehmens für die Sprachkommunikation zur Lösung unmittelbarer und komplexer Probleme dar.',
        en: 'Represents direct line to specific departments or personnel within the organization for voice communication to solve immediate and complex concerns.',
        fr: 'Représente la ligne directe avec des services ou du personnel spécifiques de l’entreprise pour la communication vocale afin de résoudre les problèmes immédiats et complexes.',
      },
      'printed-communication': {
        ar: 'يمثل التواصل الرسمي المادي، وغالبًا ما يكون مخصصًا، ويستخدم بشكل عام للإشعارات الرسمية والتسويق المتميز.',
        cs: 'Představuje fyzickou, často personalizovanou úřední komunikaci, obvykle používanou pro oficiální oznámení a prémiový marketing.',
        de: 'Stellt eine physische, oft personalisierte amtliche Kommunikation dar, die in der Regel für offizielle Bekanntmachungen und Premium-Marketing verwendet wird.',
        en: 'Represents physical, often personalized official communication, generally used for official notices and premium marketing.',
        fr: 'Représente la communication officielle physique, souvent personnalisée, généralement utilisée pour les avis officiels et le marketing premium.',
      },
      sms: {
        ar: 'يمثل الإشعارات أو الدعم القائم على النص والتفاعلات المحدودة، غالبًا لتأكيدات الانتقال.',
        cs: 'Představuje textová oznámení nebo podporu a omezené interakce, často pro potvrzení přechodu.',
        de: 'Stellt Textbenachrichtigungen oder Unterstützung und begrenzte Interaktionen dar, oft um den Übergang zu bestätigen.',
        en: 'Represents text-based notifications or support and limited interactions, often for transition confirmations.',
        fr: 'Représente les notifications ou l’assistance textuelles et les interactions limitées, souvent pour les confirmations de transition.',
      },
      'social-media': {
        ar: 'يمثل التقنيات التفاعلية التي تسهل إنشاء ومشاركة المحتوى والأفكار والاهتمامات وأشكال التعبير الأخرى من خلال المجتمعات والشبكات الافتراضية.',
        cs: 'Představuje interaktivní technologie, které usnadňují vytváření a sdílení obsahu, nápadů, zájmů a dalších forem vyjádření prostřednictvím virtuálních komunit a sítí.',
        de: 'Stellt interaktive Technologien dar, die das Erstellen und Teilen von Inhalten, Ideen, Interessen und anderen Ausdrucksformen über virtuelle Gemeinschaften und Netzwerke erleichtern.',
        en: 'Represents interactive technologies that facilitate the creation and sharing of content, ideas, interests, and other forms of expression through virtual communities and networks.',
        fr: 'Représente les technologies interactives qui facilitent la création et le partage de contenu, d’idées, d’intérêts ou d’autres formes d’expression par le biais de communautés et de réseaux virtuels.',
      },
      'virtual-assistant': {
        ar: 'يمثل روبوتات الدردشة والتحليلات التنبؤية وخوارزميات التخصيص لمساعدة المستخدمين وتوجيههم، غالبًا في الوقت الفعلي.',
        cs: 'Představuje chatboty, prediktivní analýzy a algoritmy personalizace, které pomáhají a navádějí uživatele, často v reálném čase.',
        de: 'Stellt Chatbots, prädiktive Analysen und Individualisierungsalgorithmen dar, die die Benutzer unterstützen und führen, oft in Echtzeit.',
        en: 'Represents chatbots, predictive analytics, and personalization algorithms to assist and guide users, often in real-time.',
        fr: 'Représente les chatbots, l’analyse prédictive et les algorithmes de personnalisation pour aider et guider les utilisateurs, souvent en temps réel.',
      },
      web: {
        ar: 'يمثل الموقع الإلكتروني للشركة أو بوابات الويب التي تقدم معلومات وخدمات محدودة قائمة على الإنترنت.',
        cs: 'Představuje webové stránky společnosti nebo webové portály, které nabízejí informace a omezené internetové služby.',
        de: 'Stellt Webseiten von Unternehmen oder Webportale dar, die Informationen und begrenzte Internetdienstleistungen anbieten.',
        en: 'Represents company website or web portals that offer information and limited internet-based services.',
        fr: 'Représente le site Web ou les portails Web de l’entreprise qui offrent des informations et des services Internet limités.',
      },
      'without-communication': {
        ar: 'يمثل نقاط الاتصال التي لا تتطلب أي تفاعل رقمي أو مادي.',
        cs: 'Představuje kontaktní body, které nevyžadují žádnou digitální nebo fyzickou interakci.',
        de: 'Stellt Kontaktpunkte dar, die keine digitale oder physische Interaktion erfordern.',
        en: 'Represents touchpoints that require no digital or physical interaction.',
        fr: 'Représente les points de contact qui ne nécessitent aucune interaction numérique ou physique.',
      },
    },
    name: {
      advertisement: {
        ar: 'الإعلانات',
        cs: 'Reklama',
        de: 'Werbung',
        en: 'Advertisement',
        fr: 'Publicité',
      },
      atm: {
        ar: 'ماكينات الصراف الآلي',
        cs: 'Bankomaty',
        de: 'Geldautomaten',
        en: 'ATM Machines',
        fr: 'Distributeurs automatiques de billets',
      },
      bank: {
        ar: 'فرع مصرف',
        cs: 'Pobočka banky',
        de: 'Bankfiliale',
        en: 'Bank branch',
        fr: 'Agence bancaire',
      },
      'broadcast-media': {
        ar: 'بث وسائل الإعلام',
        cs: 'Vysílací média',
        de: 'Rundfunkmedien',
        en: 'Broadcast media',
        fr: 'Médias audiovisuels',
      },
      'call-centre-external': {
        ar: 'اتصال سنتروم خارجي',
        cs: 'Externí call centrum',
        de: 'Externes Call-Center',
        en: 'Call centrum external',
        fr: 'Appel centrum externe',
      },
      'call-centre-internal': {
        ar: 'اتصال سنتروم داخلي',
        cs: 'Interní call centrum',
        de: 'Internes Call-Center',
        en: 'Call centrum internal',
        fr: 'Appel centrum interne',
      },
      'credit-card': {
        ar: 'بطاقة الائتمان',
        cs: 'Kreditní karta',
        de: 'Kreditkarte',
        en: 'Credit card',
        fr: 'Carte de crédit',
      },
      'electronic-communication': {
        ar: 'التواصل الإلكتروني',
        cs: 'Elektronická komunikace',
        de: 'Elektronische Kommunikation',
        en: 'Electronic communication',
        fr: 'Communication électronique',
      },
      electronics: {
        ar: 'الإلكترونيات',
        cs: 'Elektronika',
        de: 'Elektronische Geräte',
        en: 'Electronics',
        fr: 'Électronique',
      },
      email: {
        ar: 'بريد إلكتروني',
        cs: 'E-Mail',
        de: 'E-Mail',
        en: 'E-mail',
        fr: 'E-mail',
      },
      event: {
        ar: 'الحدث',
        cs: 'Událost',
        de: 'Ereignis',
        en: 'Event',
        fr: 'Évènement',
      },
      'face-to-face': {
        ar: 'شخصيا',
        cs: 'Osobně',
        de: 'Persönlich',
        en: 'Face to face',
        fr: 'En personne',
      },
      family: {
        ar: 'الفصيلة',
        cs: 'Rodina',
        de: 'Familie',
        en: 'Family',
        fr: 'Famille',
      },
      'george-ib': {
        ar: 'الوسيط المعرف/جورج',
        cs: 'IB/George',
        de: 'IB/Gorge',
        en: 'IB/George',
        fr: 'IB/George',
      },
      'george-key': {
        ar: 'مفتاح جورج',
        cs: 'George klíč',
        de: 'George-Schlüssel',
        en: 'George key',
        fr: 'Key George',
      },
      'george-mb': {
        ar: 'تطبيق MB/George',
        cs: 'MB/George aplikace',
        de: 'MB/George App',
        en: 'MB/George app',
        fr: 'MB/Application George',
      },
      home: {
        ar: 'الصفحة الرئيسية',
        cs: 'Domov',
        de: 'Zuhause',
        en: 'Home',
        fr: 'Accueil',
      },
      'instant-messaging': {
        ar: 'رسائل فورية',
        cs: 'Instantní zprávy',
        de: 'Sofortnachrichten',
        en: 'Instant messaging',
        fr: 'Messages instantanés',
      },
      insurance: {
        ar: 'فرع تأمين',
        cs: 'Pobočka pojišťovny',
        de: 'Zweigstelle der Versicherungsanstalt',
        en: 'Insurance branch',
        fr: 'Agence d’assurance',
      },
      letter: {
        ar: 'بالحروف',
        cs: 'Dopis',
        de: 'Brief',
        en: 'Letter',
        fr: 'Lettre',
      },
      'mobile-app': {
        ar: 'تطبيق الهاتف المحمول',
        cs: 'Mobilní aplikace',
        de: 'Mobile Anwendung',
        en: 'Mobile app',
        fr: 'Applications mobiles',
      },
      multicash: {
        ar: 'نقود متعددة',
        cs: 'Multicash',
        de: 'Multicash',
        en: 'Multicash',
        fr: 'Multicash',
      },
      news: {
        ar: 'الأخبار',
        cs: 'Zprávy',
        de: 'Nachrichten',
        en: 'News',
        fr: 'Actualités',
      },
      'online-banking': {
        ar: 'الخدمات المصرفية عبر الإنترنت',
        cs: 'Online bankovnictví',
        de: 'Online-Banking',
        en: 'Online banking',
        fr: 'Banque en ligne',
      },
      other: {
        ar: 'أخرى',
        cs: 'Jiné',
        de: 'Sonstiges',
        en: 'Other',
        fr: 'Autre',
      },
      phone: {
        ar: 'المكالمات الهاتفية',
        cs: 'Telefonní hovory',
        de: 'Telefonanrufe',
        en: 'Phone calls',
        fr: 'Appels téléphoniques',
      },
      'post-office': {
        ar: 'مكتب البريد',
        cs: 'Pošta',
        de: 'Post',
        en: 'Post office',
        fr: 'Bureau de poste',
      },
      'printed-communication': {
        ar: 'الاتصالات المطبوعة',
        cs: 'Tištěná komunikace',
        de: 'Gedruckte Kommunikation',
        en: 'Printed communication',
        fr: 'Communication imprimée',
      },
      process: {
        ar: 'العملية',
        cs: 'Proces',
        de: 'Prozess',
        en: 'Process',
        fr: 'Processus',
      },
      radio: {
        ar: 'الراديو',
        cs: 'Rádio',
        de: 'Radio',
        en: 'Radio',
        fr: 'Radio',
      },
      shop: {
        ar: 'تسوق',
        cs: 'Obchod',
        de: 'Laden',
        en: 'Shop',
        fr: 'Boutique',
      },
      sms: {
        ar: 'رسالة قصيرة',
        cs: 'SMS',
        de: 'SMS',
        en: 'SMS',
        fr: 'SMS',
      },
      'social-media': {
        ar: 'وسائل التواصل الاجتماعي',
        cs: 'Sociální média',
        de: 'Soziale Medien',
        en: 'Social media',
        fr: 'Médias sociaux',
      },
      unspecified: {
        ar: 'غير محدد',
        cs: 'Nespecifikované',
        de: 'Nicht spezifiziert',
        en: 'Unspecified',
        fr: 'Non précisé',
      },
      user: {
        ar: 'المستخدم',
        cs: 'Uživatel',
        de: 'Benutzer',
        en: 'User',
        fr: 'Utilisateur',
      },
      video: {
        ar: 'عقد مؤتمر بالفيديو',
        cs: 'Video konference',
        de: 'Video-Konferenz',
        en: 'Video conference',
        fr: 'Visioconférence',
      },
      'virtual-assistant': {
        ar: 'مساعد افتراضي',
        cs: 'Virtuální asistentka',
        de: 'Virtueller Assistent',
        en: 'Virtual assistant',
        fr: 'Assistant virtuel',
      },
      web: {
        ar: 'ويب',
        cs: 'Web',
        de: 'Web',
        en: 'Web',
        fr: 'Web',
      },
      'without-communication': {
        ar: 'لا اتصال',
        cs: 'Bez komunikace',
        de: 'Keine Kommunikation',
        en: 'Without communication',
        fr: 'Pas de communication',
      },
    },
  },
  channelsManagement: {
    changeChannelIcon: {
      ar: 'تغيير رمز القناة',
      cs: 'Změnit ikonu kanálu',
      de: 'Kanal-Ikone ändern',
      en: 'Change channel icon',
      fr: "Changer l'icône du canal",
    },
    channelAddButton: {
      ar: 'إنشاء قناة جديدة',
      cs: 'Vytvořit nový kanál',
      de: 'Neuen Kanal erstellen',
      en: 'Create a new channel',
      fr: 'Créer un nouveau canal',
    },
    channelDescription: {
      ar: 'الوصف',
      cs: 'Popis',
      de: 'Beschreibung',
      en: 'Description',
      fr: 'Description',
    },
    channelDescriptionPlaceholder: {
      ar: 'إدخال الوصف',
      cs: 'Vložte popis',
      de: 'Beschreibung einfügen',
      en: 'Enter description',
      fr: 'Saisir la description',
    },
    channelIcon: {
      ar: 'أيقونة',
      cs: 'Ikona',
      de: 'Symbol',
      en: 'Icon',
      fr: 'Icône',
    },
    channelNamePlaceholder: {
      ar: 'إدخال الاسم',
      cs: 'Zadejte název',
      de: 'Den Namen eingeben',
      en: 'Enter name',
      fr: 'Saisir le nom',
    },
    channelSelectIcon: {
      ar: 'تحديد أيقونة',
      cs: 'Vyberte ikonu',
      de: 'Ikone wählen',
      en: 'Select icon',
      fr: 'Sélectionner l’icône',
    },
    channel_name: {
      ar: 'اسم القناة',
      cs: 'Název kanálu',
      de: 'Kanalname',
      en: 'Channel name',
      fr: 'Nom du canal',
    },
    channels: {
      global: {
        ar: 'القنوات العالمية',
        cs: 'Globální kanály',
        de: 'Globale Kanäle',
        en: 'Global channels',
        fr: 'Canaux mondiaux',
      },
      local: {
        ar: 'القنوات المحلية',
        cs: 'Lokální kanály',
        de: 'Lokale Kanäle',
        en: 'Local channels',
        fr: 'Canaux locaux',
      },
    },
    deleteChannelModal: {
      desc: {
        ar: 'هل تريد حقًا حذف الفئة <strong>"{{name}}"</strong>؟',
        cs: 'Opravdu chcete smazat kanál <strong>"{{name}}"</strong>?',
        de: 'Wollen Sie wirklich den Kanal <strong>"{{name}}"</strong> löschen?',
        en: 'Do you really want to delete the channel <strong>"{{name}}"</strong>?',
        fr: 'Voulez-vous vraiment supprimer le canal <strong>« {{name}} »</strong> ?',
      },
      title: {
        ar: 'هل تريد حذف القناة؟',
        cs: 'Smazat kanál?',
        de: 'Kanal löschen?',
        en: 'Delete the channel?',
        fr: 'Supprimer le canal ?',
      },
    },
    editChannel: {
      ar: 'تعديل',
      cs: 'Editovat',
      de: 'Bearbeiten',
      en: 'Edit',
      fr: 'Modifier',
    },
    hidden: {
      ar: 'مخفي',
      cs: 'Skrytý',
      de: 'Ausgeblendet',
      en: 'Hidden',
      fr: 'Masqué',
    },
    newChannel: {
      ar: 'إنشاء قناة جديدة',
      cs: 'Vytvořit nový kanál',
      de: 'Neuen Kanal erstellen',
      en: 'Create a new channel',
      fr: 'Créer un nouveau canal',
    },
    searchChannelPlaceholder: {
      ar: 'البحث عن قناة',
      cs: 'Vyhledat kanál',
      de: 'Kanal suchen',
      en: 'Search channel',
      fr: 'Recherche un canal',
    },
    title: {
      ar: 'إدارة القنوات',
      cs: 'Správa kanálů',
      de: 'Kanal-Verwaltung',
      en: 'Management of Channels',
      fr: 'Gérer des canaux',
    },
    visible: {
      ar: 'مرئي',
      cs: 'Viditelný',
      de: 'Sichtbar',
      en: 'Visible',
      fr: 'Visible',
    },
  },
  common: {
    accept: {
      ar: 'تم القبول',
      cs: 'Beru na vědomí',
      de: 'Ich nehme zur Kenntnis',
      en: 'Acknowledged',
      fr: 'Je prends acte',
    },
    apply: {
      ar: 'تطبيق',
      cs: 'Aplikovat',
      de: 'Anwenden',
      en: 'Apply',
      fr: 'Appliquer',
    },
    atlas: {
      ar: 'أطلس',
      cs: 'Atlas',
      de: 'Atlas',
      en: 'Atlas',
      fr: "L'atlas",
    },
    atlasPlaceholder: {
      default: {
        ar: 'اختار الاطلس',
        cs: 'Vyberte atlas',
        de: 'Atlas auswählen',
        en: 'Select atlas',
        fr: "Sélectionner l'atlas",
      },
      noAtlases: {
        ar: 'لا توجد أطالس',
        cs: 'Žádné atlasy',
        de: 'Keine Atlasse',
        en: 'No atlases',
        fr: 'Pas d’atlas',
      },
    },
    atlases: {
      ar: 'أطالس',
      cs: 'Atlasy',
      de: 'Atlasse',
      en: 'Atlases',
      fr: 'Atlas',
    },
    back: {
      ar: 'خطوة إلى الخلف',
      cs: 'Zpět',
      de: 'Zurück',
      en: 'Back',
      fr: 'Retour',
    },
    backlog: {
      ar: 'قائمة المهام',
      cs: 'Backlog',
      de: 'Backlog',
      en: 'Backlog',
      fr: 'Backlog',
    },
    cancel: {
      ar: 'إلغاء',
      cs: 'Zrušit',
      de: 'Aufheben',
      en: 'Cancel',
      fr: 'Annuler',
    },
    clear: {
      ar: 'محو',
      cs: 'Vymazat',
      de: 'Tilgen',
      en: 'Clear',
      fr: 'Effacer',
    },
    close: {
      ar: 'يغلق',
      cs: 'Zavřít',
      de: 'Schließen',
      en: 'Close',
      fr: 'Fermer',
    },
    confirm: {
      ar: 'تأكيد',
      cs: 'Potvrdit',
      de: 'Bestätigen',
      en: 'Confirm',
      fr: 'Confirmer',
    },
    continue: {
      ar: 'متابعة',
      cs: 'Pokračovat',
      de: 'Weiter',
      en: 'Continue',
      fr: 'Continuer',
    },
    create: {
      ar: 'إنشاء',
      cs: 'Vytvořit',
      de: 'Erstellen',
      en: 'Create',
      fr: 'Créer',
    },
    delete: {
      ar: 'حذف',
      cs: 'Smazat',
      de: 'Löschen',
      en: 'Delete',
      fr: 'Effacer',
    },
    deletePermanently: {
      ar: 'حذف نهائياً',
      cs: 'Smazat permanentně',
      de: 'Dauerhaft löschen',
      en: 'Delete permanently',
      fr: 'Supprimer définitivement',
    },
    description: {
      ar: 'الوصف',
      cs: 'Popis',
      de: 'Beschreibung',
      en: 'Description',
      fr: 'Description',
    },
    edit: {
      ar: 'تعديل',
      cs: 'Editovat',
      de: 'Bearbeiten',
      en: 'Edit',
      fr: 'Modifier',
    },
    enterDescription: {
      ar: 'أدخل الوصف',
      cs: 'Zadejte popis',
      de: 'Beschreibung eingeben',
      en: 'Enter description',
      fr: 'Saisir la description',
    },
    enterName: {
      ar: 'ادخل الاسم',
      cs: 'Zadejte název',
      de: 'Den Namen eingeben',
      en: 'Enter name',
      fr: 'Saisir un nom',
    },
    filter: {
      ar: 'عملية الترشيح',
      cs: 'Filtrovat',
      de: 'Filtern',
      en: 'Filter',
      fr: 'Filtre',
    },
    filters: {
      ar: 'المرشحات',
      cs: 'Filtry',
      de: 'Filter',
      en: 'Filters',
      fr: 'Filtres',
    },
    folder: {
      ar: 'مجلد',
      cs: 'Složka',
      de: 'Ordner',
      en: 'Folder',
      fr: 'Dossier',
    },
    folderPlaceholder: {
      default: {
        ar: 'اختيار المجلد',
        cs: 'Vybrat složku',
        de: 'Ordner wählen',
        en: 'Select folder',
        fr: 'Sélectionner le dossier',
      },
      noAtlas: {
        ar: 'اختار الاطلس أولاً',
        cs: 'Nejprve vyberte atlas',
        de: 'Zuerst Atlas wählen',
        en: 'Select atlas first',
        fr: "Sélectionner l'atlas en premier",
      },
      noFolders: {
        ar: 'لا توجد أية مجلدات',
        cs: 'Žádné složky',
        de: 'Keine Ordner',
        en: 'No folders',
        fr: 'Pas de dossier',
      },
    },
    groups: {
      ar: 'المجموعات',
      cs: 'Skupiny',
      de: 'Gruppen',
      en: 'Groups',
      fr: 'Groupes',
    },
    groupsManagement: {
      ar: 'إدارة المجموعات',
      cs: 'Správa skupin',
      de: 'Gruppen-Verwaltung',
      en: 'Group management',
      fr: 'Administration des groupes',
    },
    hide: {
      ar: 'إخفاء',
      cs: 'Schovat',
      de: 'Ausblenden',
      en: 'Hide',
      fr: 'Masquer',
    },
    icon: {
      ar: 'أيقونة',
      cs: 'Ikona',
      de: 'Symbol',
      en: 'Icon',
      fr: 'Icône',
    },
    id: {
      ar: 'الرقم',
      cs: 'ID',
      de: 'ID',
      en: 'ID',
      fr: 'ID',
    },
    inProgress: {
      ar: 'جارٍ التنفيذ',
      cs: 'Rozpracovaná',
      de: 'Angearbeitet',
      en: 'In progress',
      fr: 'En cours de développement',
    },
    inProgressing: {
      ar: 'تجري حالياً',
      cs: 'Rozpracované',
      de: 'Angearbeitet',
      en: 'In progress',
      fr: 'En cours',
    },
    inlineProcessButtons: {
      cancel: {
        ar: 'إلغاء',
        cs: 'Zrušit',
        de: 'Abbrechen',
        en: 'Cancel',
        fr: 'Annuler',
      },
      save: {
        ar: 'حفظ',
        cs: 'Uložit',
        de: 'Speichern',
        en: 'Save',
        fr: 'Enregistrer',
      },
    },
    journey: {
      ar: 'رحلة',
      cs: 'Cesta',
      de: 'Pfad',
      en: 'Journey',
      fr: 'Parcours',
    },
    journeys: {
      ar: 'رحلات',
      cs: 'Cesty',
      de: 'Pfade',
      en: 'Journeys',
      fr: 'Parcours',
    },
    listOfAtlases: {
      ar: 'جميع الأطالس',
      cs: 'Přehled atlasů',
      de: 'Atlasse im Überblick',
      en: 'All Atlases',
      fr: 'Tous les atlas',
    },
    listOfFolders: {
      ar: 'لائحة المجلدات',
      cs: 'Seznam složek',
      de: 'Ordner-Liste',
      en: 'List of folders',
      fr: 'Liste de dossiers',
    },
    listOfTenants: {
      ar: 'لائحة المستأجرين',
      cs: 'Seznam tenantů',
      de: 'Tenant-liste',
      en: 'List of Tenants',
      fr: 'Liste des tenants',
    },
    manageChannels: {
      ar: 'إدارة القنوات',
      cs: 'Správa kanálů',
      de: 'Kanal-Verwaltung',
      en: 'Manage channels',
      fr: 'Gérer des canaux',
    },
    manageTenant: {
      ar: 'إدارة المستأجر',
      cs: 'Správa tenanta',
      de: 'Tenant-Verwaltung',
      en: 'Management of Tenant',
      fr: 'Administration du tenant',
    },
    managementOfKpmgUsers: {
      ar: 'إدارة مستخدمي كيه بي إم جي KPMG',
      cs: 'Správa uživatelů KPMG',
      de: 'KPMG-Benutzerverwaltung',
      en: 'Management of KPMG users',
      fr: 'Administration des utilisateurs KPMG',
    },
    managementOfUsers: {
      ar: 'إدارة المستخدمين',
      cs: 'Správa uživatelů',
      de: 'Benutzerverwaltung',
      en: 'Management of Users',
      fr: 'Administration des utilisateurs',
    },
    name: {
      ar: 'الاسم',
      cs: 'Název',
      de: 'Name',
      en: 'Name',
      fr: 'Nom',
    },
    newAtlas: {
      ar: 'أطلس جديد',
      cs: 'Nový atlas',
      de: 'Neuer Atlas',
      en: 'New Atlas',
      fr: 'Nouvel atlas',
    },
    newFolder: {
      ar: 'مجلد جديد',
      cs: 'Nová složka',
      de: 'Neuer Ordner',
      en: 'New Folder',
      fr: 'Nouveau dossier',
    },
    newGroup: {
      ar: 'مجموعة جديدة',
      cs: 'Nová skupina',
      de: 'Neue Gruppe',
      en: 'New Group',
      fr: 'Nouveau groupe',
    },
    newItem: {
      ar: 'عنصر جديد',
      cs: 'Nová položka',
      de: 'Neues Element',
      en: 'New item',
      fr: 'Nouvel élément',
    },
    newJourney: {
      ar: 'رحلة جديدة',
      cs: 'Nová cesta',
      de: 'Neuer Pfad',
      en: 'New Journey',
      fr: 'Nouveau parcours',
    },
    newPersona: {
      ar: 'شخصية جديدة',
      cs: 'Nová Persona',
      de: 'Neue Person',
      en: 'New Persona',
      fr: 'Nouveau persona',
    },
    newTenant: {
      ar: 'مستأجر جديد',
      cs: 'Nový tenant',
      de: 'Neuer Tenant',
      en: 'New Tenant',
      fr: 'Nouveau tenant',
    },
    ok: {
      ar: 'أوكي تمام',
      cs: 'OK',
      de: 'OK',
      en: 'OK',
      fr: 'OK',
    },
    persona: {
      ar: 'الشخصية',
      cs: 'Persona',
      de: 'Person',
      en: 'Persona',
      fr: 'Persona',
    },
    powerFlow: {
      environment: {
        ar: 'البيئة الحيوية',
        cs: 'Životní prostředí',
        de: 'Umgebung',
        en: 'Environment',
        fr: 'Environnement',
      },
      exportProcess: {
        ar: 'عملية النقل إلى الخارج',
        cs: 'Proces exportu',
        de: 'Export-Prozess',
        en: 'Export process',
        fr: "Processus d'exportation",
      },
      loadingEnvironment: {
        ar: 'جاري تحميل البيئة...',
        cs: 'Načítání prostředí...',
        de: 'Laden von Umgebungen...',
        en: 'Loading environments...',
        fr: 'Chargement des environnements...',
      },
      powerFlowButtons: {
        close: {
          ar: 'يغلق',
          cs: 'Zavřít',
          de: 'Schließen',
          en: 'Close',
          fr: 'Fermer',
        },
        export: {
          ar: 'يصدّر',
          cs: 'Export',
          de: 'Export',
          en: 'Export',
          fr: 'Exporter',
        },
      },
      tag: {
        ar: 'تسمية',
        cs: 'Štítek',
        de: 'Tag',
        en: 'Tag',
        fr: 'Tag',
      },
      title: {
        ar: 'اللقب',
        cs: 'Název',
        de: 'Name',
        en: 'Title',
        fr: 'Titre',
      },
    },
    processButtons: {
      cancel: {
        ar: 'إلغاء',
        cs: 'Zrušit',
        de: 'Abbrechen',
        en: 'Cancel',
        fr: 'Annuler',
      },
      loadXml: {
        ar: 'تحميل BPMN',
        cs: 'Nahrát BPMN',
        de: 'BPMN laden',
        en: 'Load BPMN',
        fr: 'Charger BPMN',
      },
      save: {
        ar: 'حفظ',
        cs: 'Uložit',
        de: 'Speichern',
        en: 'Save',
        fr: 'Enregistrer',
      },
      saveSvg: {
        ar: 'حفظ رسومات موجهة قابلة للتحجيم SVG',
        cs: 'Uložit SVG',
        de: 'SVG speichern',
        en: 'Save SVG',
        fr: 'Sauvegarder SVG',
      },
      saveXml: {
        ar: 'حفظ BPMN',
        cs: 'Uložit BPMN',
        de: 'BPMN speichern',
        en: 'Save BPMN',
        fr: 'Enregistrer BPMN',
      },
    },
    publish: {
      ar: 'نُشِر',
      cs: 'Publikovaná',
      de: 'Publiziert',
      en: 'Published',
      fr: 'Publié',
    },
    published: {
      ar: 'تم نشره',
      cs: 'Publikováno',
      de: 'Publiziert',
      en: 'Published',
      fr: 'Publié',
    },
    publishing: {
      ar: 'المعلن',
      cs: 'Publikované',
      de: 'Veröffentlicht',
      en: 'Published',
      fr: 'Publié',
    },
    recycleBin: {
      ar: 'سلّة المحذوفات',
      cs: 'Koš',
      de: 'Papierkorb',
      en: 'Recycle bin',
      fr: 'Corbeille',
    },
    restore: {
      ar: 'الاستعادة',
      cs: 'Obnovit',
      de: 'Erneuern',
      en: 'Restore',
      fr: 'Restaurer',
    },
    save: {
      ar: 'حفظ',
      cs: 'Uložit',
      de: 'Speichern',
      en: 'Save',
      fr: 'Enregistrer',
    },
    search: {
      ar: 'البحث عن مستخدم',
      cs: 'Vyhledat uživatele',
      de: 'Benutzer suchen',
      en: 'Search user',
      fr: 'Rechercher un utilisateur',
    },
    searchResults: {
      ar: 'نتائج البحث',
      cs: 'Výsledky vyhledávání',
      de: 'Suchergebnisse',
      en: 'Search Results',
      fr: 'Résultats de la recherche',
    },
    show: {
      ar: 'إظهار',
      cs: 'Zobrazit',
      de: 'Anzeigen',
      en: 'Unhide',
      fr: 'Rendre visible',
    },
    status: {
      ar: 'الحالة',
      cs: 'Stav',
      de: 'Status',
      en: 'Status',
      fr: 'Statut',
    },
    submit: {
      ar: 'إرسال',
      cs: 'Odeslat',
      de: 'Senden',
      en: 'Submit',
      fr: 'Soumettre',
    },
    success: {
      ar: 'نجاح',
      cs: 'Úspěch',
      de: 'Erfolg',
      en: 'Success',
      fr: 'Succès',
    },
    tenant: {
      ar: 'المستأجر',
      cs: 'Tenant',
      de: 'Tenant',
      en: 'Tenant',
      fr: 'Tenant',
    },
    tutorials: {
      ar: 'عروض توضيحية',
      cs: 'Tutoriály',
      de: 'Anleitungen',
      en: 'Tutorials',
      fr: 'Tutoriels',
    },
  },
  connectedCapabilities: {
    capabilities: {
      ar: 'القدرة',
      cs: 'Kapability',
      de: 'Kapabilitäten',
      en: 'Capabilities',
      fr: 'Capacités',
    },
    description: {
      ar: 'لتقديم توقعات المستهلك المتزايدة باستمرار للحصول على تجربة حقيقية تركز على العملاء ولتحقيق أهداف المؤسسة، يجب على المؤسسات اتباع نهج شامل',
      cs: 'To deliver on ever-increasing consumer expectations for a true customer-centric experience and to achieve enterprise goals, organisations must take a holistic approach',
      de: 'Um den steigenden Verbrauchererwartungen an eine echte kundenorientierte Erfahrung gerecht zu werden und Unternehmensziele zu erreichen, haben Unternehmen einen ganzheitlichen Ansatz zu verfolgen.',
      en: 'To deliver on ever-increasing consumer expectations for a true customer-centric experience and to achieve enterprise goals, organisations must take a holistic approach',
      fr: "Pour répondre aux attentes toujours plus grandes des consommateurs en matière d'expérience centrée sur le client et pour atteindre les objectifs de l'entreprise, les organisations doivent adopter une approche holistique",
    },
    importInProgress: {
      ar: '...جاري الاستيراد',
      cs: 'Import probíhá...',
      de: 'Import läuft...',
      en: 'Import in progress...',
      fr: "L'importation en cours...",
    },
    kpmgConnectedCapabilities: {
      ar: 'قدرات KPMG المتصلة',
      cs: 'KPMG Connected capabilities',
      de: 'KPMG Connected capabilities',
      en: 'KPMG Connected capabilities',
      fr: 'KPMG Connected capabilities',
    },
    publishTooltip: {
      ar: 'لنشر قدرة فرعية ، يلزم ملء العنوان والوصف لجميع اللغات المتاحة.',
      cs: 'Pro publikaci subkapability je třeba vyplnit název a popis pro všechny dostupné jazyky.',
      de: 'Um die Sub-Kapabilität zu veröffentlichen, so sind der Name und die Beschreibung für alle verfügbaren Sprachen einzugeben.',
      en: 'To publish the sub-capability, you have to enter the names and descriptions for all available language versions.',
      fr: 'Pour publier une sous-capacité, vous devez remplir le titre et la description pour toutes les langues disponibles.',
    },
    subCapabilities: {
      ar: 'القدرة الفرعية',
      cs: 'SUBKAPABILITY',
      de: 'SUB-KAPABILITÄTEN',
      en: 'SUB-CAPABILITIES',
      fr: 'SOUS-CAPACITÉS',
    },
    subCapabilitiesLoweCase: {
      ar: 'القدرة الفرعية',
      cs: 'Subkapability',
      de: 'Sub-Kapabilitäten',
      en: 'Sub-capabilities',
      fr: 'Sous-capacités',
    },
    textNotFound: {
      ar: 'لم يتم العثور على النص - يجب تعبئته لهذا الموقع.',
      cs: 'Text nenalezen – Nutno vyplnit pro tuto lokalizaci.',
      de: 'Text nicht gefunden – Ist für diese Lokalisierung auszufüllen.',
      en: 'Text not found – please fill in for this localisation.',
      fr: 'Texte introuvable – Doit être rempli pour cet emplacement.',
    },
  },
  deleteModal: {
    archivedTenant: {
      deleteArchivedTenant: {
        ar: 'حذف المستأجر نهائيا؟',
        cs: 'Trvale odstranit tenant?',
        de: 'Den Tenant dauerhaft entfernen?',
        en: 'Delete the tenant permanently?',
        fr: 'Supprimer définitivement le tenant ?',
      },
      doYouWantToDeleteTenantPermanently: {
        ar: 'إذا قمت بحذف المستأجر <strong>"{{name}}"</strong> بشكل دائم، فلن تتمكن من استعادته أبدًا.',
        cs: 'Pokud tenanta <strong>"{{name}}"</strong> trvale smažete, nebude možné jej již nikdy obnovit.',
        de: 'Wenn Sie den Tenant <strong>"{{name}}"</strong> dauerhaft löschen, kann er nicht wiederhergestellt werden.',
        en: 'If you delete the tenant <strong>"{{name}}"</strong> permanently, it can never be restored.',
        fr: 'Si vous supprimez définitivement le tenant <strong>"{{name}}"</strong>, il ne pourra jamais être restauré.',
      },
    },
    atlases: {
      deleteAtlas: {
        ar: 'حذف أطلس؟',
        cs: 'Smazat atlas?',
        de: 'Den Atlas löschen?',
        en: 'Delete atlas?',
        fr: "Effacer l'atlas ?",
      },
      emptyAtlasDescription: {
        ar: 'هل أنت متأكد أنك تريد حذف الأطلس <strong>"{{name}}"</strong> ؟ الحذف لا رجعة فيه',
        cs: 'Opravdu si přejete smazat atlas <strong>"{{name}}"</strong> ? Smazání je nevratné.',
        de: 'Möchten Sie den Atlas <strong>"{{name}}"</strong> wirklich löschen? Das Löschen ist nicht reversibel.',
        en: 'Do you really want to delete atlas <strong>"{{name}}"</strong> ? The deletion is irreversible.',
        fr: 'Désirez-vous vraiment supprimer l’atlas <strong>"{{name}}"</strong> ? La suppression est irréversible.',
      },
      nonEmptyAtlasDescription: {
        ar: 'أطلس <strong>"{{name}}"</strong> ليس فارغًا. هل تريد حقًا حذفه؟ الحذف لا رجعة فيه.',
        cs: 'Atlas <strong>"{{name}}"</strong> není prázdný. Opravdu si ho přejete smazat? Smazání je nevratné.',
        de: 'Der Atlas <strong>"{{name}}"</strong> ist nicht leer. Möchten Sie ihn wirklich löschen? Das Löschen ist irreversibel.',
        en: 'Atlas <strong>"{{name}}"</strong> is not empty. Do you really want to delete it? The deletion is irreversible.',
        fr: "L'atlas <strong>\"{{name}}\"</strong> n'est pas vide. Désirez-vous vraiment l'effacer ? L'effacement est irréversible.",
      },
    },
    channelsManagementModal: {
      body: {
        ar: 'هل ترغب حقًا في حذف القناة؟ لتجديدها، يجب عليك الاتصال بالمسؤول.',
        cs: 'Opravdu si přejete smazat kanál? Pro obnovení je třeba kontaktovat administrátora.',
        de: 'Möchten Sie den Kanal wirklich löschen? Um ihn wiederherzustellen, müssen Sie sich an den Administrator wenden.',
        en: 'Do you really want to delete the channel? To restore the channel, you will need to contact the administrator.',
        fr: "Souhaitez-vous vraiment supprimer le canal ? Pour restaurer le canal, il faudra contacter l'administrateur.",
      },
      title: {
        ar: 'أتريد حذف قناة؟',
        cs: 'Smazat kanál?',
        de: 'Kanal löschen?',
        en: 'Delete the channel?',
        fr: 'Supprimer le canal ?',
      },
    },
    delete: {
      ar: 'حذف',
      cs: 'Smazat',
      de: 'Löschen',
      en: 'Delete',
      fr: 'Effacer',
    },
    folder: {
      deleteFolder: {
        ar: 'حذف مجلد؟',
        cs: 'Smazat složku?',
        de: 'Ordner löschen?',
        en: 'Delete folder?',
        fr: 'Effacer le dossier ?',
      },
      emptyFolderDescription: {
        ar: 'هل أنت متأكد أنك تريد حذف المجلد <strong>"{{name}}"</strong> ؟ الحذف لا رجعة فيه',
        cs: 'Opravdu si ji přejete smazat složku <strong>"{{name}}"</strong> ? Smazání je nevratné.',
        de: 'Möchten Sie den Ordner <strong>"{{name}}"</strong> wirklich löschen? Das Löschen ist nicht reversibel.',
        en: 'Do you really want to delete folder <strong>"{{name}}"</strong> ? The deletion is irreversible.',
        fr: 'Désirez-vous vraiment supprimer le dossier <strong>"{{name}}"</strong> ? La suppression est irréversible.',
      },
      nonEmptyFolderDescription: {
        ar: 'مجلد <strong>"{{name}}"</strong> ليس فارغا. هل تريد حقًا حذفه؟ الحذف لا رجعة فيه.',
        cs: 'Složka <strong>"{{name}}"</strong> není prázdná. Opravdu si ji přejete smazat? Smazání je nevratné.',
        de: 'Der Folder <strong>"{{name}}"</strong> ist nicht leer. Möchten Sie ihn wirklich löschen? Das Löschen ist irreversibel.',
        en: 'Folder <strong>"{{name}}"</strong> is not empty. Do you really want to delete it? The deletion is irreversible.',
        fr: "Le dossier <strong>\"{{name}}\"</strong> n'est pas vide. Désirez-vous vraiment l'effacer ? L'effacement est irréversible.",
      },
    },
    journey: {
      deleteJourney: {
        ar: 'حذف رحلة؟',
        cs: 'Smazat cestu?',
        de: 'Pfad löschen?',
        en: 'Delete Journey?',
        fr: 'Effacer le parcours ?',
      },
      description: {
        ar: '? <strong>"{{name}}"</strong> هل تود حقًا حذف رحلة ',
        cs: 'Opravdu si přejete smazat cestu <strong>"{{name}}"</strong> ?',
        de: 'Möchten Sie den Pfad <strong>"{{name}}"</strong> wirklich löschen?',
        en: 'Do you really want to delete Journey <strong>"{{name}}"</strong> ?',
        fr: 'Désirez-vous vraiment effacer le parcours <strong>"{{name}}"</strong> ?',
      },
    },
    personas: {
      deletePersona: {
        ar: 'حذف شخصية؟',
        cs: 'Smazat personu?',
        de: 'Person löschen?',
        en: 'Delete Persona?',
        fr: 'Supprimer le persona ?',
      },
      description: {
        ar: 'هل تريد فعلا إزالة الشخص <strong>"{{name}}"</strong>؟',
        cs: 'Opravdu chcete odstranit osobu <strong>"{{name}}"</strong>?',
        de: 'Möchten Sie die Person <strong>"{{name}}"</strong> wirklich löschen?',
        en: 'Do you really want to delete the persona <strong>"{{name}}"</strong>?',
        fr: 'Voulez-vous vraiment supprimer le persona <strong>"{{name}}"</strong> ?',
      },
      journeys: {
        ar: 'الشخصية مرتبطة {{count}} رحلات. هل تريد إلغاء تنشيط الشخصية؟',
        cs: 'Persona má vazbu na {{count}} cest.',
        de: 'Es besteht eine Verbindung zwischen der Persona und {{count}} der Pfade.',
        en: 'Persona is connected to {{count}} journeys.',
        fr: 'Le persona est lié à {{count}} routes.',
      },
    },
    subcapability: {
      connected: {
        ar: '{{count}} متصل',
        cs: '{{count}} spojeny',
        de: '{{count}} zusammengefügt',
        en: '{{count}} connected',
        fr: '{{count}} connectées',
      },
      deleteSubcapability: {
        ar: 'حذف القدرة الفرعية',
        cs: 'Smazat subkapabilitu?',
        de: 'Sub-Kapabilität löschen?',
        en: 'Delete sub-capability',
        fr: 'Supprimer la sous-capacité',
      },
      description: {
        ar: 'هل تريد حقًا حذف القدرة الفرعية "{{name}}"؟ هذه الخطوة لا رجوع فيها!',
        cs: 'Opravdu si přejete odstranit subkapabilitu "{{name}}"? Tento krok je NEVRATNÝ!',
        de: 'Wollen Sie die Sub-Kapabilität "{{name}}" wirklich entfernen? Dieser Schritt ist IRREVERSIBEL!',
        en: 'Do you really wish to delete this sub-capability "{{name}}"? This step is IRREVERSIBLE!',
        fr: 'Voulez-vous vraiment supprimer la sous-capacité "{{name}}"? Cette étape est IRREVERSIBLE !',
      },
    },
    tenant: {
      deleteTenant: {
        ar: 'هل تريد حذف المستأجر؟',
        cs: 'Smazat tenant?',
        de: 'Den Tenant löschen?',
        en: 'Delete the tenant?',
        fr: 'Supprimer le tenant ?',
      },
      doYouWantToDeleteTenant: {
        ar: 'هل تريد حقًا حذف الفئة <strong>"{{name}}"</strong>؟',
        cs: 'Opravdu chcete smazat tenant <strong>"{{name}}"</strong>?',
        de: 'Wollen Sie den Tenant <strong>"{{name}}"</strong> wirklich löschen?',
        en: 'Do you really want to delete the tenant <strong>"{{name}}"</strong>?',
        fr: 'Voulez-vous vraiment supprimer le tenant <strong>« {{name}} »</strong> ?',
      },
      permanentDelete: {
        ar: 'الحذف الدائم للمستأجر',
        cs: 'Trvalé smazání tenantu',
        de: 'Tenant dauerhaft löschen',
        en: 'Permanent deletion of the tenant',
        fr: 'Suppression définitive du tenant',
      },
    },
  },
  duplicateJourney: {
    ar: 'طريق مكرر',
    cs: 'Duplikovat cestu',
    de: 'Pfad duplizieren',
    en: 'Duplicate journey',
    fr: 'Dupliquer le parcours',
  },
  duplicateJourneyTenantType: {
    current: {
      ar: 'المستأجر الحالي',
      cs: 'Aktuální tenant',
      de: 'Aktueller Tenant',
      en: 'Current tenant',
      fr: 'Tenant actuel',
    },
    template: {
      ar: 'مستأجر القالب',
      cs: 'Šablonovací tenant',
      de: 'Vorlage-Tenant',
      en: 'Template tenant',
      fr: 'Modèle de tenant',
    },
  },
  errorModal: {
    acceptBtn: {
      ar: 'تم القبول',
      cs: 'Beru na vědomí',
      de: 'Ich nehme zur Kenntnis',
      en: 'Acknowledged',
      fr: 'Je prends acte',
    },
    error: {
      ar: 'خطأ!',
      cs: 'Chyba!',
      de: 'Fehler!',
      en: 'Error!',
      fr: 'Erreur !',
    },
    journeys: {
      description: {
        export: {
          ar: 'حدث خطأ أثناء تصدير البيانات. [Error code: {{name}}]',
          cs: 'Došlo k chybě při exportu dat. [Kód chyby: {{name}}].',
          de: 'Beim Export von Daten ist ein Fehler aufgetreten [Fehlercode: {{name}}].',
          en: 'There was an error while exporting the data. [Error code: {{name}}]',
          fr: "Erreur lors de l'exportation de données. [Code de l'erreur : {{name}}]",
        },
        import: {
          ar: 'ححدث خطأ أثناء استيراد البيانات. [Error code: {{name}}]',
          cs: 'Došlo k chybě při importu dat. [Kód chyby: {{name}}].',
          de: 'Beim Import von Daten ist ein Fehler aufgetreten [Fehlercode: {{name}}].',
          en: 'There was an error while importing the data. [Error code: {{name}}]',
          fr: "Erreur lors de l'importation de données. [Code de l'erreur : {{name}}]",
        },
      },
    },
  },
  errors: {
    cannotLoadConfigJsonModal: {
      btn: {
        ar: 'أعد تحميل التطبيق',
        cs: 'Přenačíst aplikaci',
        de: 'Anwendung erneut laden',
        en: 'Reload application',
        fr: 'Recharger l’application',
      },
      title: {
        ar: 'غير قادر على تحميل config.json! لا يمكن أن تستمر العملية',
        cs: 'Nelze načíst soubor config.json! Proces nemůže pokračovat.',
        de: 'Die Datei config.json kann nicht geladen werden! Der Prozess kann nicht fortgesetzt werden.',
        en: 'Cannot load config.json file! Process cannot continue.',
        fr: 'Impossible de charger le fichier config.json. Le processus ne peut pas continuer.',
      },
    },
    cannotLoadGroupList: {
      ar: 'فشل تنزيل قائمة المجموعات. حاول مرة أخرى أو لاحقاً',
      cs: 'Nepodařilo se stáhnout seznam skupin. Zkuste to znovu nebo to zkuste později.',
      de: 'Die Gruppen-Liste konnte nicht heruntergeladen werden. Versuchen Sie es erneut oder zu einem späteren Zeitpunkt.',
      en: 'An error has occurred while downloading the list of groups. Try again or try later.',
      fr: "Une erreur s'est produite lors du téléchargement de la liste des groupes. Réessayez ou réessayez plus tard.",
    },
    cannotLoadTenantData: {
      ar: 'حدث خطأ أثناء تنزيل بيانات المستأجر. حاول مرة أخرى أو حاول لاحقًا.',
      cs: 'Nepodařilo se stáhnout data tenanta. Zkuste to znovu nebo to zkuste později.',
      de: 'Die Tenant-Daten konnten nicht heruntergeladen werden. Versuchen Sie es erneut oder zu einem späteren Zeitpunkt.',
      en: 'An error has occurred while downloading the tenant data. Try again or try later.',
      fr: 'Une erreur s’est produite lors du téléchargement des données du tenant. Réessayez maintenant ou plus tard.',
    },
    cannotLoadUserList: {
      ar: 'فشل تنزيل قائمة المستخدمين. حاول مرة أخرى أو لاحقاً',
      cs: 'Nepodařilo se stáhnout seznam uživatelů. Zkuste to znovu nebo to zkuste později.',
      de: 'Die Benutzerliste konnten nicht heruntergeladen werden. Versuchen Sie es erneut oder zu einem späteren Zeitpunkt.',
      en: 'An error has occurred while downloading the list of users. Try again or try later.',
      fr: "Une erreur s'est produite lors du téléchargement de la liste des utilisateurs. Réessayez ou essayez plus tard.",
    },
    default: {
      content: {
        ar: 'الرجاء معاودة المحاولة في وقت لاحق',
        cs: 'Zkuste svůj požadavek zopakovat později',
        de: 'Versuchen Sie, Ihre Anfrage später zu wiederholen',
        en: 'Try to repeat your request later.',
        fr: 'Essayez de répéter votre demande plus tard',
      },
      title: {
        ar: '‫خطأ‬',
        cs: 'Chyba',
        de: 'Fehler',
        en: 'Error',
        fr: 'Erreur',
      },
    },
    errorCodeModal: {
      errorDetail: {
        ar: 'تفاصيل الخطاً:',
        cs: 'Detail chyby:',
        de: 'Fehler-Detail:',
        en: 'Error detail:',
        fr: "Détail de l'erreur :",
      },
    },
    invalidConfigJsonModal: {
      btn: {
        ar: 'أعد تحميل التطبيق',
        cs: 'Znovu načíst aplikaci',
        de: 'App neu laden',
        en: 'Reload application',
        fr: 'Recharger l’application',
      },
      title: {
        ar: 'ملف config.json غير صالح! لا يمكن أن تستمر العملية',
        cs: 'Soubor config.json není platný! Proces nemůže pokračovat.',
        de: 'Die Datei config.json ist ungültig! Der Prozess kann nicht fortgesetzt werden.',
        en: 'File config.json is not valid! Process cannot continue.',
        fr: 'Le fichier config.json n’est pas valide. Le processus ne peut pas continuer.',
      },
    },
  },
  exportImport: {
    export: {
      ar: 'تصدير',
      cs: 'Export',
      de: 'Export',
      en: 'Export',
      fr: 'Exporter',
    },
    exportIntoXml: {
      ar: 'تصدير إلى XML',
      cs: 'Export do XML',
      de: 'Export ins XML-Format',
      en: 'Export into XML',
      fr: 'Exporter en XML',
    },
    exportModal: {
      ar: 'التصدير قيد المعالجة...',
      cs: 'Export probíhá...',
      de: 'Der Exportprozess läuft...',
      en: 'The export is being processed...',
      fr: 'Exportation en cours...',
    },
    exportTenantUsers: {
      ar: 'تصدير المستخدمين',
      cs: 'Export uživatelů',
      de: 'Benutze-Export',
      en: 'Export of users',
      fr: 'Exporter les utilisateurs',
    },
    exportToPdf: {
      ar: 'تصدير إلى PDF',
      cs: 'PDF',
      de: 'PDF',
      en: 'PDF',
      fr: 'PDF',
    },
    exportToXlsx: {
      ar: 'تصدير إلى XLSX',
      cs: 'XLS',
      de: 'XLS',
      en: 'XLS',
      fr: 'XLS',
    },
    exportXML: {
      ar: 'تصدير إلى XML',
      cs: 'XML',
      de: 'XML',
      en: 'XML',
      fr: 'XML',
    },
    importModal: {
      ar: 'الاستيراد قيد المعالجة ...',
      cs: 'Import probíhá...',
      de: 'Der Importvorgang läuft...',
      en: 'The import is being processed...',
      fr: 'Importation en cours...',
    },
    importToXlsx: {
      ar: 'استيراد من XLS',
      cs: 'Import (XLS)',
      de: 'Import (XLS)',
      en: 'Import (XLS)',
      fr: 'Importation (XLS)',
    },
  },
  folder: {
    selectedFolder: {
      ar: 'حدد الأطلس حيث تريد نقل المجلد.',
      cs: 'Vyberte atlas, kam chcete složku přesunout.',
      de: 'Wählen Sie den Atlas aus, in den Sie den Ordner verschieben möchten.',
      en: 'Select to which Atlas you want to move the folder.',
      fr: "Choisissez l'atlas vers lequel vous désirez déplacer le dossier.",
    },
  },
  journeyName: {
    ar: 'نسخة',
    cs: 'Duplikovat',
    de: 'Duplizieren',
    en: 'Duplicate',
    fr: 'Dupliquer',
  },
  journeys: {
    add: {
      ar: 'إلغاء',
      cs: 'Přidat',
      de: 'Hinzufügen',
      en: 'Add',
      fr: 'Ajouter',
    },
    addLayerBtn: {
      ar: 'إلغاء',
      cs: 'Přidat vrstvu',
      de: 'Layer hinzufügen',
      en: 'Add layer',
      fr: 'Ajoutez une couche',
    },
    backlog: {
      editBacklogItem: {
        ar: 'تحرير قائمة المهام',
        cs: 'Upravit položku backlogu',
        de: 'Backlog-Element anpassen',
        en: 'Edit backlog',
        fr: 'Modifier le backlog',
      },
      noActiveBacklog: {
        ar: 'لا يحتوي العنصر على عنصر قائمة مهام نشط.',
        cs: 'Položka neobsahuje žádnou aktivní položku backlogu.',
        de: 'Der Posten enthält kein aktives Backlog-Element.',
        en: 'Item contains no active backlog item.',
        fr: "L'élément ne contient pas d'élément de backlog actif.",
      },
      selectFromList: {
        ar: 'اختيار قصة مستخدم',
        cs: 'Vyberte ze seznamu',
        de: 'Aus der Liste wählen',
        en: 'Select from list',
        fr: 'Sélectionnez de la liste',
      },
      terminatedItem: {
        ar: 'تم إنهاء أو حذف عنصر قائمة المهام، أو لا تتوفر بياناته في الصفحة بعد.',
        cs: 'Položky backlogu jsou buď ve stavu zrušeno, smazané, nebo stránka ještě nemá jejich údaje.',
        de: 'Die Backlog-Elemente haben entweder den Status aufgehoben, gelöscht oder die Seite enthält noch keine Daten.',
        en: 'Backlog item is either terminated or deleted, or page does not have its data yet.',
        fr: "L'élément de backlog est soit terminé, soit supprimé, ou la page manque encore de ses données.",
      },
    },
    cancel: {
      ar: 'إلغاء',
      cs: 'Zrušit',
      de: 'Aufheben',
      en: 'Cancel',
      fr: 'Annuler',
    },
    changeInProgress: {
      ar: 'جاري مزامنة البيانات ، يرجى الانتظار.',
      cs: 'Data se synchronizují, vyčkejte prosím.',
      de: 'Die Daten werden synchronisiert, warten Sie bitte.',
      en: 'Data is synchronizing, please wait.',
      fr: 'Les données sont en cours de synchronisation, veuillez patienter.',
    },
    connect: {
      ar: 'توصيل',
      cs: 'Připojit',
      de: 'Anschließen',
      en: 'Connect',
      fr: 'Connecter',
    },
    description: {
      ar: 'الوصف',
      cs: 'popis',
      de: 'Beschreibung',
      en: 'description',
      fr: 'description',
    },
    difficulty: {
      difficultyEasy: {
        ar: 'سهل',
        cs: 'Jednoduché',
        de: 'einfach',
        en: 'Easy',
        fr: 'Facile',
      },
      difficultyFemale: {
        ar: 'أنثى',
        cs: 'Žena',
        de: 'Frau',
        en: 'Female',
        fr: 'Femme',
      },
      difficultyHard: {
        ar: 'شديد الصعوبة',
        cs: 'Velice složité',
        de: 'Hoher Schwierigkeitsgrad',
        en: 'High Difficulty',
        fr: 'Très difficile',
      },
      difficultyMale: {
        ar: 'ذكر',
        cs: 'Muž',
        de: 'Mann',
        en: 'Male',
        fr: 'Homme',
      },
      difficultyMedium: {
        ar: 'متوسط الصعوبة',
        cs: 'Středně složité',
        de: 'Mittlerer Schwierigkeitsgrad',
        en: 'Medium Difficulty',
        fr: 'Moyennement difficile',
      },
      difficultyUnknown: {
        ar: 'صعوبة غير معروفة',
        cs: 'Neznámá složitost',
        de: 'Schwierigkeitsgrad nicht bekannt',
        en: 'Unknown Difficulty',
        fr: 'Difficulté inconnue',
      },
      unknown: {
        ar: 'غير معروف',
        cs: 'Neznámé',
        de: 'Unbekannt',
        en: 'Unknown',
        fr: 'Inconnu',
      },
    },
    duplicateJourneyModal: {
      text: {
        ar: 'أختر اسم مناسب للطريق',
        cs: 'Zvolte vhodný název cesty.',
        de: 'Wählen Sie einen passenden Pfadnamen aus.',
        en: 'Change the journey name appropriately.',
        fr: 'Choisir un nom de parcours approprié.',
      },
      title: {
        ar: 'تلميح /المساعدة ',
        cs: 'Hint',
        de: 'Hinweis',
        en: 'Tip',
        fr: 'Hint',
      },
    },
    emotionCurve: {
      bad: {
        ar: 'سيئ',
        cs: 'Špatně',
        de: 'Schlecht',
        en: 'Bad',
        fr: 'Mauvais',
      },
      good: {
        ar: 'جيد',
        cs: 'Dobře',
        de: 'Gut',
        en: 'Good',
        fr: 'Bon',
      },
      nameOfEmotion: {
        ar: 'اسم العاطفة',
        cs: 'Název emoce',
        de: 'Emotionsbezeichnung',
        en: 'Name of Emotion',
        fr: "Nom de l'émotion",
      },
    },
    entireLayer: {
      ar: 'الطبقة بأكملها',
      cs: 'Celá vrstva',
      de: 'Ganzes Layer',
      en: 'Entire layer',
      fr: 'Couche entière',
    },
    journeyDescription: {
      ar: 'وصف الرحلة',
      cs: 'Popis cesty',
      de: 'Pfad-Beschreibung',
      en: 'Journey description',
      fr: 'Description du parcours',
    },
    layer: {
      ar: 'طبقة',
      cs: 'Vrstva',
      de: 'Layer',
      en: 'Layer',
      fr: 'Couche',
    },
    layerAllLevels: {
      ar: '(جميع مستويات الطبقة)',
      cs: '(Všechny úrovně vrstev)',
      de: '(Alle Ebenenebenen)',
      en: '(All layer levels)',
      fr: '(Tous les niveaux de couche)',
    },
    layerLabel: {
      architecture: {
        ar: 'المقدرات',
        cs: 'Architektura',
        de: 'Architektur',
        en: 'Architecture',
        fr: 'Architecture',
      },
      backlog: {
        ar: 'قائمة المهام',
        cs: 'Backlog',
        de: 'Backlog',
        en: 'Backlog',
        fr: 'Backlog',
      },
      backlogExplanation: {
        ar: 'يتم استخدام الأعمال المتراكمة لتنظيم الحلول والتحسينات المحتملة وتحديد أولوياتها وإدارتها.',
        cs: 'Backlog se používá pro organizaci, stanovení priorit a správu potenciálních řešení a vylepšení.',
        de: 'Das Backlog dient zu Organisationszwecken, zur Festlegung von Prioritäten sowie um potenzielle Lösungen und Verbesserungen zu verwalten.',
        en: 'The backlog is utilized for organizing, prioritizing, and managing potential solutions and improvements.',
        fr: 'Le backlog est utilisé pour organiser, hiérarchiser et gérer les solutions et améliorations potentielles.',
      },
      capabilities: {
        ar: 'قدرات فنية',
        cs: 'Technologické kapability',
        de: 'Technologische Kapabilitäten',
        en: 'Technological capabilities',
        fr: 'Capacités technologiques',
      },
      capabilitiesExplanation: {
        ar: 'ارجع إلى الموارد والمهارات والتقنيات التي تمتلكها المنظمة أو تحتاج إلى تطويرها لتقديم تجربة مثالية للعملاء.',
        cs: 'Odkazuje na zdroje, dovednosti a technologie, kterými organizace disponuje nebo potřebuje vyvinout, aby poskytovala optimální zákaznickou zkušenost.',
        de: 'Verweist auf Ressourcen, Fertigkeiten und Technologien, über die das Unternehmen verfügt oder die es zu entwickeln hat, um ein optimales Kundenerlebnis anbieten zu können.',
        en: 'Refer to the resources, skills and technologies the organization has or needs to develop to deliver an optimal customer experience.',
        fr: 'Référez-vous aux ressources, compétences et technologies que l’entreprise a ou doit développer pour offrir une expérience client optimale.',
      },
      channels: {
        ar: 'الأقنية',
        cs: 'Kanály',
        de: 'Kanäle',
        en: 'Channels',
        fr: 'Canaux',
      },
      channelsExplanation: {
        ar: 'الوسائل المختلفة التي تتفاعل من خلالها الشركة مع العملاء، مثل البريد الإلكتروني أو وسائل التواصل الاجتماعي أو الهاتف أو شخصيًا.',
        cs: 'Různé způsoby, kterými organizace komunikuje se zákazníky, jako je e-mail, sociální média, telefon nebo osobní kontakt.',
        de: 'Verschiedene Wege, auf denen das Unternehmen mit seinen Kunden kommuniziert, wie E-Mail, soziale Medien, Telefon oder persönlicher Kontakt.',
        en: 'The various means through which a business interacts with customers, like email, social media, phone, or in-person.',
        fr: 'Les différents moyens par lesquels une entreprise interagit avec les clients, comme les e-mails, les réseaux sociaux, le téléphone ou en personne.',
      },
      connectedCapabilities: {
        ar: 'القدرات المتصلة',
        cs: 'Connected capabilities',
        de: 'Connected Capabilities',
        en: 'Connected capabilities',
        fr: 'Connected capabilities',
      },
      connectedCapabilitiesExplanation: {
        ar: 'يشير إلى منهجية KPMG Connected Enterprise، التي تشمل ثماني قدرات متميزة، تتميز كل منها بقدرات فرعية خاصة بالصناعة.',
        cs: 'Odkazuje na metodiku KPMG Connected Enterprise, která zahrnuje osm různých schopností, z nichž každá obsahuje dílčí schopnosti specifické pro dané odvětví.',
        de: 'Verweist auf die Methodik KPMG Connected Enterprise, die acht verschiedene Fertigkeiten umfasst, die jeweils branchenspezifische Teilfertigkeiten enthalten.',
        en: 'Refers to the KPMG Connected Enterprise methodology, encompassing eight distinct capabilities, each featuring industry-specific sub-capabilities.',
        fr: 'Désigne la méthodologie d’entreprise connectée de KPMG, qui englobe huit capacités distinctes, chacune comportant des sous-capacités spécifiques à l’industrie.',
      },
      cost: {
        ar: 'تمويل',
        cs: 'Finance',
        de: 'Finanzen',
        en: 'Finance',
        fr: 'Finance',
      },
      costExplanation: {
        ar: 'الجانب المالي لتجربة العملاء، بما في ذلك التكلفة التي يتحملها العمل لتوفير التجربة.',
        cs: 'Finanční aspekt zákaznické zkušenosti, včetně nákladů organizace na poskytování zážitku.',
        de: 'Der finanzielle Aspekt des Kundenerlebnisses, einschließlich der Kosten, die dem Unternehmen durch die Bereitstellung des Erlebnisses anfallen.',
        en: 'The financial aspect of customer experience, including the cost to the business of providing the experience.',
        fr: 'L’aspect financier de l’expérience client, y compris le coût pour l’entreprise de fournir l’expérience.',
      },
      designPrinciples: {
        ar: 'مبادئ التصميم',
        cs: 'Designové principy',
        de: 'Gestaltungsprinzipien',
        en: 'Design principles',
        fr: 'Principes de conception',
      },
      designPrinciplesExplanation: {
        ar: 'تستند إلى منهجية "الحمض النووي للعلامة التجارية لتجربة العملاء – هرم AVC" من KPMG  وتضم مجموعة من الإرشادات والمعايير التي تسترشد بها في تصميم رحلة العميل.',
        cs: 'Jsou založeny na metodologii KPMG „CX brand DNA – AVC pyramid“ a zahrnují soubor pokynů a standardů, které informují o designu cesty zákazníka.',
        de: 'Basieren auf der KPMG-Methodologie „CX brand DNA – AVC pyramid“ und umfassen eine Reihe von Anweisungen und Standards, die Auskunft über das Design der Customer Journey geben.',
        en: 'Are grounded in KPMG\'s "CX brand DNA – AVC pyramid" methodology and comprises the set of guidelines and standards that inform the design of the customer journey.',
        fr: 'Sont fondés sur la méthodologie « ADN de la marque CX – pyramide AVC » de KPMG et comprennent l’ensemble des lignes directrices et des normes qui régissent la conception du parcours client.',
      },
      emotionalCurve: {
        ar: 'رحلة عاطفية',
        cs: 'Emoční křivka',
        de: 'Emotionskurve',
        en: 'Emotional Journey',
        fr: 'Parcours émotionnel',
      },
      emotionalExplanation: {
        ar: 'الحالات العاطفية التي يختبرها العميل طوال تفاعله مع العمل، من التوقع إلى الرضا أو الإحباط.',
        cs: 'Emocionální stavy, které zákazník zažívá během své interakce s organizací, od očekávání až po spokojenost nebo frustraci.',
        de: 'Die emotionalen Zustände, die Kunden während ihrer Interaktion mit einem Unternehmen erleben, die von Erwartung bis zu Zufriedenheit oder Frustration reichen.',
        en: 'The emotional states a customer experiences throughout their interaction with a business, from anticipation to satisfaction or frustration.',
        fr: 'Les états émotionnels qu’un client éprouve tout au long de son interaction avec une entreprise, de l’anticipation à la satisfaction ou à la frustration.',
      },
      expectations: {
        ar: 'التوقعات',
        cs: 'Očekávání',
        de: 'Erwartung',
        en: 'Expectations',
        fr: 'Attentes',
      },
      expectationsExplanation: {
        ar: 'ما يتوقعه العملاء من تفاعلاتهم مع الشركة، والتي قد تتراوح من جودة المنتج إلى الاستجابة لخدمة العملاء.',
        cs: 'Co zákazníci očekávají od svých interakcí s organizací, které se mohou pohybovat od kvality produktu až po schopnost reagovat na služby zákazníkům.',
        de: 'Die Erwartungen der Kunden an die Interaktion mit dem Unternehmen, die von der Produktqualität bis hin zu Reaktionsfähigkeit im Bereich Kundendienst reichen können.',
        en: 'What customers expect from their interactions with the business, which can range from product quality to customer service responsiveness.',
        fr: 'Ce que les clients attendent de leurs interactions avec l’entreprise, qui peuvent aller de la qualité du produit à la réactivité du service client.',
      },
      gainPoint: {
        ar: 'نقطة الرضا',
        cs: 'Gain point',
        de: 'Gain Point',
        en: 'Gain point',
        fr: 'Gain point',
      },
      gainPointExplanation: {
        ar: 'الجوانب الإيجابية أو الفوائد التي تعزز تجربة العميل.',
        cs: 'Pozitivní aspekty nebo výhody, které zlepšují zákaznickou zkušenost.',
        de: 'Positive Aspekte oder Vorteile, die das Kundenerlebnis verbessern.',
        en: 'Positive aspects or benefits that enhance the customer’s experience.',
        fr: 'Aspects positifs ou avantages qui améliorent l’expérience du client.',
      },
      inlineProcesses: {
        ar: 'العمليات المضمنة',
        cs: 'Inline Procesy',
        de: 'Inline-Prozesse',
        en: 'Inline Processes',
        fr: 'Processus en ligne',
      },
      inlineProcessesExplanation: {
        ar: 'يقدم رسمًا تخطيطيًا شاملاً لـ BPMN يرسم خريطة لرحلة العميل بأكملها، ويربط جميع نقاط الاتصال.',
        cs: 'Nabízí komplexní diagram BPMN, který mapuje celou cestu zákazníka a spojuje všechny kontaktní body.',
        de: 'Bieten ein umfassendes BPMN-Diagramm, das die gesamte Customer Journey abbildet und alle Berührungspunkte miteinander verbindet.',
        en: 'Offers a comprehensive BPMN diagram that maps the entire customer journey, connecting all touchpoints.',
        fr: 'Offre un diagramme BPMN complet qui cartographie l’ensemble du parcours client, reliant tous les points de contact.',
      },
      layerModalTitle: {
        ar: 'تحديد الطبقة',
        cs: 'Vyberte vrstvu',
        de: 'Layer wählen',
        en: 'Select layer',
        fr: 'Sélectionner la couche',
      },
      metrics: {
        ar: 'المقاييس',
        cs: 'Metriky',
        de: 'Metriken',
        en: 'Metrics',
        fr: 'Métriques',
      },
      metricsExplanation: {
        ar: 'مقاييس قابلة للقياس تستخدم لتقييم جوانب رحلة العميل، مثل درجات رضا العملاء أو متوسط وقت الاستجابة.',
        cs: 'Kvantifikovatelné míry k posouzení aspektů cesty zákazníka, jako je skóre spokojenosti zákazníků nebo průměrná doba odezvy.',
        de: 'Quantifizierbare Messgrößen zur Beurteilung von Aspekten der Customer Journey, wie die Kundenzufriedenheitswerte oder durchschnittliche Antwortzeiten.',
        en: 'Quantifiable measures used to assess aspects of the customer journey, like customer satisfaction scores or average response time.',
        fr: 'Mesures quantifiables utilisées pour évaluer des aspects du parcours client, tels que les scores de satisfaction client ou le temps de réponse moyen.',
      },
      painPoint: {
        ar: 'نقطة الشكوى',
        cs: 'Pain point',
        de: 'Pain Point',
        en: 'Pain point',
        fr: 'Pain point',
      },
      painPointExplanation: {
        ar: 'التحديات أو المشكلات التي يواجهها العملاء في رحلتهم، مما قد يؤدي إلى عدم الرضا.',
        cs: 'Výzvy nebo problémy, kterým zákazníci čelí na své cestě, jenž mohou vést k nespokojenosti.',
        de: 'Herausforderungen oder Probleme, denen Kunden auf ihrer Journey begegnen und die zu Unzufriedenheit führen können.',
        en: 'Challenges or problems customers face in their journey, which can lead to dissatisfaction.',
        fr: 'Les défis ou les problèmes auxquels les clients sont confrontés dans leur parcours, ce qui peut conduire à l’insatisfaction.',
      },
      processEmptyDesc: {
        ar: 'وصف العملية',
        cs: 'Popis procesu',
        de: 'Prozessbeschreibung',
        en: 'Process description',
        fr: 'Description de processus',
      },
      processes: {
        ar: 'العمليات',
        cs: 'Procesy',
        de: 'Prozesse',
        en: 'Processes',
        fr: 'Processus',
      },
      processesExplanation: {
        ar: 'تصور نقاط الاتصال الفردية داخل رحلة العميل بالتفصيل باستخدام مخططات BPMN، مما يسمح بإجراء تحليل عميق لكل تفاعل أو حدث محدد.',
        cs: 'Podrobná vizualizace jednotlivých kontaktních bodů v rámci cesty zákazníka pomocí BPMN digramu, umožňující hloubkovou analýzu každé konkrétní interakce nebo události.',
        de: 'Detaillierte Visualisierung einzelner Berührungspunkte innerhalb der Customer Journey mittels eines BPMN-Diagramms, das eine eingehende Analyse jeder spezifischen Interaktion oder jeden Ereignisses möglich macht.',
        en: 'Visualize individual touchpoints within the customer journey in detail using BPMN diagrams, allowing for a deep analysis of each specific interaction or event.',
        fr: 'Visualiser en détail les points de contact individuels dans le parcours client à l’aide de diagrammes BPMN, qui permettent d’effectuer une analyse approfondie de chaque interaction ou événement spécifique.',
      },
      sixPillars: {
        ar: 'الركائز الستة',
        cs: 'Šest pilířů',
        de: 'Sechs Pfeiler',
        en: 'Six Pillars',
        fr: 'Six piliers',
      },
      sixPillarsExplanation: {
        ar: 'الركائز الست للتميز في الخبرة من KPMG، وهو إطار يحدد العناصر الرئيسية لتجربة العملاء الناجحة.',
        cs: 'KPMG Six Pillars of Experience Excellence, rámec, který nastiňuje klíčové prvky úspěšné zákaznické zkušenosti.',
        de: 'KPMG Six Pillars of Experience Excellence, ein Rahmen, der die Schlüsselelemente eines erfolgreichen Kundenerlebnisses umreißt.',
        en: "KPMG's Six Pillars of Experience Excellence, a framework that outlines the key elements of a successful customer experience.",
        fr: 'Les six piliers de l’excellence de l’expérience de KPMG, un cadre qui décrit les éléments fondamentaux d’une expérience client réussie.',
      },
      solution: {
        ar: 'الحلول',
        cs: 'Řešení',
        de: 'Lösung',
        en: 'Solutions',
        fr: 'Solutions',
      },
      solutionsExplanation: {
        ar: 'يشير إلى الاستراتيجيات والأدوات المستخدمة لتحسين تجربة العملاء، مثل منصة تقنية جديدة أو برنامج لولاء العملاء',
        cs: 'Odkazuje na strategie a nástroje používané ke zlepšení zákaznické zkušenosti, jako je nová technologická platforma nebo zákaznický věrnostní program.',
        de: 'Verweist auf Strategien und Instrumente zur Verbesserung des Kundenerlebnisses, wie eine neue Technologieplattform oder ein Kundenbindungsprogramm.',
        en: 'Refers to the strategies and tools used to improve customer experience, such as a new technology platform or a customer loyalty program.',
        fr: 'Désigne les stratégies et outils utilisés pour améliorer l’expérience client, comme une nouvelle plateforme technologique ou un programme de fidélisation de la clientèle.',
      },
      statistics: {
        ar: 'الإحصاء',
        cs: 'Statistika',
        de: 'Statistik',
        en: 'Statistics',
        fr: 'Statistiques',
      },
      statisticsExplanation: {
        ar: 'يساعد على التقاط وجمع وتقديم أي بيانات إحصائية تتعلق بتفاعلات وسلوكيات العملاء.',
        cs: 'Pomáhá zachytit, shromažďovat a prezentovat jakákoli statistická data související s interakcemi a chováním zákazníků.',
        de: 'Hilft beim Erfassen, Sammeln und Darstellen jeglicher statistischen Daten betreffend Kundeninteraktionen und -verhalten.',
        en: 'Helps to capture, collect and present any statistical data related to customer interactions and behaviors.',
        fr: 'Aide à saisir, collecter et présenter toutes les données statistiques relatives aux interactions et aux comportements des clients.',
      },
      textAndImage: {
        ar: 'النص والصورة',
        cs: 'Text a obrázek',
        de: 'Text und Bild',
        en: 'Text & Image',
        fr: 'Texte et image',
      },
      textAndImageExplanation: {
        ar: 'يساعد تمثيل المحتوى النصي والمرئي على رسم خريطة وتصور الجوانب المختلفة لرحلة العميل لنقل المعلومات بشكل فعال.',
        cs: 'Reprezentace textového a vizuálního obsahu pomáhá zmapovat a vizualizovat různé aspekty cesty zákazníka k efektivnímu předávání informací.',
        de: 'Die Darstellung von textlichen und visuellen Inhalten hilft dabei, verschiedene Aspekte der Customer Journey abzubilden und zu visualisieren, um Informationen effizient weiterzugeben.',
        en: 'The representation of textual and visual content helps to map and visualize various aspects of the customer journey to convey information effectively.',
        fr: 'La représentation du contenu textuel et visuel aide à cartographier et à visualiser divers aspects du parcours client pour transmettre efficacement l’information.',
      },
      userstory: {
        ar: 'قائمة المهام',
        cs: 'Backlog',
        de: 'Backlog',
        en: 'Backlog',
        fr: 'Backlog',
      },
      voiceOfBusiness: {
        ar: 'رأي قطاع الأعمال',
        cs: 'Voice of business',
        de: 'Voice of Business',
        en: 'Voice of business',
        fr: 'Voice of business',
      },
      voiceOfBusinessExplanation: {
        ar: 'يمثل منظور العمل، ويضمن أن لا تركز تجربة العملاء على العملاء فحسب، بل تتماشى أيضًا مع أهداف العمل.',
        cs: 'Reprezentuje perspektivu byznysu, zajišťuje, že zákaznická zkušenost není pouze orientovaná na zákazníka, ale je také v souladu s obchodními cíli.',
        de: 'Steht für die geschäftliche Perspektive und stellt sicher, dass das Kundenerlebnis nicht nur kundenorientiert ist, sondern auch den geschäftlichen Zielen gerecht wird.',
        en: 'Represents the business’s perspective, ensures that the customer experience is not only customer-centric but also aligns with the business goals.',
        fr: 'Représente le point de vue de l’entreprise, garantit que l’expérience client est non seulement centrée sur le client, mais qu’elle s’aligne également sur les objectifs de l’entreprise.',
      },
      whatWeHeard: {
        ar: 'ما سمعناه',
        cs: 'Co o nás říkají',
        de: 'Was sie über uns sagen',
        en: 'What they say about us',
        fr: "Ce qu'ils disent sur nous",
      },
      whatWeHeardExplanation: {
        ar: 'التعليقات أو الأفكار التي جُمعت من العملاء، والتي قد تسترشد بها التحسينات أو التغييرات.',
        cs: 'Zpětná vazba nebo statistiky získané od zákazníků, které mohou informovat o vylepšeních nebo změnách.',
        de: 'Von Kunden gesammeltes Feedback oder Statistiken, denen Verbesserungen oder Änderungen entnommen werden können.',
        en: 'Feedback or insights gathered from customers, which can inform improvements or changes.',
        fr: 'Des commentaires ou des informations recueillis auprès des clients, qui peuvent éclairer les améliorations ou les changements.',
      },
    },
    layers: {
      backlog: {
        showBacklog: {
          ar: 'إظهار التراكم',
          cs: 'Zobrazit backlog',
          de: 'Backlog anzeigen',
          en: 'Show backlog',
          fr: 'Affichage backlog',
        },
      },
      capabilities: {
        description: {
          ar: 'وصف قدرات فنية',
          cs: 'Popis technologických kapabilit',
          de: 'Beschreibung der technologischen Kapabilitäten',
          en: 'Technological capabilities description',
          fr: 'Description des capacités technologiques',
        },
        title: {
          ar: 'قدرات فنية',
          cs: 'Technologické kapability',
          de: 'Technologische Kapabilitäten',
          en: 'Technological capabilities',
          fr: 'Capacités technologiques',
        },
      },
      'capabilities-layer': {
        new: {
          ar: 'عنصر جديد',
          cs: 'Nová položka',
          de: 'Neues Element',
          en: 'New item',
          fr: 'Nouvel élément',
        },
      },
      channels: {
        other: {
          ar: 'غير ذلك',
          cs: 'Jiné',
          de: 'Andere',
          en: 'Other',
          fr: 'Autre',
        },
      },
      connectedCapabilities: {
        showCapabilities: {
          ar: 'إظهار القدرات',
          cs: 'Zobrazit kapability',
          de: 'Kapabilitäten anzeigen',
          en: 'Show capabilities',
          fr: 'Affichage capacités',
        },
      },
      designPrinciples: {
        efforts: {
          ar: 'من المفيد أن تكون معنا',
          cs: 'Být s námi se vám vyplácí',
          de: 'Mit uns zu sein, lohnt sich für Sie.',
          en: 'It pays to be with us',
          fr: 'Cela vaut la peine d’être avec nous',
        },
        eyes: {
          ar: 'نراها من خلال عيونك',
          cs: 'Vidíme to vašima očima',
          de: 'Wir sehen es mit Ihren Augen.',
          en: 'We see it your way',
          fr: 'Nous le voyons à travers vos yeux',
        },
        solution: {
          ar: 'سوف اقوم بالحل من اجلك',
          cs: 'Já to pro vás vyřeším',
          de: 'Ich finde die Lösung für Sie.',
          en: "I'll resolve it for you",
          fr: 'Je m’en occupe pour vous',
        },
      },
      'expectations-layer': {
        new: {
          ar: 'عنصر جديد',
          cs: 'Nová položka',
          de: 'Neues Element',
          en: 'New item',
          fr: 'Nouvel élément',
        },
      },
      gainPoint: {
        description: {
          ar: 'نقطة الرضا الوصف',
          cs: 'Gain point popis',
          de: 'Gain Point Beschreibung',
          en: 'Gain point description',
          fr: 'Gain point description',
        },
        title: {
          ar: 'نقطة الرضا',
          cs: 'Gain point',
          de: 'Gain Point',
          en: 'Gain point',
          fr: 'Gain point',
        },
      },
      'gainpoint-layer': {
        new: {
          ar: 'نقطة رضا جديدة',
          cs: 'Nový gain point',
          de: 'Neuer Gain Point',
          en: 'New gain point',
          fr: 'Nouveau gain point',
        },
      },
      'metrics-layer': {
        new: {
          ar: 'عنصر جديد',
          cs: 'Nová položka',
          de: 'Neues Element',
          en: 'New item',
          fr: 'Nouvel élément',
        },
      },
      pain: {
        description: {
          ar: 'نقطة الشكوى الوصف',
          cs: 'Pain point popis',
          de: 'Pain Point Beschreibung',
          en: 'Pain point description',
          fr: 'Pain point description',
        },
        title: {
          ar: 'نقطة الشكوى',
          cs: 'Pain points',
          de: 'Pain Point',
          en: 'Pain points',
          fr: 'Pain point',
        },
      },
      'painpoint-layer': {
        new: {
          ar: 'نقطة شكوى جديدة',
          cs: 'Nový pain point',
          de: 'Neuer Pain Point',
          en: 'New pain point',
          fr: 'Nouveau pain point',
        },
      },
      phase: {
        newPhase: {
          ar: 'مرحلة أو طور جديد',
          cs: 'Nová fáze',
          de: 'Neue Phase',
          en: 'New phase',
          fr: 'Nouvelle phase',
        },
        title: {
          ar: 'المرحلة أو الطور',
          cs: 'Fáze',
          de: 'Phase',
          en: 'Phase',
          fr: 'Phase',
        },
      },
      processes: {
        description: {
          ar: 'وصف العملية',
          cs: 'Popis procesu',
          de: 'Prozessbeschreibung',
          en: 'Process description',
          fr: 'Description de processus',
        },
        title: {
          ar: 'عملية جديدة',
          cs: 'Nový proces',
          de: 'Neuer Prozess',
          en: 'New process',
          fr: 'Nouveau processus',
        },
      },
      sixPillars: {
        empathy: {
          ar: 'التعاطف',
          cs: 'Empatie',
          de: 'Einfühlungsvermögen',
          en: 'Empathy',
          fr: 'Empathie',
        },
        expectations: {
          ar: 'التوقعات',
          cs: 'Očekávání',
          de: 'Erwartungen',
          en: 'Expectations',
          fr: 'Attentes',
        },
        integrity: {
          ar: 'النزاهة',
          cs: 'Integrita',
          de: 'Integrität',
          en: 'Integrity',
          fr: 'Intégrité',
        },
        personalization: {
          ar: 'إضفاء الطابع الشخصي',
          cs: 'Personalizace',
          de: 'Individualisierung',
          en: 'Personalization',
          fr: 'Personnalisation',
        },
        resolution: {
          ar: 'الإصرار',
          cs: 'Řešení problémů',
          de: 'Problemlösung',
          en: 'Resolution',
          fr: 'Résolution',
        },
        timeAndEffort: {
          ar: 'الوقت والجهد',
          cs: 'Čas a úsilí',
          de: 'Zeit und Aufwand',
          en: 'Time and effort',
          fr: 'Temps et effort',
        },
      },
      statistics: {
        description: {
          ar: 'وصف',
          cs: 'Popis',
          de: 'Bezeichnung',
          en: 'Description',
          fr: 'La description',
        },
        formErrors: {
          requiredNumber: {
            ar: 'هذا الحقل يتطلب رقما',
            cs: 'Toto pole vyžaduje číslo',
            de: 'Dieses Feld erfordert eine Zahl',
            en: 'This field requires a number',
            fr: 'Ce champ nécessite un numéro',
          },
        },
        max: {
          ar: 'الأعلى',
          cs: 'Max',
          de: 'Max',
          en: 'Max',
          fr: 'Max',
        },
        min: {
          ar: 'دقيقة',
          cs: 'Min',
          de: 'Min',
          en: 'Min',
          fr: 'Min',
        },
        unit: {
          ar: 'وحدة',
          cs: 'Jednotka',
          de: 'Einheit',
          en: 'Unit',
          fr: 'Unité',
        },
        value: {
          ar: 'قيمة',
          cs: 'Hodnota',
          de: 'Wert',
          en: 'Value',
          fr: 'Évaluer',
        },
      },
      textAndImage: {
        description: {
          ar: 'النص والصورة الوصف',
          cs: 'Text a obrázek popis',
          de: 'Text und Bild Beschreibung',
          en: 'Text & Image description',
          fr: 'Texte et image Description',
        },
        descriptionWysiwyg: {
          ar: 'وصف الخلية الجديدة',
          cs: 'Popis nové buňky',
          de: 'Beschreibung der neuen Zelle',
          en: 'Description of the new cell',
          fr: 'Description de la nouvelle cellule',
        },
        title: {
          ar: 'النص والصورة',
          cs: 'Text a obrázek',
          de: 'Text und Bild',
          en: 'Text & Image',
          fr: 'Texte et image',
        },
      },
      touchpoint: {
        title: {
          ar: 'نقطة اتصال جديدة',
          cs: 'Nový Touchpoint',
          de: 'Neuer Touchpoint',
          en: 'New Touchpoint',
          fr: 'Nouveau Touchpoint',
        },
      },
    },
    management: {
      layers: {
        ar: 'طبقات',
        cs: 'Vrstvy',
        de: 'Layer',
        en: 'Layers',
        fr: 'Couches',
      },
      structure: {
        ar: 'بنية الرحلة',
        cs: 'Struktura cesty',
        de: 'Pfad-Struktur',
        en: 'Journey Structure',
        fr: 'Structure du parcours',
      },
      title: {
        ar: 'إدارة الرحلة',
        cs: 'Správa cesty',
        de: 'Pfad-Management',
        en: 'Journey Management',
        fr: 'Gestion du parcours',
      },
    },
    new: {
      description: {
        ar: 'اختر قالباً أو ابدأ من البداية',
        cs: 'Vyberte šablonu nebo začněte od začátku',
        de: 'Wählen Sie eine Vorlage aus oder beginnen Sie neu',
        en: 'Choose a template or start from scratch',
        fr: 'Sélectionner un modèle ou partir de zéro',
      },
      modal: {
        atlasCreate: {
          ar: 'أطلس جديد',
          cs: 'Nový atlas',
          de: 'Neuer Atlas',
          en: 'New atlas',
          fr: 'Nouvel atlas',
        },
        continue: {
          fewPersonas: {
            ar: 'الاستمرار مع شخصيات  {{count}}',
            cs: 'Pokračovat s {{count}} personami',
            de: 'Weiter mit {{count}} Personas',
            en: 'Continue with {{count}} personas',
            fr: 'Continuer avec {{count}} personas',
          },
          manyPersonas: {
            ar: 'الاستمرار مع شخصيات  {{count}}',
            cs: 'Pokračovat s {{count}} personami',
            de: 'Weiter mit {{count}} personas',
            en: 'Continue with {{count}} personas',
            fr: 'Continuer avec {{count}} personas',
          },
          noPersona: {
            ar: 'الاستمرار بدون شخصيات',
            cs: 'Pokračovat bez person',
            de: 'Weiter ohne Personas',
            en: 'Continue without personas',
            fr: 'Continuer sans personas',
          },
          onePersona: {
            ar: 'الاستمرار مع شخصية واحدة',
            cs: 'Pokračovat s 1 personou',
            de: 'Weiter mit 1 Persona',
            en: 'Continue with one persona',
            fr: 'Continuer avec 1 persona',
          },
        },
        createButton: {
          ar: 'إنشاء',
          cs: 'Vytvořit',
          de: 'Erstellen',
          en: 'Create',
          fr: 'Créer',
        },
        folderCreate: {
          ar: 'مجلد جديد',
          cs: 'Nová složka',
          de: 'Neuer Ordner',
          en: 'New folder',
          fr: 'Nouveau dossier',
        },
        journeyName: {
          noPersona: {
            ar: ' الاستمرار بدون شخصيات',
            cs: 'Pokračovat bez person',
            de: 'Weiter ohne Personas',
            en: 'Continue without personas',
            fr: 'Continuer sans personas',
          },
          onePersona: {
            ar: 'الاستمرار مع شخصية واحدة',
            cs: 'Pokračovat s 1 personou',
            de: 'Weiter mit 1 Persona',
            en: 'Continue with 1 persona',
            fr: 'Continuer avec 1 persona',
          },
        },
        title: {
          journeyDetails: {
            ar: 'تفاصيل عن الطريق',
            cs: 'Podrobnosti o cestě',
            de: 'Details zum Pfad',
            en: 'Journey details',
            fr: 'Détails du parcours',
          },
          selectPersona: {
            ar: 'إضافة شخصية',
            cs: 'Přidat personu',
            de: 'Persona hinzufügen',
            en: 'Select persona to add',
            fr: 'Ajouter une persona',
          },
        },
        tooltip: {
          bottomHint: {
            ar: 'إذا كنت ترغب في إنشاء أطلس أو مجلد، فما عليك سوى تحديد خيارك في القائمة القابلة للفتح',
            cs: 'Pokud chcete vytvořit nový atlas/složku, stačí v rozevíracím seznamu vybrat možnost vytvořit atlas/složku.',
            de: 'Wenn Sie einen neuen Atlas/Ordner erstellen wollen, wählen Sie im Dropdown-Menü einfach Atlas/Ordner erstellen aus.',
            en: 'If you want to create a new atlas/folder,  select the option to create an atlas/folder in the dropdown menu.',
            fr: 'Si vous souhaitez créer un nouvel atlas/dossier, il vous suffit de sélectionner Créer un atlas/dossier dans la liste déroulante.',
          },
          header: {
            ar: 'نصيحة',
            cs: 'Tip',
            de: 'Tipp',
            en: 'Hint',
            fr: 'Astuce',
          },
          topHint: {
            ar: 'قم بتغيير اسم الطريق بشكل مناسب. أيضاً، يمكنك اختيار الأطلس/ المجلد حيث ترغب إضافة الطريق. ',
            cs: 'Změňte vhodně název cesty. Také si můžete vybrat atlas / složku, do které chcete cestu vložit.',
            de: 'Ändern Sie den Pfadnamen entsprechend. Sie können auch den Atlas/Ordner auswählen, in den Sie den Pfad einfügen möchten.',
            en: 'Change the journey name appropriately. You can also choose the atlas/folder in which you want to insert the journey.',
            fr: 'Modifiez le nom du parcours de façon appropriée. Vous pouvez également sélectionner l’atlas / le dossier dans lequel vous souhaitez placer le parcours.',
          },
        },
      },
      searchExample: {
        ar: 'مثال على البحث',
        cs: 'Hledat ukázku',
        de: 'Muster suchen',
        en: 'Search example',
        fr: 'Exemple de recherche',
      },
      searchTemplate: {
        ar: 'قالب البحث',
        cs: 'Hledat šablonu',
        de: 'Vorlage suchen',
        en: 'Search template',
        fr: 'Modèle de recherche',
      },
      startFromScratch: {
        ar: 'إنشاء من البداية',
        cs: 'Vytvořit od začátku',
        de: 'Neu erstellen',
        en: 'Create from scratch',
        fr: 'Créer à partir de zéro',
      },
      templates: {
        global: {
          ar: 'قوالب عالمية',
          cs: 'Globální šablony',
          de: 'Globale Vorlagen',
          en: 'Global templates',
          fr: 'Modèles globaux',
        },
        local: {
          ar: 'قوالب محلية',
          cs: 'Lokální šablony',
          de: 'Lokale Vorlagen',
          en: 'Local templates',
          fr: 'Modèles locaux',
        },
      },
      title: {
        ar: 'إنشاء طريق جديد',
        cs: 'Vytvořit novou cestu',
        de: 'Neuen Pfad anlegen',
        en: 'Create new journey',
        fr: 'Créer un nouveau parcours',
      },
    },
    newPainpoint: {
      ar: 'نقطة شكوى جديدة',
      cs: 'Nový pain point',
      de: 'Neuer Pain Point',
      en: 'New pain point',
      fr: 'Nouveau pain point',
    },
    newProcess: {
      ar: 'عملية جديدة',
      cs: 'Nový proces',
      de: 'Neuer Prozess',
      en: 'New process',
      fr: 'Nouveau processus',
    },
    newSubPhase: {
      ar: 'طور ثانوي جديدة',
      cs: 'Nová subfáze',
      de: 'Neue Sub-Phase',
      en: 'New subphase',
      fr: 'Nouvelle sous-phase',
    },
    phase: {
      ar: 'الطور',
      cs: 'Fáze',
      de: 'Phase',
      en: 'Phase',
      fr: 'Phase',
    },
    placeBefore: {
      ar: 'ضع قبل',
      cs: 'Umístit před',
      de: 'Davor platzieren',
      en: 'Place in front',
      fr: 'Placer avant',
    },
    placeBehind: {
      ar: 'ضع خلف',
      cs: 'Umístit za',
      de: 'Dahinter platzieren',
      en: 'Place behind',
      fr: 'Placer après',
    },
    placeHere: {
      ar: 'ضع هنا',
      cs: 'Umístit zde',
      de: 'Hier platzieren',
      en: 'Place here',
      fr: 'Placer ici',
    },
    process: {
      title: {
        ar: 'ضع هنا',
        cs: 'Umístit zde',
        de: 'Hier platzieren',
        en: 'Place here',
        fr: 'Placer ici',
      },
    },
    processPreview: {
      ar: 'معاينة العملية',
      cs: 'Náhled procesu',
      de: 'Prozessansicht',
      en: 'Process preview',
      fr: 'Aperçu du processus',
    },
    processToConnect: {
      ar: 'عمليات الاتصال',
      cs: 'Procesy k připojení',
      de: 'Anzuschließende Prozesse',
      en: 'Processes to connect',
      fr: 'Processus à connecter',
    },
    processesNewProcess: {
      ar: 'العمليات المرتبطة بـ{{process}}',
      cs: 'Připojení procesů k {{process}}',
      de: 'Anschluss der Prozesse an {{process}}',
      en: 'Processes linked to {{process}}',
      fr: 'Connexion de procédés à {{process}}',
    },
    sidebar: {
      deleteLayer: {
        ar: 'حذف طبقة',
        cs: 'Smazat vrstvu',
        de: 'Layer löschen',
        en: 'Delete layer',
        fr: 'Supprimer la couche',
      },
      deleteLayerAsk: {
        ar: 'هل ترغب في حذف الطبقة؟',
        cs: 'Smazat vrstvu?',
        de: 'Layer löschen?',
        en: 'Delete the layer?',
        fr: 'Supprimer la couche ?',
      },
      deleteLayerSentence: {
        ar: 'هل أنت متأكد من أنك تريد حذف طبقة <strong>"{{name}}"</strong>',
        cs: 'Opravdu chceš smazat vrstvu <strong>"{{name}}"</strong> ?',
        de: 'Willst du das Layer <strong>"{{name}}"</strong> wirklich löschen?',
        en: 'Do you really want to delete the layer <strong>"{{name}}"</strong> ?',
        fr: 'Voulez-vous vraiment supprimer la couche <strong>"{{name}}"</strong> ?',
      },
      moveDown: {
        ar: 'المرور لأسفل',
        cs: 'Posunout dolu',
        de: 'Nach unten verschieben',
        en: 'Move down',
        fr: 'Déplacer vers le bas',
      },
      moveUp: {
        ar: 'المرور لأعلى',
        cs: 'Posunout nahoru',
        de: 'Nach oben verschieben',
        en: 'Move up',
        fr: 'Déplacer vers le haut',
      },
      personaAdd: {
        ar: 'إضافة شخصية',
        cs: 'Přidat personu',
        de: 'Persona hinzufügen',
        en: 'Add persona',
        fr: 'Ajouter un persona',
      },
      personaRemove: {
        ar: 'إزالة شخصية',
        cs: 'Odebrat personu',
        de: 'Persona entfernen',
        en: 'Remove persona',
        fr: 'Supprimer un persona',
      },
      unlock: {
        ar: 'فتح القفل',
        cs: 'Odemknout',
        de: 'Freischalten',
        en: 'Unlock',
        fr: 'Déverrouiller',
      },
      unlockLastLayer: {
        ar: 'فتح قفل الطبقة الأخيرة',
        cs: 'Odemknout poslední vrstvu',
        de: 'Das letzte Layer freischalten',
        en: 'Unlock the last layer',
        fr: 'Déverrouiller la dernière couche',
      },
      unlockWarning: {
        ar: 'هل فعلاً تريج فتح قفل الطبقة الرئيسية الأخيرة؟ إذا قمت بذلك فقد تفقد السياق بالنسبة للطبقات الأخرى.',
        cs: 'Opravdu chcete odemknout poslední hlavní vrstvu? Pokud to uděláte, můžete ztratit kontext k ostatním vrstvám.',
        de: 'Wollen Sie das letzte Haupt-Layer wirklich freischalten? Wenn Sie das tun, können Sie den Kontext zu anderen Layern verlieren.',
        en: 'Do you really want to unlock the last main layer? If you do that, you may lose the context to other layers.',
        fr: 'Voulez-vous vraiment déverrouiller la dernière couche principale ? Si vous le faites, vous risquez de perdre le contexte des autres couches.',
      },
      visibility: {
        ar: 'الرؤية',
        cs: 'Viditelnost',
        de: 'Sichtbarkeit',
        en: 'Visibility',
        fr: 'Visibilité',
      },
    },
    structure: {
      interactions: {
        ar: 'تفاعلات',
        cs: 'Interakce',
        de: 'Interaktion',
        en: 'Interactions',
        fr: 'Interaction',
      },
      journeysStructure: {
        ar: 'بنية الرحلة',
        cs: 'Struktura cesty',
        de: 'Pfad-Struktur',
        en: 'Journey structure',
        fr: 'Structure du parcours',
      },
      layers: {
        ar: 'طبقات',
        cs: 'Vrstvy',
        de: 'Layer',
        en: 'Layers',
        fr: 'Couches',
      },
    },
    subPhase: {
      ar: 'الطور الفرعي',
      cs: 'Subfáze',
      de: 'Sub-Phase',
      en: 'Subphase',
      fr: 'Sous-phase',
    },
    touchpoint: {
      ar: 'نقطة الاتصال',
      cs: 'Touchpoint',
      de: 'Touchpoint',
      en: 'Touchpoint',
      fr: 'Touchpoint',
    },
    touchpoints: {
      ar: 'نقاط الاتصال',
      cs: 'Touchpointy',
      de: 'Touchpoints',
      en: 'Touchpoints',
      fr: 'Touchpoints',
    },
    whatWeHeard: {
      author: {
        ar: 'المؤلف',
        cs: 'Autor',
        de: 'Autor',
        en: 'Author',
        fr: 'Autor',
      },
      authorDescription: {
        ar: 'وصف المؤلف',
        cs: 'Popis autora',
        de: 'Beschreibung des Autors',
        en: 'Author description',
        fr: "Description de l'auteur",
      },
      comment: {
        ar: 'تعليق',
        cs: 'Komentář',
        de: 'Kommentar',
        en: 'Comment',
        fr: 'Commentaire',
      },
    },
  },
  journeysPreview: {
    created: {
      ar: 'تم الإنشاء',
      cs: 'Vytvořeno',
      de: 'Erstellt',
      en: 'Created',
      fr: 'Créé',
    },
    lastChange: {
      ar: 'آخر تغيير',
      cs: 'Poslední změna',
      de: 'Letzte Änderung',
      en: 'Last change',
      fr: 'Dernière modification',
    },
    showAll: {
      ar: 'عرض الكل',
      cs: 'Zobrazit vše',
      de: 'Alles anzeigen',
      en: 'Show all',
      fr: 'Afficher tout',
    },
  },
  languages: {
    ar: 'عربى',
    cs: 'Čeština',
    de: 'Deutsch',
    en: 'English',
    fr: 'Français',
  },
  layers: {
    capabilities: {
      description: {
        ar: 'وصف قدرات فنية',
        cs: 'Technologické kapability popis',
        de: 'Technologische Kapabilitäten Beschreibung',
        en: 'Technological capabilities description',
        fr: 'Description des capacités technologiques',
      },
      title: {
        ar: 'قدرات فنية',
        cs: 'Technologické kapability',
        de: 'Technologische Kapabilitäten',
        en: 'Technological capabilities',
        fr: 'Capacités technologiques',
      },
    },
    'capabilities-layer': {
      new: {
        ar: 'عنصر جديد',
        cs: 'Nová položka',
        de: 'Neues Element',
        en: 'New item',
        fr: 'Nouvel élément',
      },
    },
    channels: {
      accept: {
        ar: 'قبول',
        cs: 'Akceptovat',
        de: 'Akzeptieren',
        en: 'Accept',
        fr: 'Accepter',
      },
      active: {
        ar: 'نشط',
        cs: 'Aktivní',
        de: 'Aktiv',
        en: 'Active',
        fr: 'Actif',
      },
      addOwnChannel: {
        ar: 'إضافة قناة خاصة',
        cs: 'Přidat vlastní kanál',
        de: 'Eigenen Kanal hinzufügen',
        en: 'Add own channel',
        fr: 'Ajouter son propre canal',
      },
      createOwnChannel: {
        ar: 'إنشاء قناتك الخاصة',
        cs: 'Tvorba vlastního kanálu',
        de: 'Erstellen eines eigenen Kanals',
        en: 'Create your own channel',
        fr: 'Créer votre propre canal',
      },
      custom: {
        ar: 'مخصص',
        cs: 'Vlastní',
        de: 'Eigen',
        en: 'Custom',
        fr: 'Personnalisation',
      },
      customChannel: {
        ar: 'قناة مخصصة',
        cs: 'Vlastní kanál',
        de: 'Eigener Kanal',
        en: 'Custom channel',
        fr: 'Canal personnalisé',
      },
      deny: {
        ar: 'رفض',
        cs: 'Zamítnout',
        de: 'Ablehnen',
        en: 'Deny',
        fr: 'Refuser',
      },
      description: {
        ar: 'الوصف',
        cs: 'Popis',
        de: 'Beschreibung',
        en: 'Description',
        fr: 'Description',
      },
      editChannels: {
        ar: 'تعديل القنوات',
        cs: 'Editovat kanály',
        de: 'Kanäle bearbeiten',
        en: 'Edit channels',
        fr: 'Modifier les canaux',
      },
      global: {
        ar: 'عالمي',
        cs: 'Globální',
        de: 'Global',
        en: 'Global',
        fr: 'Mondial',
      },
      globalChannel: {
        ar: 'قناة عالمية',
        cs: 'Globální kanál',
        de: 'Globaler Kanal',
        en: 'Global channel',
        fr: 'Canal mondial',
      },
      inactive: {
        ar: 'غير مفعّل',
        cs: 'Neaktivní',
        de: 'Inaktiv',
        en: 'Inactive',
        fr: 'Inactif',
      },
      local: {
        ar: 'محلي',
        cs: 'Lokální',
        de: 'Lokal',
        en: 'Local',
        fr: 'Local',
      },
      localChannel: {
        ar: 'القناة المحلية',
        cs: 'Lokální kanál',
        de: 'Lokaler Kanal',
        en: 'Local channel',
        fr: 'Canal local',
      },
      mainInfo: {
        ar: 'المعلومات الرئيسية',
        cs: 'Základní informace',
        de: 'Grundlegende Informationen',
        en: 'Main info',
        fr: 'Information principale',
      },
      manageChannels: {
        ar: 'إدارة قنواتك',
        cs: 'Správa kanálů',
        de: 'Verwalten der Kanäle',
        en: 'Manage your channels',
        fr: 'Gérer vos canaux',
      },
      modified: {
        ar: 'مُعدل',
        cs: 'Upravené',
        de: 'Angepasst',
        en: 'Modified',
        fr: 'Modifié',
      },
      removed: {
        ar: 'تمت الإزالة',
        cs: 'Odstraněné',
        de: 'Entfernt',
        en: 'Removed',
        fr: 'Supprimé',
      },
      searchChannel: {
        ar: 'البحث عن قناة',
        cs: 'Vyhledat kanál',
        de: 'Kanal suchen',
        en: 'Search channel',
        fr: 'Rechercher un canal',
      },
      selectWarning: {
        ar: 'تحديد قناة',
        cs: 'Vyberte kanál',
        de: 'Kanal wählen',
        en: 'Select channel',
        fr: 'Sélectionner le canal',
      },
      selectWarningText: {
        ar: 'يجب عليك تحديد قناة واحدة على الأقل.',
        cs: 'Musíte vybrat alespoň jeden kanál.',
        de: 'Sie müssen mindestens einen Kanal wählen.',
        en: 'You must select at least one channel.',
        fr: 'Vous devez sélectionner au moins un canal.',
      },
      updatedChannels: {
        ar: 'القناة (القنوات) المحدثة',
        cs: 'Aktualizované kanály',
        de: 'Aktualisierte Kanäle',
        en: 'Updated channel(s)',
        fr: 'Canal/canaux mis à jour',
      },
      updatedChannelsAcceptLabel: {
        ar: '<strong>قبول</strong> - ستؤثر التغييرات فوق القنوات على إعدادات الطبقة الحالية.',
        cs: '<strong>Akceptovat</strong> - změny výše uvedených kanálů ovlivní aktuální nastavení vrstvy.',
        de: '<strong>Akzeptieren</strong> - Änderungen an den vorgenannten Kanälen wirken sich auf die aktuelle Einstellung des Layers aus.',
        en: '<strong>Accept</strong> - changes above  channels will affect the current layer settings.',
        fr: '<strong>Accepter</strong> – les modifications apportées aux canaux ci-dessus affecteront les paramètres actuels de la couche.',
      },
      updatedChannelsDenyLabel: {
        ar: '<strong>رفض</strong> - الاحتفاظ بإعدادات الطبقة الحالية (سيتم تخصيص القنوات التي تم تغييرها لاحتياجاتك).',
        cs: '<strong>Zamítnout</strong> - zachovat aktuální nastavení vrstvy (změněné kanály budou přizpůsobeny vašim potřebám).',
        de: '<strong>Ablehnen</strong> - die aktuelle Einstellung des Layers aufrechterhalten (die geänderten Kanäle werden an Ihre Bedürfnisse angepasst).',
        en: '<strong>Deny</strong> - keeping the current layer settings (changed channels will be customized for your needs).',
        fr: '<strong>Refuser</strong> – conserver les paramètres actuels de la couche (les canaux modifiés seront personnalisés en fonction de vos besoins).',
      },
    },
    designPrinciplesV2: {
      title: {
        ar: 'مبدأ التصميم الجديد',
        cs: 'Nový designový princip',
        de: 'Neues Gestaltungsprinzip',
        en: 'New design principle',
        fr: 'Nouveau principe de design',
      },
      tooltip: {
        text: {
          ar: 'يرجى تحميل أيقونة التصميم بمبادئ بحد أقصى 5 ميغابايت في أحد التنسيقات التالية: .svg, .jpeg, .png',
          cs: 'Nahrajte prosím ikonu pro design principy s maximální velikostí 5MB v jednom z následujících formátů: .svg, .jpeg, .png.',
          de: 'Bitte ein mit den Gestaltungsprinzipien verbundenes Symbol mit einer maximalen Größe von 5 MB in einem der folgenden Formate hochladen: .svg, .jpeg, .png.',
          en: 'Please upload an icon associated with the design principles with a maximum size of 5MB in one of the following formats: .svg, .jpeg, .png.',
          fr: "Veuillez télécharger une icône associée au principe de conception d'une taille maximale de 5 Mo dans l'un des formats suivants : .svg, .jpeg, .png.",
        },
        title: {
          ar: 'تنسيق صحيح للملف',
          cs: 'Správný formát souboru',
          de: 'Korrektes Dateiformat',
          en: 'Correct file format',
          fr: 'Format de fichier correct',
        },
      },
    },
    'expectations-layer': {
      new: {
        ar: 'عنصر جديد',
        cs: 'Nová položka',
        de: 'Neues Element',
        en: 'New item',
        fr: 'Nouvel élément',
      },
    },
    gainPoint: {
      description: {
        ar: 'نقطة الرضا الوصف',
        cs: 'Gain point popis',
        de: 'Gain Point Beschreibung',
        en: 'Gain point description',
        fr: 'Gain point Description',
      },
      title: {
        ar: 'نقطة الرضا',
        cs: 'Gain point',
        de: 'Gain Point',
        en: 'Gain point',
        fr: 'Gain point',
      },
    },
    'gainpoint-layer': {
      new: {
        ar: 'نقطة رضا جديدة',
        cs: 'Nový gain point',
        de: 'Neuer Gain Point',
        en: 'New gain point',
        fr: 'Nouveau gain point',
      },
    },
    'metrics-layer': {
      new: {
        ar: 'عنصر جديد',
        cs: 'Nová položka',
        de: 'Neues Element',
        en: 'New item',
        fr: 'Nouvel élément',
      },
    },
    pain: {
      description: {
        ar: 'نقطة الشكوى الوصف',
        cs: 'Pain point popis',
        de: 'Pain Point Beschreibung',
        en: 'Pain point description',
        fr: 'Pain point Description',
      },
      title: {
        ar: 'نقطة الشكوى',
        cs: 'Pain points',
        de: 'Pain Points',
        en: 'Pain points',
        fr: 'Pain point',
      },
    },
    'painpoint-layer': {
      new: {
        ar: 'نقطة شكوى جديدة',
        cs: 'Nový pain point',
        de: 'Neuer Pain Point',
        en: 'New pain point',
        fr: 'Nouveau pain point',
      },
    },
    phase: {
      newPhase: {
        ar: 'طور جديد',
        cs: 'Nová fáze',
        de: 'Neue Phase',
        en: 'New Phase',
        fr: 'Nouvelle phase',
      },
      title: {
        ar: 'الطور',
        cs: 'Fáze',
        de: 'Phase',
        en: 'Phase',
        fr: 'Phase',
      },
    },
    processes: {
      description: {
        ar: 'وصف العملية',
        cs: 'Popis procesu',
        de: 'Prozessbeschreibung',
        en: 'Process description',
        fr: 'Description de processus',
      },
      title: {
        ar: 'عملية جديدة',
        cs: 'Nový proces',
        de: 'Neuer Prozess',
        en: 'New process',
        fr: 'Nouveau processus',
      },
    },
    subPhase: {
      title: {
        ar: 'طور ثانوي جديدة',
        cs: 'Nová subfáze',
        de: 'Neue Sub-Phase',
        en: 'New Subphase',
        fr: 'Nouvelle sous-phase',
      },
    },
    textAndImage: {
      description: {
        ar: 'النص والصورة الوصف',
        cs: 'Text a obrázek popis',
        de: 'Text und Bild Beschreibung',
        en: 'Text & image description',
        fr: 'Texte et image Description',
      },
      descriptionWysiwyg: {
        ar: 'وصف الخلية الجديدة',
        cs: 'Popis nové buňky',
        de: 'Beschreibung der neuen Zelle',
        en: 'Description of the new cell',
        fr: 'Description de la nouvelle cellule',
      },
      newCell: {
        ar: 'خلية جديدة',
        cs: 'Nová buňka',
        de: 'Neue Zelle',
        en: 'New cell',
        fr: 'Nouvelle cellule',
      },
      title: {
        ar: 'النص والصورة',
        cs: 'Text a obrázek',
        de: 'Text und Bild',
        en: 'Text & image',
        fr: 'Texte et image',
      },
    },
    touchpoint: {
      title: {
        ar: 'نقطة اتصال جديدة',
        cs: 'Nový Touchpoint',
        de: 'Neuer Touchpoint',
        en: 'New Touchpoint',
        fr: 'Nouveau Touchpoint',
      },
    },
    'voice-of-business-layer': {
      new: {
        ar: 'عنصر جديد',
        cs: 'Nová položka',
        de: 'Neues Element',
        en: 'New item',
        fr: 'Nouvel élément',
      },
    },
  },
  listOfJourneys: {
    allJourneys: {
      ar: 'كافة الطرق',
      cs: 'Přehled cest',
      de: 'Pfade im Überblick',
      en: 'All Journeys',
      fr: 'Tous les parcours',
    },
    archivedJourneys: {
      ar: 'الرحلات المحفتفظ بها في الأرشيف',
      cs: 'Archivované cesty',
      de: 'Archivierte Pfade',
      en: 'Archived journeys',
      fr: 'Parcours archivés',
    },
    createAnewJourney: {
      ar: 'إنشاء رحلة جديدة',
      cs: 'Vytvořit novou cestu',
      de: 'Neuen Pfad anlegen',
      en: 'Create a new journey',
      fr: 'Créer un nouveau parcours',
    },
    searchJourney: {
      ar: 'البحث عن مسار',
      cs: 'Vyhledat cestu',
      de: 'Pfad suchen',
      en: 'Search journey',
      fr: 'Rechercher un parcours',
    },
    showingSortBy: {
      ar: 'تظهر الطرق حسب',
      cs: 'Zobrazují se cesty podle',
      de: 'Pfade werden angezeigt nach',
      en: 'Showing journeys by',
      fr: 'Afficher les parcours par',
    },
    title: {
      ar: 'لائحة الرحلات',
      cs: 'Seznam cest',
      de: 'Pfad-Liste',
      en: 'List of Journeys',
      fr: 'Liste de parcours',
    },
  },
  loadingApp: {
    ar: 'جارٍ تحميل التطبيق...',
    cs: 'Načítání aplikace...',
    de: 'Anwendung wird geladen...',
    en: 'Loading application...',
    fr: 'L’application se charge…',
  },
  login: {
    documentTitle: {
      ar: 'KPMG - الاكتشاف - تسجيل الدخول',
      cs: 'KPMG - Discovery - Přihlásit se',
      de: 'KPMG - Discovery – Anmelden',
      en: 'KPMG - Discovery - Log in',
      fr: 'KPMG – Discovery – Se connecter',
    },
    logIn: {
      ar: 'تسجيل الدخول',
      cs: 'Přihlásit se',
      de: 'Anmelden',
      en: 'Log in',
      fr: 'Se connecter',
    },
    title: {
      ar: 'أولا تحتاج إلى تسجيل الدخول',
      cs: 'Nejprve je potřeba se přihlásit',
      de: 'Zunächst müssen Sie sich anmelden',
      en: 'Please log in first',
      fr: 'Il faut d’abord se connecter',
    },
  },
  modals: {
    loginErrorModal: {
      content: {
        ar: 'تمت مقاطعة عملية تسجيل الدخول وسيكون من الضروري تسجيل الدخول مرة أخرى.',
        cs: 'Proces přihlášení byl přerušen a bude nutné se znovu přihlásit.',
        de: 'Der Anmeldevorgang wurde unterbrochen und Sie werden sich erneut anmelden müssen.',
        en: 'The login process has been interrupted and it will be necessary to log in again.',
        fr: 'Le processus de connexion a été interrompu et il sera nécessaire de se connecter à nouveau.',
      },
      title: {
        ar: 'تمت مقاطعة عملية تسجيل الدخول',
        cs: 'Proces přihlašování byl přerušen',
        de: 'Der Anmeldevorgang wurde unterbrochen',
        en: 'Login process interrupted',
        fr: 'Processus de connexion interrompu',
      },
    },
    networkErrorModal: {
      content: {
        ar: 'يبدو أن الخادم أو اتصال الشبكة الخاص بك غير متاح مؤقتًا. الرجاء معاودة المحاولة في وقت لاحق',
        cs: 'Zdá se, že server nebo vaše síťové připojení je dočasně nedostupné. Zkuste svůj požadavek zopakovat později.',
        de: 'Der Server oder Ihre Netzwerkverbindung scheint vorübergehend nicht verfügbar zu sein. Versuchen Sie, Ihre Anfrage später zu wiederholen.',
        en: 'It seems the server or your network connection is temporarily unavailable. Try to repeat your request later.',
        fr: 'Le serveur ou votre connexion réseau semble être actuellement indisponible. Essayez de répéter votre demande plus tard.',
      },
      title: {
        ar: 'تم اكتشاف خطأ في الشبكة',
        cs: 'Byla zjištěna chyba sítě',
        de: 'Es wurde ein Netzwerkfehler festgestellt.',
        en: 'Network error detected',
        fr: 'Une erreur de réseau s’est produite',
      },
    },
    tenantDeactivatedModal: {
      title: {
        ar: 'المستأجر غير النشط',
        cs: 'Tenant smazán',
        de: 'Tenant gelöscht',
        en: 'Deactivated tenant',
        fr: 'Tenant désactivé',
      },
    },
    unauthenticatedModal: {
      content: {
        ar: 'يرجى تسجيل الدخول مرة أخرى لمواصلة استخدام التطبيق',
        cs: 'Pro pokračování v používání aplikace se prosím znovu přihlaste.',
        de: 'Bitte melden Sie sich erneut an, um die App weiter zu nutzen.',
        en: 'Please log in again to continue using the app.',
        fr: 'Veuillez vous reconnecter pour continuer à utiliser l’application.',
      },
      title: {
        ar: 'لقد انتهت جلستك',
        cs: 'Vaše relace vypršela',
        de: 'Ihre Sitzung ist abgelaufen',
        en: 'Your session has expired',
        fr: 'Votre session a expiré',
      },
    },
    unauthorizedModal: {
      content: {
        ar: 'ليس لديك الأذونات الكافية',
        cs: 'Nemáte dostatečné oprávnění',
        de: 'Ihre Berechtigung ist nicht ausreichend.',
        en: "You don't have sufficient permission.",
        fr: 'Vous ne disposez pas de l’autorisation suffisante',
      },
      title: {
        ar: 'غير مصرح',
        cs: 'Neoprávněný',
        de: 'Nicht berechtigt',
        en: 'Unauthorized',
        fr: 'Non autorisé',
      },
    },
    userHasNoPermissionToAnyTenantModal: {
      logoutButton: {
        ar: 'تسجيل خروج',
        cs: 'Odhlásit',
        de: 'Abmelden',
        en: 'Log out',
        fr: 'Se déconnecter',
      },
      reloadButton: {
        ar: 'أعد تحميل التطبيق',
        cs: 'Přenačíst aplikaci',
        de: 'Anwendung erneut laden',
        en: 'Reload application',
        fr: 'Recharger l’application',
      },
      title: {
        ar: 'حدث خطأ غير معروف',
        cs: 'Došlo k neznámé chybě',
        de: 'Ein unbekannter Fehler ist aufgetreten',
        en: 'Unknown error occurred',
        fr: 'Une erreur inconnue s’est produite',
      },
    },
  },
  modules: {
    moveRestoreModal: {
      folder: {
        info: {
          ar: 'تحويل إلى <strong>"{{target}}"</strong>',
          cs: 'Přesun na <strong>"{{target}}"</strong>',
          de: 'Wechseln zu <strong>"{{target}}"</strong>',
          en: 'Move to <strong>"{{target}}"</strong>',
          fr: 'Déplacer vers <strong>"{{target}}"</strong>',
        },
        text: {
          ar: 'حدد الأطلس حيث تريد نقل مجلد  <strong>"{{name}}"</strong> إليه',
          cs: 'Vyberte atlas, do kterého chcete přesunout složku <strong>"{{name}}"</strong>.',
          de: 'Wählen Sie einen Atlas aus, in den Sie den Ordner <strong>"{{name}}"</strong> verschieben möchten.',
          en: 'Choose an atlas, where you want to move the folder <strong>"{{name}}"</strong> to.',
          fr: 'Choisissez un atlas vers lequel vous souhaitez déplacer le dossier <strong>"{{name}}"</strong>.',
        },
        title: {
          ar: 'نقل المجلد',
          cs: 'Přesunout složku',
          de: 'Den Ordner verschieben',
          en: 'Move the Folder',
          fr: 'Déplacer le dossier',
        },
      },
      journey: {
        clearFolderSelect: {
          ar: 'إلغاء اختيار المجلد',
          cs: 'Zrušit výběr složky',
          de: 'Ordnerauswahl aufheben',
          en: 'Clear folder selection',
          fr: 'Effacer la sélection du dossier',
        },
        info: {
          ar: 'انقل إلى  <strong>"{{target}}"</strong>',
          cs: 'Přesunout do <strong>"{{target}}"</strong>',
          de: 'Nach <strong>"{{target}}"</strong> verschieben',
          en: 'Move to <strong>"{{target}}"</strong>',
          fr: 'Déplacer vers <strong>"{{target}}"</strong>',
        },
        text: {
          ar: 'حدد الأطلس او المجلد حيث تريد نقل طريق <strong>"{{name}}"</strong>',
          cs: 'Vyberte atlas / složku, kam chcete přesunout cestu <strong>"{{name}}"</strong>.',
          de: 'Wählen Sie einen Atlas/Ordner aus, in den Sie den Pfad <strong>"{{name}}"</strong> verschieben möchten.',
          en: 'Choose an atlas / folder, where you want to move the journey <strong>"{{name}}"</strong> to.',
          fr: 'Choisissez un atlas/dossier vers lequel vous souhaitez déplacer le parcours <strong>"{{name}}"</strong>.',
        },
        title: {
          ar: 'انقل الطريق',
          cs: 'Přesunout cestu',
          de: 'Den Pfad verschieben',
          en: 'Move the Journey',
          fr: 'Déplacer le parcours',
        },
      },
      restore: {
        info: {
          ar: 'عملية الإستعادة الى <strong>"{{target}}"</strong>',
          cs: 'Obnovit do <strong>"{{target}}"</strong>',
          de: 'In <strong>"{{target}}"</strong> wiederherstellen',
          en: 'Restore to <strong>"{{target}}"</strong>',
          fr: 'Restaurer vers <strong>"{{target}}"</strong>',
        },
        text: {
          ar: 'حدد الأطلس أو المجلد التي تريد استعادة <strong>"{{name}}"</strong> إليه.',
          cs: 'Vyberte atlas / složku, do které chcete obnovit cestu <strong>"{{name}}"</strong>.',
          de: 'Wählen Sie einen Atlas / Ordner aus, in dem Sie den Pfad <strong>"{{name}}"</strong> wiederherstellen möchten.',
          en: 'Choose an atlas / folder, where you want to restore the journey <strong>"{{name}}"</strong> to.',
          fr: 'Choisissez un atlas/dossier dans lequel vous souhaitez restaurer le parcours <strong>"{{name}}"</strong>.',
        },
        title: {
          ar: 'استعادة الطريق',
          cs: 'Obnovit cestu',
          de: 'Den Pfad wiederherstellen',
          en: 'Restore the Journey',
          fr: 'Restaurer le parcours',
        },
      },
    },
    uploadImage: {
      addPhoto: {
        ar: 'إضافة صورة فوتوغرافية',
        cs: 'Přidat fotografii',
        de: 'Foto hinzufügen',
        en: 'Add photo',
        fr: 'Ajouter une photo',
      },
      changeImage: {
        ar: 'اختيار واحدة جديدة',
        cs: 'Vyberte novou',
        de: 'Neu auswählen',
        en: 'Choose new',
        fr: 'Choisissez une nouvelle photo',
      },
      info: {
        ar: 'اختر جزء من الصورة تريد عرضه',
        cs: 'Vyberte část fotografie, kterou chcete zobrazit.',
        de: 'Wählen Sie den Teil des Fotos aus, den Sie zeigen möchten.',
        en: 'Please select the section of the photo you want to show.',
        fr: 'Sélectionnez la partie de la photo que vous souhaitez afficher.',
      },
      title: {
        ar: 'قص الصورة وتحميلها',
        cs: 'Ořízněte obrázek a nahrajte jej',
        de: 'Bild zuschneiden und hochladen',
        en: 'Crop image and upload',
        fr: "Recadrer l'image et la télécharger",
      },
      upload: {
        ar: 'القص والتحميل',
        cs: 'Oříznutí a nahrání',
        de: 'Zuschneiden und hochladen',
        en: 'Crop and upload',
        fr: 'Recadrer et télécharger',
      },
    },
  },
  newTenantModal: {
    chooseSector: {
      ar: 'قطاع المستأجرين',
      cs: 'Vyberte sektor',
      de: 'Wählen Sie den Sektor',
      en: 'Choose Sector',
      fr: 'Choisissez un secteur',
    },
    createNewTenant: {
      ar: 'إنشاء مستأجر جديد',
      cs: 'Založení nového tenanta',
      de: 'Tenant neu anlegen',
      en: 'Create new tenant',
      fr: "Création d'un nouveau locataire",
    },
    createTenantError: {
      title: {
        ar: 'فشل في إنشاء مستأجر جديد',
        cs: 'Vytvoření nového tenanta se nezdařilo',
        de: 'Neuer Tenant konnte nicht angelegt werden.',
        en: 'Creating a new tenant failed',
        fr: 'La création du nouveau locataire a échoué',
      },
    },
    createTenantSuccess: {
      content: {
        ar: 'الإنشاء عملية غير متزامنة وقد تستغرق بعض الوقت',
        cs: 'Vytváření je asynchronní proces a může chvíli trvat',
        de: 'Das Erstellen ist ein asynchroner Prozess und kann etwas dauern',
        en: 'Creating is an asynchronous process and can take a while.',
        fr: 'La création est un processus asynchrone et peut prendre du temps',
      },
      title: {
        ar: 'لقد اكتمل إنشاء المستأجر الجديد تقريبًا',
        cs: 'Vytvoření nového tenantu je téměř hotové',
        de: 'Das Erstellen des neuen Tenants ist fast abgeschlossen',
        en: 'Creating a new tenant almost complete',
        fr: 'La création du nouveau locataire est presque terminée',
      },
    },
    loadingNewTenantAdded: {
      ar: 'البدء في إعداد مستأجر جديد',
      cs: 'Probíhá spuštění přípravy nového tenanta',
      de: 'Die Vorbereitung eines neuen Tenants läuft',
      en: 'Starting the preparation of a new tenant',
      fr: "Commencer la préparation d'un nouveau locataire",
    },
    newTenant: {
      ar: 'مستأجر جديد',
      cs: 'Nový tenant',
      de: 'Neuer Tenant',
      en: 'New Tenant',
      fr: 'Nouveau tenant',
    },
    noTemplate: {
      ar: 'لا يوجد نموذج',
      cs: 'Žádná šablona',
      de: 'Keine Vorlage',
      en: 'No template',
      fr: 'Pas de modèle',
    },
    tenantName: {
      ar: 'اسم المستأجر',
      cs: 'Název tenanta',
      de: 'Tenant-Name',
      en: 'Tenant name',
      fr: 'Nom du locataire',
    },
    tenantSector: {
      ar: 'قطاع المستأجرين',
      cs: 'Sektor tenanta',
      de: 'Tenant-Sektor',
      en: 'Tenant sector',
      fr: 'Secteur locataire',
    },
    tenantTemplate: {
      ar: 'قالب المستأجر ',
      cs: 'Šablona tenanta',
      de: 'Tenant-Vorlage',
      en: 'Tenant template',
      fr: 'Modèle du locataire',
    },
  },
  notifications: {
    notificationEndDuplicated: {
      ar: 'مكرر.',
      cs: 'duplikována.',
      de: 'dupliziert.',
      en: 'duplicated.',
      fr: 'dupliqué.',
    },
    notificationEndMoved: {
      ar: 'منقول.',
      cs: 'přesunuta.',
      de: 'verschoben.',
      en: 'moved.',
      fr: 'déplacé.',
    },
    notificationEndRestored: {
      ar: 'تمت الاستعادة',
      cs: 'obnovena.',
      de: 'erneuert.',
      en: 'restored.',
      fr: 'restauré.',
    },
    notificationFolderSuccessfully: {
      ar: 'تم ... المجلد بنجاح ',
      cs: 'Složka byla úspěšně',
      de: 'Der Ordner wurde erfolgreich',
      en: 'The folder was successfully',
      fr: 'Le dossier a bien été',
    },
    notificationJourneySuccessfully: {
      ar: 'تمت ... الرحلة بنجاح ',
      cs: 'Cesta byla úspěšně',
      de: 'Der Pfad wurde erfolgreich',
      en: 'The journey was successfully',
      fr: 'Le parcours a bien été',
    },
    notificationJourneySuccessfullyCreated: {
      ar: 'تم إنشاء الرحلة بنجاح.',
      cs: 'Cesta byla úspěšně vytvořena.',
      de: 'Der Pfad wurde erfolgreich angelegt.',
      en: 'The journey was successfully created.',
      fr: 'Le parcours a bien été créé.',
    },
    notificationTenantSuccessfully: {
      ar: ' تم بنجاح ... المستأجر',
      cs: 'Tenant byl úspěšně',
      de: 'Der Tenant wurde erfolgreich',
      en: 'The tenant was successfully',
      fr: 'Le tenant a bien été',
    },
  },
  persona: {
    addNewItem: {
      ar: 'إضافة جديد',
      cs: 'Přidat nový',
      de: 'Neu hinzufügen',
      en: 'Add new',
      fr: 'Ajouter nouveau',
    },
    addNewPersona: {
      ar: 'إنشاء شخصية جديدة',
      cs: 'Vytvořit novou personu',
      de: 'Eine neue Persona erstellen',
      en: 'Create a new persona',
      fr: 'Créer un nouveau persona',
    },
    addPersona: {
      ar: 'إضافة  شخصية',
      cs: 'Přidat personu',
      de: 'Persona hinzufügen',
      en: 'Add persona',
      fr: 'Ajouter un persona',
    },
    connectedJourneys: {
      accessibleJourneys: {
        ar: 'طرق متاحة',
        cs: 'Dostupné cesty',
        de: 'Verfügbare Pfade',
        en: 'Accessible journeys',
        fr: 'Parcours disponibles',
      },
      inaccessibleJourneys: {
        ar: 'طرح غير متاحة',
        cs: 'Nedostupné cesty',
        de: 'Nicht verfügbare Pfade',
        en: 'Inaccessible journeys',
        fr: 'Parcours inaccessibles',
      },
      totalJourneys: {
        ar: 'الإجمالي: {{count}} عدد الطرق',
        cs: 'Celkem: {{count}} cest',
        de: 'Insgesamt: {{count}} Pfade',
        en: 'Total: {{count}} journeys',
        fr: 'Total : {{count}} parcours',
      },
    },
    connectedJourneysAmount: {
      ar: 'عدد {{count}} الطرق غير المربوطة',
      cs: '{{count}} navázaných cest',
      de: '{{count}} Anschlusspfade',
      en: '{{count}} connected journeys',
      fr: '{{count}} parcours établis',
    },
    content: {
      ar: 'المحتوى',
      cs: 'Obsah',
      de: 'Inhalt',
      en: 'Contents',
      fr: 'Contenu',
    },
    createPersona: {
      placeholder: {
        ar: 'ادخل الاسم',
        cs: 'Zadejte jméno',
        de: 'Den Namen eingeben',
        en: 'Enter name',
        fr: 'Saisir le nom',
      },
      title: {
        ar: 'إنشاء شخصية جديدة',
        cs: 'Vytvořit novou personu',
        de: 'Eine neue Persona erstellen',
        en: 'Create a new persona',
        fr: 'Créer un nouveau persona',
      },
    },
    deletePersona: {
      ar: 'حذف شخصية',
      cs: 'Smazat personu',
      de: 'Persona löschen',
      en: 'Delete persona',
      fr: 'Supprimer un persona',
    },
    edit: {
      ar: 'تعديل الملف الشخصي',
      cs: 'Upravit profil',
      de: 'Profil bearbeiten',
      en: 'Edit profile',
      fr: 'Modifier le profil',
    },
    enterAge: {
      ar: 'ادخل العمر',
      cs: 'Zadejte věk',
      de: 'Alter eingeben',
      en: 'Enter age',
      fr: "Saisir l'âge",
    },
    enterEducation: {
      ar: 'ادخل أعلى درجة تعليم لديها',
      cs: 'Zadejte vzdělání',
      de: 'Bildung eingeben',
      en: 'Enter education',
      fr: "Saisir l'éducation",
    },
    enterGender: {
      ar: 'ادخل الجنس',
      cs: 'Zadejte pohlaví',
      de: 'Geschlecht eingeben',
      en: 'Enter gender',
      fr: 'Saisir le sexe',
    },
    enterMaterialStatus: {
      ar: 'ادخل الحالة الاجتماعية',
      cs: 'Zadejte rodinný stav',
      de: 'Familienstand eingeben',
      en: 'Enter marital status',
      fr: "Saisir l'état civil",
    },
    enterOccupation: {
      ar: 'ادخل المهنة',
      cs: 'Zadejte povolání',
      de: 'Beruf eingeben',
      en: 'Enter occupation',
      fr: 'Saisir la profession',
    },
    heading: {
      ar: 'العنوان',
      cs: 'Nadpis',
      de: 'Überschrift',
      en: 'Heading',
      fr: 'Titre',
    },
    mainInformation: {
      description: {
        ar: 'وصف الشخصية',
        cs: 'Popis persony',
        de: 'Beschreibung der Persona',
        en: 'Persona description',
        fr: 'Description du persona',
      },
      name: {
        ar: 'اسم الشخصية',
        cs: 'Jméno persony',
        de: 'Name der Person',
        en: 'Persona name',
        fr: 'Nom du persona',
      },
      photo: {
        ar: 'صورة الشخصية',
        cs: 'Fotografie persony',
        de: 'Foto der Persona',
        en: 'Persona photo',
        fr: 'Photo du persona',
      },
    },
    predefinedForJourney: {
      ar: 'محدد مسبقاً للرحلة',
      cs: 'Předdefinováno pro cestu',
      de: 'Für den Pfad vordefiniert',
      en: 'Predefined for journey',
      fr: 'Prédéfini pour le parcours',
    },
    profile: {
      ar: 'الملف الشخصي للشخصية',
      cs: 'Profil persony',
      de: 'Profil der Persona',
      en: 'Persona Profile',
      fr: 'Profil du persona',
    },
    save: {
      ar: 'حفظ الملف الشخصي',
      cs: 'Uložit profil',
      de: 'Profil speichern',
      en: 'Save profile',
      fr: 'Enregistrer le profil',
    },
    searchNotFound: {
      ar: 'الشخصية التي تبحث عنها ليست موجودة في مكان الاستئجار هذا.',
      cs: 'Hledaná persona není součástí daného tenantu.',
      de: 'Die gesuchte Persona ist nicht Teil des angegebenen Tenants.',
      en: 'The searched persona is not part of the given tenant.',
      fr: 'Le persona recherché ne fait pas partie du tenant donné.',
    },
    searchPlaceholder: {
      ar: 'البحث عن شخصية',
      cs: 'Vyhledat personu',
      de: 'Persona suchen',
      en: 'Search persona',
      fr: 'Rechercher une persona',
    },
    sections: {
      behavior: {
        ar: 'سلوك',
        cs: 'Chování',
        de: 'Verhalten',
        en: 'Behavior',
        fr: 'Comportement',
      },
      connectedJourneys: {
        ar: 'الطرق المنشاة المربوطة',
        cs: 'Navázané cesty',
        de: 'Anschlusspfade',
        en: 'Connected journeys',
        fr: 'Parcours établis',
      },
      detail: {
        ar: 'تفاصيل حول الشخصية',
        cs: 'Podrobnosti o personě',
        de: 'Details zur Persona',
        en: 'Persona details',
        fr: 'Détails du persona',
      },
      mainInformation: {
        ar: 'المعلومات الأساسية',
        cs: 'Hlavní informace',
        de: 'Wesentliche Informationen',
        en: 'Main information',
        fr: 'Informations principales',
      },
      preferences: {
        ar: 'التفضيلات',
        cs: 'Preference',
        de: 'Präferenzen',
        en: 'Preferences',
        fr: 'Préférences',
      },
    },
    selectPersonaToAdd: {
      description: {
        ar: 'حدد الشخصيات التي تريج إضافتها إلى الطريق.',
        cs: 'Vyberte persony, které chcete přidat do cesty.',
        de: 'Bitte wählen Sie die Personas aus, die Sie dem Pfad hinzufügen möchten.',
        en: 'Please select the persona(s) you want to add to the journey.',
        fr: 'Veuillez sélectionner le(s) persona(s) que vous souhaitez ajouter au parcours.',
      },
      isInTenant: {
        description: {
          ar: 'يمكنك إضافة شخصية واحدة فقط إلى كل طبقة.',
          cs: 'Do každé vrstvy můžete přidat pouze jednu personu.',
          de: 'Sie können nur eine Persona pro Layer hinzufügen.',
          en: 'You can only add one persona per layer.',
          fr: "Vous ne pouvez ajouter qu'un seul persona par couche.",
        },
      },
      title: {
        ar: 'اختيار شخصية للإضافة',
        cs: 'Vyber personu pro přidání',
        de: 'Auswahl der hinzufügenden Persona',
        en: 'Select persona to add',
        fr: 'Choix de personas à ajouter',
      },
    },
    selectedPersona: {
      ar: 'الشخصية المختارة {{count}}',
      cs: 'Vybraná {{count}} persona',
      de: 'Ausgewählte {{count}} Persona',
      en: 'Selected {{count}} persona',
      fr: 'Persona sélectionné {{count}}',
    },
    selectedPersonas: {
      ar: 'الشخصيات المختارة {{count}}',
      cs: 'Vybrané {{count}} persony',
      de: 'Ausgewählte {{count}} Personas',
      en: 'Selected {{count}} personas',
      fr: 'Personas sélectionnés {{count}}',
    },
    tenantPersonas: {
      ar: 'شخصيات المستأجر',
      cs: 'Persony tenanta',
      de: 'Personas des Tenants',
      en: 'Tenant personas',
      fr: 'Personas tenants',
    },
  },
  personas: {
    allPersonas: {
      ar: 'جميع الشخصيات',
      cs: 'Přehled person',
      de: 'Alle Personas',
      en: 'All Personas',
      fr: 'Tous les personas',
    },
    basicInformation: {
      ar: 'معلومات أساسية',
      cs: 'Základní informace',
      de: 'Grundlegende Informationen',
      en: 'Basic Information',
      fr: 'Informations de base',
    },
    enterDescription: {
      ar: 'أدخل الوصف',
      cs: 'Zadejte popis',
      de: 'Beschreibung eingeben',
      en: 'Enter description',
      fr: 'Saisir la description',
    },
    newPersona: {
      ar: 'إضافة شخصية جديدة',
      cs: 'Přidat novou personu',
      de: 'Neue Persona hinzufügen',
      en: 'Add new Persona',
      fr: 'Ajouter un nouveau persona',
    },
    personaDescription: {
      ar: 'وصف الشخصية',
      cs: 'Popis persony',
      de: 'Beschreibung der Persona',
      en: 'Persona Description',
      fr: 'Description du persona',
    },
  },
  personasPreview: {
    newPersona: {
      ar: 'شخصية جديدة',
      cs: 'Nová Persona',
      de: 'Neue Persona',
      en: 'New Persona',
      fr: 'Nouveau persona',
    },
    personaDetail: {
      ar: 'تفاصيل الشخصية',
      cs: 'Detail persony',
      de: 'Detail der Persona',
      en: 'Persona detail',
      fr: 'Détail persona',
    },
    personas: {
      ar: 'شخصيات',
      cs: 'Persony',
      de: 'Personas',
      en: 'Personas',
      fr: 'Personas',
    },
    showAll: {
      ar: 'عرض الكل',
      cs: 'Zobrazit vše',
      de: 'Alles anzeigen',
      en: 'Show all',
      fr: 'Afficher tout',
    },
  },
  publishModal: {
    cancel: {
      ar: 'إلغاء',
      cs: 'Zrušit',
      de: 'Aufheben',
      en: 'Cancel',
      fr: 'Annuler',
    },
    content: {
      ar: 'هل تريد حقًا نشر القدرة الفرعية "{{name}}"؟ هذه الخطوة لا رجوع فيها!',
      cs: 'Opravdu si přejete publikovat subkapabilitu "{{name}}"? Tento krok je NEVRATNÝ!',
      de: 'Wollen Sie die Sub-Kapabilität "{{name}}" wirklich veröffentlichen? Dieser Schritt ist IRREVERSIBEL!',
      en: 'Do you really wish to publish this sub-capability "{{name}}"? This step is IRREVERSIBLE!',
      fr: 'Voulez-vous vraiment publier la sous-capacité "{{name}}"? Cette étape est IRREVERSIBLE !',
    },
    publish: {
      ar: 'نشر',
      cs: 'Publikovat',
      de: 'Veröffentlichen',
      en: 'Publish',
      fr: 'Publier',
    },
  },
  revisionControl: {
    eightHoursBack: {
      ar: '8 قبل ساعات',
      cs: '8 hodin zpět',
      de: '8 Stunden zurück',
      en: '8 hours back',
      fr: '8 heures en arrière',
    },
    fortyEightHoursBack: {
      ar: '48 قبل ساعات',
      cs: '48 hodin zpět',
      de: '48 Stunden zurück',
      en: '48 hours back',
      fr: '48 heures en arrière',
    },
    fourHoursBack: {
      ar: '4 قبل ساعات',
      cs: '4 hodiny zpět',
      de: '4 Stunden zurück',
      en: '4 hours back',
      fr: '4 heures en arrière',
    },
    historyIsNotAvailable: {
      ar: 'السجل غير متاح',
      cs: 'Historie není dostupná',
      de: 'History ist nicht vorhanden',
      en: 'History is not available',
      fr: "L'historique n'est pas accessible",
    },
    isLoading: {
      ar: 'جاري تحميل قائمة التاريخ...',
      cs: 'Historické položky se načítají...',
      de: 'Historische Posten werden geladen…',
      en: 'History list is loading...',
      fr: 'La liste historique est en cours de chargement...',
    },
    oneHourBack: {
      ar: '1 قبل ساعة',
      cs: '1 hodina zpět',
      de: '1 Stunde zurück',
      en: '1 hour back',
      fr: '1 heure en arrière',
    },
    twentyFourHoursBack: {
      ar: '24 قبل ساعات',
      cs: '24 hodin zpět',
      de: '24 Stunden zurück',
      en: '24 hours back',
      fr: '24 heures en arrière',
    },
  },
  roundIconToolTip: {
    delete: {
      ar: 'حذف',
      cs: 'Smazat',
      de: 'Löschen',
      en: 'Delete',
      fr: 'Effacer',
    },
    duplicate: {
      ar: 'نسخة',
      cs: 'Duplikovat',
      de: 'Duplizieren',
      en: 'Duplicate',
      fr: 'Dupliquer',
    },
    move: {
      ar: 'نقل',
      cs: 'Přesunout',
      de: 'Verschieben',
      en: 'Move',
      fr: 'Déplacer',
    },
    restore: {
      ar: 'استعادة',
      cs: 'Obnovit',
      de: 'Erneuern',
      en: 'Restore',
      fr: 'Restaurer',
    },
    saveAsTemplate: {
      ar: 'احفظ كقالب /كنموذج',
      cs: 'Uložit jako šablonu',
      de: 'Als Vorlage speichern',
      en: 'Save as Template',
      fr: 'Enregistrer comme modèle',
    },
    share: {
      ar: 'مشاركة',
      cs: 'Sdílet',
      de: 'Teilen',
      en: 'Share',
      fr: 'Partager',
    },
  },
  routes: {
    administration: {
      ar: 'إدارة',
      cs: 'Správa',
      de: 'Verwaltung',
      en: 'Administration',
      fr: 'Administration',
    },
    archivedJourneys: {
      ar: 'رحلات محتفظ بها في الأرشيف',
      cs: 'Archivované cesty',
      de: 'Archivierte Pfade',
      en: 'Archived journeys',
      fr: 'Parcours archivés',
    },
    archivedTenants: {
      ar: 'المستأجرون المؤرشفون',
      cs: 'Archivovaní tenanti',
      de: 'Archivierte Tenants',
      en: 'Archived tenants',
      fr: 'Tenants archivés',
    },
    atlas: {
      ar: 'أطلس',
      cs: 'Atlas',
      de: 'Atlas',
      en: 'Atlas',
      fr: "L'atlas",
    },
    atlases: {
      ar: 'جميع الأطالس',
      cs: 'Přehled atlasů',
      de: 'Atlasse im Überblick',
      en: 'All atlases',
      fr: 'Tous les atlas',
    },
    backlog: {
      ar: 'قائمة المهام',
      cs: 'Backlog',
      de: 'Backlog',
      en: 'Backlog',
      fr: 'Backlog',
    },
    capabilities: {
      ar: 'القدرات المتصلة',
      cs: 'Connected capabilities',
      de: 'Connected Capabilities',
      en: 'Connected capabilities',
      fr: 'Connected capabilities',
    },
    capabilityManagement: {
      ar: 'إدارة القدرات',
      cs: 'Správa kapabilit',
      de: 'Kapabilitätsmanagement',
      en: 'Management of capabilities',
      fr: 'Gestion des capacités',
    },
    channels: {
      ar: 'إدارة القنوات',
      cs: 'Správa kanálů',
      de: 'Kanalverwaltung',
      en: 'Management of Channels',
      fr: 'Gérer des canaux',
    },
    editTenant: {
      ar: 'إدارة المستأجر',
      cs: 'Správa tenanta',
      de: 'Tenant-Verwaltung',
      en: 'Management of Tenant',
      fr: 'Administration du tenant',
    },
    folder: {
      ar: 'مجلد',
      cs: 'Složka',
      de: 'Ordner',
      en: 'Folder',
      fr: 'Dossier',
    },
    folders: {
      ar: 'لائحة المجلدات',
      cs: 'Seznam složek',
      de: 'Ordner-Liste',
      en: 'List of folders',
      fr: 'Liste de dossiers',
    },
    globalAdministration: {
      ar: 'إدارة',
      cs: 'Správa',
      de: 'Verwaltung',
      en: 'Administration',
      fr: 'Administration',
    },
    globalCapabilityManagement: {
      ar: 'إدارة القدرات',
      cs: 'Správa kapabilit',
      de: 'Kapabilitätsmanagement',
      en: 'Management of capabilities',
      fr: 'Gestion des capacités',
    },
    globalTemplate: {
      ar: 'قالب / نموذج',
      cs: 'Šablona',
      de: 'Vorlage',
      en: 'Template',
      fr: 'Modèle',
    },
    globalTemplateManagement: {
      ar: 'إدارة القوالب',
      cs: 'Správa šablon',
      de: 'Vorlagenverwaltung',
      en: 'Management of Templates',
      fr: 'Gestion de modèles',
    },
    globalTemplatePreview: {
      ar: 'رؤية أولية للنموذج',
      cs: 'Náhled šablony',
      de: 'Vorlagenvorschau',
      en: 'Template preview',
      fr: 'Aperçu du modèle',
    },
    globalUserManagement: {
      ar: 'إدارة المستخدمين',
      cs: 'Správa uživatelů',
      de: 'Benutzerverwaltung',
      en: 'Management of Users',
      fr: 'Administration des utilisateurs',
    },
    groups: {
      ar: 'المجموعات',
      cs: 'Skupiny',
      de: 'Gruppen',
      en: 'Groups',
      fr: 'Groupes',
    },
    journey: {
      ar: 'رحلة',
      cs: 'Cesta',
      de: 'Pfad',
      en: 'Journey',
      fr: 'Parcours',
    },
    journeyFolder: {
      ar: 'رحلة',
      cs: 'Cesta',
      de: 'Pfad',
      en: 'Journey',
      fr: 'Parcours',
    },
    journeys: {
      ar: 'رحلات',
      cs: 'Cesty',
      de: 'Pfade',
      en: 'Journeys',
      fr: 'Parcours',
    },
    limits: {
      ar: 'حدود المستأجر',
      cs: 'Limity tenanta',
      de: 'Tenant-Limits',
      en: 'Tenant limits',
      fr: 'Limites du tenant',
    },
    listOfJourneys: {
      ar: 'كافة الطرق',
      cs: 'Přehled cest',
      de: 'Pfade im Überblick',
      en: 'All journeys',
      fr: 'Tous les parcours',
    },
    localTemplate: {
      ar: 'قالب / نموذج',
      cs: 'Šablona',
      de: 'Vorlage',
      en: 'Template',
      fr: 'Modèle',
    },
    localTemplatePreview: {
      ar: 'رؤية أولية للنموذج',
      cs: 'Náhled šablony',
      de: 'Vorlagenvorschau',
      en: 'Template preview',
      fr: 'Aperçu du modèle',
    },
    login: {
      ar: 'تسجيل الدخول',
      cs: 'Přihlásit se',
      de: 'Anmelden',
      en: 'Login',
      fr: 'Se connecter',
    },
    newJourney: {
      ar: 'طريق جديد',
      cs: 'Nová cesta',
      de: 'Neuer Pfad',
      en: 'New Journey',
      fr: 'Nouveau parcours',
    },
    newJourneyAtlas: {
      ar: 'أطلس جديد',
      cs: 'Nový atlas',
      de: 'Neuer Atlas',
      en: 'New Atlas',
      fr: 'Nouvel atlas',
    },
    newJourneyFolder: {
      ar: 'مجلد جديد',
      cs: 'Nová složka',
      de: 'Neuer Ordner',
      en: 'New Folder',
      fr: 'Nouveau dossier',
    },
    persona: {
      ar: 'الشخصية',
      cs: 'Persona',
      de: 'Persona',
      en: 'Persona',
      fr: 'Persona',
    },
    personas: {
      ar: 'جميع الشخصيات',
      cs: 'Všechny persony',
      de: 'Alle Personas',
      en: 'All personas',
      fr: 'Tous les personas',
    },
    recycleBinJourney: {
      ar: 'الشخصية',
      cs: 'Persona',
      de: 'Persona',
      en: 'Persona',
      fr: 'Persona',
    },
    search: {
      ar: 'نتائج البحث',
      cs: 'Výsledky vyhledávání',
      de: 'Suchergebnisse',
      en: 'Search Results',
      fr: 'Résultats de la recherche',
    },
    templateManagement: {
      ar: 'إدارة القوالب',
      cs: 'Správa šablon',
      de: 'Vorlagenverwaltung',
      en: 'Management of Templates',
      fr: 'Gestion de modèles',
    },
    tenant: {
      ar: 'المستأجر',
      cs: 'Tenant',
      de: 'Tenant',
      en: 'Tenant',
      fr: 'Tenant',
    },
    tenants: {
      ar: 'لائحة المستأجرين',
      cs: 'Seznam tenantů',
      de: 'Tenant-Liste',
      en: 'List of Tenants',
      fr: 'Liste des tenants',
    },
    test: {
      ar: 'اختبار',
      cs: 'Test',
      de: 'Test',
      en: 'Test',
      fr: 'Test',
    },
    tutorial: {
      ar: 'المساعدة والدعم ',
      cs: 'Nápověda a podpora',
      de: 'Assistenz und Unterstützung',
      en: 'Help and Support',
      fr: 'Aide et soutien',
    },
    tutorialExportImport: {
      ar: 'التصدير/ الاستراد',
      cs: 'Export/Import',
      de: 'Export/Import',
      en: 'Export/Import',
      fr: 'Exportation/importation',
    },
    tutorialIntroduction: {
      ar: 'المقدمة ',
      cs: 'Úvod',
      de: 'Startseite',
      en: 'Introduction',
      fr: 'Introduction',
    },
    tutorialJourneys: {
      ar: 'الرحلات ',
      cs: 'Cesty',
      de: 'Pfade',
      en: 'Journeys',
      fr: 'Parcours',
    },
    tutorialNavigation: {
      ar: 'التوجه / الملاحة',
      cs: 'Navigace',
      de: 'Navigation',
      en: 'Navigation',
      fr: 'Navigation',
    },
    tutorialPersonas: {
      ar: 'الشخصيات',
      cs: 'Persony',
      de: 'Personas',
      en: 'Personas',
      fr: 'Personas',
    },
    tutorialSettings: {
      ar: 'إعداد',
      cs: 'Nastavení',
      de: 'Einstellung',
      en: 'Settings',
      fr: 'Paramètres',
    },
    userManagement: {
      ar: 'إدارة المستخدمين',
      cs: 'Správa uživatelů',
      de: 'Benutzerverwaltung',
      en: 'Management of Users',
      fr: 'Administration des utilisateurs',
    },
    userStory: {
      ar: 'قائمة المهام',
      cs: 'Backlog',
      de: 'Backlog',
      en: 'Backlog',
      fr: 'Backlog',
    },
  },
  search: {
    foundJourneys: {
      ar: 'تم العثور على {{number}} نتيجة  ',
      cs: 'nalezeno u {{number}} výskytů',
      de: 'bei {{number}} Ergebnissen gefunden',
      en: '{{number}} results found',
      fr: '{{number}} résultats trouvés',
    },
    foundResults: {
      ar: 'وجد {{number}} نتائج',
      cs: 'Nalezeno {{number}} výskytů',
      de: '{{number}} Ergebnisse gefunden',
      en: '{{number}} results found',
      fr: 'Trouvés {{number}} occurences',
    },
    inTheJourneyContent: {
      ar: 'في محتوى الرحلة',
      cs: 'V obsahu cesty',
      de: 'Im Pfadinhalt',
      en: 'In the Journey contents',
      fr: 'Dans le contenu du parcours',
    },
    searchLoading: {
      ar: 'جارٍ البحث ...',
      cs: 'Vyhledávání ...',
      de: 'Suche ...',
      en: 'Searching ...',
      fr: 'Recherche ...',
    },
    searchResults: {
      ar: 'نتائج البحث',
      cs: 'Výsledky vyhledávání',
      de: 'Suchergebnisse',
      en: 'Search Results',
      fr: 'Résultats de la recherche',
    },
  },
  sendEmail: {
    subject: {
      ar: 'تعليق',
      cs: 'Zpětná vazba',
      de: 'Feedback',
      en: 'Feedback',
      fr: 'Rétroaction',
    },
  },
  shareJourney: {
    hello: {
      ar: 'مرحباً',
      cs: 'Dobrý den,',
      de: 'Guten Tag,',
      en: 'Hello,',
      fr: 'Bonjour,',
    },
    kindRegards: {
      ar: 'مع أطيب التمنيات,',
      cs: 'S pozdravem,',
      de: 'Mit freundlichen Grüßen,',
      en: 'Kind regards,',
      fr: 'Sincères amitiés,',
    },
    subject: {
      ar: 'رحلة مشتركة',
      cs: 'Sdílená cesta',
      de: 'geteilter Pfad',
      en: 'Shared Journey',
      fr: 'Parcours partagé',
    },
  },
  sixPillarsPreview: {
    backlog: {
      ar: 'قائمة المهام',
      cs: 'Backlog',
      de: 'Backlog',
      en: 'Backlog',
      fr: 'Backlog',
    },
    empathy: {
      ar: 'التعاطف',
      cs: 'Empatie',
      de: 'Einfühlungsvermögen',
      en: 'Empathy',
      fr: 'Empathie',
    },
    empathyContent: {
      ar: 'يُعد إبداء التفهم لظروف العميل أحد العوامل التي تدعم خلق علاقة وطيدة مع العميل. لقد حان الوقت لجميع الشركات لإظهار اهتمامها والبرهنة على أن سلامة ورفاهية موظفيها وعملائها يُعد الشاغل الرئيسي لهم.',
      cs: 'Porozumění podmínkám a okolnostem klienta za účelem vytvoření silného vztahu. Je načase, aby všechny firmy ukázaly, že jejich prvořadým zájmem je bezpečí a kvalita života jejich zaměstnanců a zákazníků.',
      de: 'Verständnis für die Situation des Kunden, um eine tiefe Beziehung aufzubauen. Es ist an der Zeit, dass alle Unternehmen zeigen, dass ihnen vor allen Dingen die Sicherheit und das Wohlergehen ihrer Mitarbeiter und Kunden am Herzen liegen.',
      en: "Achieving an understanding of the customer's circumstances to drive deep rapport. It is time for all firms to show that they care, that the safety and wellbeing of their employees and customers is their prime concern.",
      fr: "Comprendre les circonstances du client pour établir un rapport profond. Il est temps pour toutes les entreprises de montrer qu'elles se soucient de la sécurité et du bien-être de leurs employés et de leurs clients.",
    },
    expectations: {
      ar: 'التوقعات',
      cs: 'Očekávání',
      de: 'Erwartungen',
      en: 'Expectations',
      fr: 'Attentes',
    },
    expectationsContent: {
      ar: 'إدارة وتلبية وتجاوز توقعات العملاء. حيث إن العملاء لديهم احتياجات ولديهم أيضًا توقعات تتعلق بكيفية تلبية هذه الاحتياجات.',
      cs: 'Řízení, naplňování a překonávání klientských očekávání. Klienti mají své potřeby a očekávání ohledně toho, jak by měly být naplňovány.',
      de: 'Management, Erfüllung und Übertreffen von Kundenerwartungen. Kunden haben Bedürfnisse und sie haben auch Erwartungen, wie diese erfüllt werden sollten.',
      en: 'Managing, meeting and exceeding customer expectations. Customers have needs, and they also have expectations about how these needs will be met.',
      fr: 'Gérer, satisfaire et dépasser les attentes des clients. Les clients ont des besoins et également des attentes par rapport à la manière dont ces besoins seront satisfaits.',
    },
    integrity: {
      ar: 'النزاهة',
      cs: 'Integrita',
      de: 'Integrität',
      en: 'Integrity',
      fr: 'Intégrité',
    },
    integrityContent: {
      ar: 'التصرف بنزاهة وبناء الثقة. الثقة هي نتاج انتهاج سلوك تنظيمي مُتسق يدل على الجدارة بالثقة.',
      cs: 'Jednání v souladu s morálními a etickými zásadami a budování důvěry. Důvěra je výsledkem konzistentního jednání organizace, které demonstruje její důvěryhodnost.',
      de: 'Handeln entsprechend den moralischen und ethischen Grundsätzen und Aufbau von Vertrauen. Vertrauen ist das Ergebnis eines konsequenten Verhaltens eines Unternehmens, das seine Glaubwürdigkeit unter Beweis stellt.',
      en: 'Acting with integrity and engendering trust. Trust is an outcome of consistent organizational behavior that demonstrates trustworthiness.',
      fr: "Agir avec intégrité et susciter la confiance. La confiance résulte d'un comportement organisationnel cohérent qui démontre la fiabilité.",
    },
    more: {
      ar: 'أكثر',
      cs: 'Více',
      de: 'Mehr',
      en: 'More',
      fr: 'En savoir plus',
    },
    openBacklog: {
      ar: 'فتح الأعمال غير المنجزة',
      cs: 'Otevřít backlog',
      de: 'Backlog öffnen',
      en: 'Open backlog',
      fr: 'Ouvrir le backlog',
    },
    personalization: {
      ar: 'إضفاء الطابع الشخصي',
      cs: 'Personalizace',
      de: 'Individualisierung',
      en: 'Personalization',
      fr: 'Personnalisation',
    },
    personalizationContent: {
      ar: 'استخدام أسلوب الاهتمام الشخصي لخلق نوع من التواصل العاطفي. توضيح أنك تفهم احتياجات وظروف العميل، وأن تُكيف التجربة وفقًا لذلك هو المعيار المتوقع الاسترشاد به حاليًا.',
      cs: 'Využití individuálně orientované pozornosti k podpoře emocionálního spojení. Zdůraznění skutečnosti, že rozumíte specifickým potřebám a okolnostem zákazníka a odpovídajícím způsobem přizpůsobíte zákaznickou zkušenost, je nyní očekávanou normou.',
      de: 'Individualisierte Betreuung zur Förderung der emotionalen Bindung. Zu zeigen, dass man die spezifischen Bedürfnisse und Umstände des Kunden versteht und das Erlebnis entsprechend anpasst, ist die heute erwartete Norm.',
      en: "Using individualised attention to drive an emotional connection. Demonstrating that you understand the customer's specific needs and circumstances and will adapt the experience accordingly is now the expected norm.",
      fr: "Profiter de l'attention individualisée pour créer un lien émotionnel. Désormais, la norme attendue c'est de démontrer que vous comprenez les besoins et les circonstances spécifiques du client et que par conséquent vous adapterez l'expérience.",
    },
    resolution: {
      ar: 'الإصرار',
      cs: 'Řešení problémů',
      de: 'Problemlösung',
      en: 'Resolution',
      fr: 'Résolution',
    },
    resolutionContent: {
      ar: 'تحويل تجربة سيئة إلى تجربة رائعة. تاريخيًا عندما يتعلق الأمر بالإصرار، فإن المنظمات تُركز على استعادة الخدمة.',
      cs: 'Změna špatné zkušenosti na skvělou. Při hledání řešení problémů se organizace i historicky vždy zaměřovaly na to, aby daly do pořádku nefungující služby.',
      de: 'Eine schlechte Erfahrung in eine großartige verwandeln. Bei der Suche nach Problemlösungen haben sich Unternehmen in der Vergangenheit immer auf die Wiederherstellung von Leistungen fokussiert.',
      en: 'Turning a poor experience into a great one. Historically, when it comes to resolution, organizations always focused on service recovery.',
      fr: "Transformer une mauvaise expérience en une excellente. Historiquement, lorsqu'il s'agit de la résolution, les organisations se sont concentrées sur la récupération des services.",
    },
    sixPillars: {
      ar: 'الركائز الستة',
      cs: 'Šest pilířů',
      de: 'Six Pillars',
      en: 'Six Pillars',
      fr: 'Six piliers',
    },
    timeAndEffort: {
      ar: 'الوقت والجهد',
      cs: 'Čas a úsilí',
      de: 'Zeit und Aufwand',
      en: 'Time and effort',
      fr: 'Temps et effort',
    },
    timeAndEffortContent: {
      ar: 'تقليل الجهد الذي يبذله العميل وخلق عمليات خالية من الاحتكاك. حيث إن العملاء يُعانون من ضيق الوقت، ويبحثون عن الإرضاء الفوري على نحوٍ مُتزايد.',
      cs: 'Minimalizace úsilí, které musí klient vynaložit, a vytváření hladce fungujících procesů. Zákazníci trpí nedostatkem času a stále častěji vyhledávají okamžité uspokojení svých potřeb.',
      de: 'Minimierung des Kundenaufwands und Schaffung reibungsloser Prozesse. Kunden haben wenig Zeit und suchen zunehmend nach sofortiger Befriedigung ihrer Bedürfnisse.',
      en: 'Minimizing customer effort and creating frictionless processes. Customers are time poor and are increasingly often looking for instant gratification.',
      fr: 'Minimiser les efforts du client et créer des processus sans friction. Les clients manquent de temps et recherchent, de plus en plus, de la gratification instantanée.',
    },
  },
  sort: {
    sortBy: {
      ar: 'الترتيب حسب',
      cs: 'Seřadit podle',
      de: 'Sortieren nach',
      en: 'Sort by',
      fr: 'Trier par',
    },
    sortByOptions: {
      createdNewest: {
        ar: 'تمت عملية الإنشاء (الأحدث أولاً)',
        cs: 'Vytvořeno (nejprve nejnovější)',
        de: 'Erstellt (neueste zuerst)',
        en: 'Created (newest first)',
        fr: 'Créé (le plus récent en premier)',
      },
      createdOldest: {
        ar: 'تمت عملية الإنشاء (الأقدم أولاً)',
        cs: 'Vytvořeno (nejstarší jako první)',
        de: 'Erstellt (älteste zuerst)',
        en: 'Created (oldest first)',
        fr: 'Créé (le plus ancien en premier)',
      },
      idAsc: {
        ar: 'الرقم التعريفي (تصاعدي)',
        cs: 'ID (vzestupně)',
        de: 'ID (aufsteigend)',
        en: 'ID (ascending)',
        fr: 'ID (croissant)',
      },
      idDesc: {
        ar: 'الرقم التعريفي (تنازلي)',
        cs: 'ID (sestupně)',
        de: 'ID (absteigend)',
        en: 'ID (descending)',
        fr: 'ID (décroissant)',
      },
      titleAZ: {
        ar: 'الاسم من الألف - الياء',
        cs: 'Název A-Z',
        de: 'Titel A-Z',
        en: 'Title A-Z',
        fr: 'Titre A-Z',
      },
      titleZA: {
        ar: 'من الياء - إلى الألف',
        cs: 'Název Z-A',
        de: 'Titel Z-A',
        en: 'Title Z-A',
        fr: 'Titre Z-A',
      },
      updatedNewest: {
        ar: 'تم تحديثه (الأحدث أولاً)',
        cs: 'Aktualizováno (nejnovější jako první)',
        de: 'Aktualisiert (neueste zuerst)',
        en: 'Updated (newest first)',
        fr: 'Mis à jour (le plus récent en premier)',
      },
      updatedOldest: {
        ar: 'تم تحديثه (الأقدم أولاً)',
        cs: 'Aktualizováno (nejstarší jako první)',
        de: 'Aktualisiert (älteste zuerst)',
        en: 'Updated (oldest first)',
        fr: 'Mis à jour (le plus ancien en premier)',
      },
    },
  },
  statusButton: {
    deleted: {
      ar: 'تم الحذف',
      cs: 'Smazané',
      de: 'Gelöscht',
      en: 'Deleted',
      fr: 'Supprimé',
    },
  },
  successModal: {
    connectedCapabilities: {
      description: {
        ar: 'تم استيراد القدرات بنجاح',
        cs: 'Kapability byly úspěšně naimportovány',
        de: 'Die Kapabilitäten wurden erfolgreich importiert',
        en: 'Capabilities imported successfully',
        fr: 'Les capacités ont été importées avec succès',
      },
      title: {
        ar: 'استيراد قدرات',
        cs: 'Import kapabilit',
        de: 'Kapabilitäten-Import',
        en: 'Capability import',
        fr: 'Importation de capacités',
      },
    },
    journeys: {
      description: {
        ar: 'تم استيراد الملف <strong>"{{name}}"</strong> بنجاح.',
        cs: 'Soubor <strong>"{{name}}"</strong> úspěšně naimportován.',
        de: 'Die Datei <strong>"{{name}}"</strong> wurde erfolgreich importiert.',
        en: 'File <strong>"{{name}}"</strong> was successfully imported.',
        fr: 'Le fichier <strong>"{{name}}"</strong> a été importé avec succès.',
      },
      title: {
        ar: 'استيراد من Excel',
        cs: 'Import z Excelu',
        de: 'Import aus Excel',
        en: 'Import from Excel',
        fr: 'Importation depuis Excel',
      },
    },
  },
  templateManagement: {
    applyFilter: {
      ar: 'استخدام المرشح',
      cs: 'Použít filtr',
      de: 'Filter anwenden',
      en: 'Apply filter',
      fr: 'Appliquer le filtre',
    },
    created: {
      ar: 'تم الإنشاء',
      cs: 'Vytvořeno',
      de: 'Erstellt',
      en: 'Created',
      fr: 'Crée',
    },
    filterLabel: {
      category: {
        ar: 'الفئة',
        cs: 'Kategorie',
        de: 'Kategorie',
        en: 'Category',
        fr: 'Catégorie',
      },
      templates: {
        ar: 'الحالة على الشاشة بالنسبة للإنشاء الطريق',
        cs: 'Stav na obrazovce vytváření cesty',
        de: 'Status auf dem Bildschirm für die Pfaderstellung',
        en: 'Status on Journey creation screen',
        fr: 'État sur l’écran de création de parcours',
      },
    },
    globalTemplatesTitle: {
      ar: 'إدارة القوالب والأمثلة العالمية',
      cs: 'Správa globálních šablon a příkladů',
      de: 'Verwaltung globaler Vorlagen und Beispiele',
      en: 'Management of Global Templates and Examples',
      fr: 'Gestion des modèles et exemples globaux',
    },
    hidden: {
      ar: 'مخفي',
      cs: 'Skrytá',
      de: 'Ausgeblendet',
      en: 'Hidden',
      fr: 'Masqué',
    },
    localTemplatesTitle: {
      ar: 'إدارة القوالب/ النماذج المحلية',
      cs: 'Správa lokálních šablon',
      de: 'Verwaltung lokaler Vorlagen',
      en: 'Management of Local Templates',
      fr: 'Gestion des modèles locaux',
    },
    reset: {
      ar: 'إعادة ضبط',
      cs: 'Resetovat',
      de: 'Zurücksetzen',
      en: 'Reset',
      fr: 'Rétablir',
    },
    searchPlaceholder: {
      examples: {
        ar: 'البحث في الأمثلة',
        cs: 'Vyhledat ukázky',
        de: 'Muster suchen',
        en: 'Search examples',
        fr: 'Exemples de recherche',
      },
      globalTemplate: {
        ar: 'البحث عن قالب عالمي',
        cs: 'Vyhledat globální šablonu',
        de: 'Globale Vorlagen suchen',
        en: 'Search global template',
        fr: 'Rechercher un modèle global',
      },
      localTemplate: {
        ar: 'البحث عن قالب محلي',
        cs: 'Vyhledat lokální šablonu',
        de: 'Lokale Vorlagen suchen',
        en: 'Search local template',
        fr: 'Rechercher un modèle local',
      },
    },
    visible: {
      ar: 'المرئية',
      cs: 'Viditelná',
      de: 'Sichtbar',
      en: 'Visible',
      fr: 'Visible',
    },
  },
  templates: {
    deleteTemplateModal: {
      desc: {
        ar: 'هل أنت متأكد من أنك تريد حذف طبقة <strong>"{{name}}"</strong>؟',
        cs: 'Opravdu chcete smazat šablonu <strong>"{{name}}"</strong>?',
        de: 'Wollen Sie die Vorlage <strong>"{{name}}"</strong> wirklich löschen?',
        en: 'Do you really want to delete the template <strong>"{{name}}"</strong>?',
        fr: 'Voulez-vous vraiment supprimer le modèle <strong>"{{name}}"</strong>?',
      },
      title: {
        ar: 'حذف القالب',
        cs: 'Smazat šablonu?',
        de: 'Vorlage löschen?',
        en: 'Delete the template?',
        fr: 'Supprimer le modèle ?',
      },
    },
    edit: {
      ar: 'تحرير',
      cs: 'Editovat',
      de: 'Bearbeiten',
      en: 'Edit',
      fr: 'Modifier',
    },
    example: {
      ar: 'مثال',
      cs: 'Ukázka',
      de: 'Muster',
      en: 'Example',
      fr: 'Exemple',
    },
    examples: {
      ar: 'أمثلة',
      cs: 'Ukázky',
      de: 'Muster',
      en: 'Examples',
      fr: 'Exemples',
    },
    preview: {
      ar: 'معاينة/ رؤية أولية',
      cs: 'Náhled',
      de: 'Vorschau',
      en: 'Preview',
      fr: 'Aperçu',
    },
    saveOrEditTemplateModal: {
      desc: {
        label: {
          ar: 'الوصف',
          cs: 'Popis',
          de: 'Beschreibung',
          en: 'Description',
          fr: 'Description',
        },
        placeholder: {
          ar: 'إضافة وصف',
          cs: 'Vložte popis',
          de: 'Beschreibung einfügen',
          en: 'Enter description',
          fr: 'Insérer la description',
        },
      },
      editExample: {
        ar: 'تعديل المثال',
        cs: 'Editovat ukázku',
        de: 'Muster bearbeiten',
        en: 'Edit example',
        fr: 'Modifier l’exemple',
      },
      editTemplate: {
        ar: 'تحرير قالب  {{templateType}}',
        cs: 'Editace {{templateType}} šablony',
        de: 'Bearbeitung der {{templateType}} Vorlage',
        en: 'Edit {{templateType}} template',
        fr: 'Modification du modèle {{templateType}}',
      },
      name: {
        duplicity: {
          ar: 'هذا الاسم أصبح موجوداً من قبل',
          cs: 'Název již existuje',
          de: 'Der Name existiert bereits',
          en: 'Name already exists',
          fr: 'Le nom existe déjà',
        },
      },
      saveAsTemplate: {
        ar: 'الحفظ كقالب / كنموذج',
        cs: 'Uložit jako šablonu',
        de: 'Als Vorlage speichern',
        en: 'Save as Template',
        fr: 'Enregistrer comme modèle',
      },
      type: {
        ar: 'النوع',
        cs: 'Typ',
        de: 'Art',
        en: 'Type',
        fr: 'Type',
      },
    },
    template: {
      ar: 'قالب',
      cs: 'Šablona',
      de: 'Vorlage',
      en: 'Template',
      fr: 'Modèle',
    },
    templateType: {
      global: {
        ar: 'بشكل عولمي',
        cs: 'globální',
        de: 'global',
        en: 'global',
        fr: 'global',
      },
      local: {
        ar: 'بشكل محلي',
        cs: 'lokální',
        de: 'lokal',
        en: 'local',
        fr: 'local',
      },
    },
    templates: {
      ar: 'قوالب',
      cs: 'Šablony',
      de: 'Vorlagen',
      en: 'Templates',
      fr: 'Modèles',
    },
    use: {
      ar: 'استخدم',
      cs: 'Použít',
      de: 'Verwenden',
      en: 'Use',
      fr: 'Appliquer',
    },
  },
  tenantSectorDropdown: {
    ASSEM: {
      ar: 'إدارة الأصول',
      cs: 'Asset Management',
      de: 'Finanzportfolioverwaltung',
      en: 'Asset Management',
      fr: 'Banque de détail',
    },
    AUTOM: {
      ar: 'السيارات',
      cs: 'Automotive',
      de: 'Automobilindustrie',
      en: 'Automotive',
      fr: 'Automobile',
    },
    BUILC: {
      ar: 'التشييد والبناء',
      cs: 'Building & Construction',
      de: 'Bauwesen',
      en: 'Building & Construction',
      fr: 'Bâtiment et construction',
    },
    CITIE: {
      ar: 'مدن',
      cs: 'Cities',
      de: 'Städte',
      en: 'Cities',
      fr: 'Villes',
    },
    COMMI: {
      ar: 'التأمين التجاري',
      cs: 'Commercial Insurance',
      de: 'Kommerzielle Versicherung',
      en: 'Commercial Insurance',
      fr: 'Assurance commerciale',
    },
    CONPG: {
      ar: 'السلع الاستهلاكية المعبأة',
      cs: 'Consumer Packaged Goods',
      de: 'Abgepackte Verbrauchswaren',
      en: 'Consumer Packaged Goods',
      fr: 'Produits de grande consommation',
    },
    CORPB: {
      ar: 'الخدمات المصرفية للشركات',
      cs: 'Corporate Banking',
      de: 'Bankgeschäfte mit Unternehmen',
      en: 'Corporate Banking',
      fr: "Banque d'entreprise",
    },
    DEFEN: {
      ar: 'الدفاع',
      cs: 'Defense',
      de: 'Verteidigung',
      en: 'Defense',
      fr: 'Défense',
    },
    DIGAP: {
      ar: 'منصات الإعلان الرقمي',
      cs: 'Digital Advertising Platforms',
      de: 'Digitale Werbeplattformen',
      en: 'Digital Advertising Platforms',
      fr: 'Plateformes de publicité numérique',
    },
    FEDEG: {
      ar: 'الحكومة الفيدرالية',
      cs: 'Federal Government',
      de: 'Bundesregierung',
      en: 'Federal Government',
      fr: 'Administration fédérale',
    },
    HARDW: {
      ar: 'الأجهزة',
      cs: 'Hardware',
      de: 'Hardware',
      en: 'Hardware',
      fr: 'Matériels',
    },
    HEALT: {
      ar: 'الرعاىة الصحية',
      cs: 'Healthcare',
      de: 'Gesundheitswesen',
      en: 'Healthcare',
      fr: 'Santé',
    },
    HIGHE: {
      ar: 'التعليم العالي',
      cs: 'Higher Education',
      de: 'Höhere Bildung',
      en: 'Higher Education',
      fr: 'Enseignement supérieur',
    },
    HUMSS: {
      ar: 'الخدمات الإنسانية والاجتماعية',
      cs: 'Human & Social Services',
      de: 'Sozialdienstleistungen',
      en: 'Human & Social Services',
      fr: 'Services humains et sociaux',
    },
    INDUM: {
      ar: 'الصناعات التحويلية',
      cs: 'Industrial Manufacturing',
      de: 'Industrielle Fertigung',
      en: 'Industrial Manufacturing',
      fr: 'Fabrication industrielle',
    },
    INVEB: {
      ar: 'الخدمات المصرفية الإستثمارية',
      cs: 'Investment Banking',
      de: 'Investment Banking',
      en: 'Investment Banking',
      fr: "Banque d'investissement",
    },
    JUSTC: {
      ar: 'العدل المستمر',
      cs: 'Justice Continuum',
      de: 'Rechts-Kontinuum',
      en: 'Justice Continuum',
      fr: 'Processus de la justice',
    },
    LEISA: {
      ar: 'الترفيه والسكن',
      cs: 'Leisure & Accommodation',
      de: 'Freizeit und Unterkunft',
      en: 'Leisure & Accommodation',
      fr: 'Loisirs et hébergement',
    },
    LIFAI: {
      ar: 'التأمين على الحياة والمعاش',
      cs: 'Life & Annuity Insurance',
      de: 'Lebens- und Rentenversicherung',
      en: 'Life & Annuity Insurance',
      fr: 'Assurance vie et rente viagère',
    },
    LIFES: {
      ar: 'علوم الحياة',
      cs: 'Life Sciences',
      de: 'Biowissenschaften',
      en: 'Life Sciences',
      fr: 'Sciences de la vie',
    },
    LOCAG: {
      ar: 'الحكومة المحلية',
      cs: 'Local Government',
      de: 'Kommunalverwaltung',
      en: 'Local Government',
      fr: 'Administration locale',
    },
    MEDIA: {
      ar: 'وسائط',
      cs: 'Media',
      de: 'Medien',
      en: 'Media',
      fr: 'Médias',
    },
    MEDID: {
      ar: 'الأجهزة الطبية',
      cs: 'Medical Devices',
      de: 'Medizinprodukte',
      en: 'Medical Devices',
      fr: 'Dispositifs médicaux',
    },
    NATIS: {
      ar: 'الأمن القومي',
      cs: 'National Security',
      de: 'Nationale Sicherheit',
      en: 'National Security',
      fr: 'Sécurité nationale',
    },
    OILGA: {
      ar: 'النفط والغاز',
      cs: 'Oil & Gas',
      de: 'Erdöl und Gas',
      en: 'Oil & Gas',
      fr: 'Pétrole et gaz',
    },
    PENSF: {
      ar: 'صناديق التقاعد',
      cs: 'Pension Funds',
      de: 'Pensionfonds',
      en: 'Pension Funds',
      fr: 'Fonds de pension',
    },
    PERSI: {
      ar: 'التأمين الشخصي',
      cs: 'Personal Insurance',
      de: 'Personenversicherung',
      en: 'Personal Insurance',
      fr: 'Assurance personnelle',
    },
    PHARM: {
      ar: 'فارما',
      cs: 'Pharma',
      de: 'Pharmazeutische Industrie',
      en: 'Pharma',
      fr: 'Pharma',
    },
    POWEU: {
      ar: 'مرافق الطاقة',
      cs: 'Power & Utilities',
      de: 'Energieversorgung und öffentliche Dienstleistungen',
      en: 'Power & Utilities',
      fr: "Énergie et services d'utilité publique",
    },
    PROBS: {
      ar: 'الخدمات المهنية والتجارية',
      cs: 'Professional & Business Services',
      de: 'Dienstleistungen für den gewerblichen Bereich',
      en: 'Professional & Business Services',
      fr: 'Services aux professionnels ou aux entreprises',
    },
    PUBLT: {
      ar: 'النقل العام',
      cs: 'Public Transport',
      de: 'Öffentlicher Verkehr',
      en: 'Public Transport',
      fr: 'Transport public',
    },
    REALE: {
      ar: 'العقارات',
      cs: 'Real Estate',
      de: 'Immobilien',
      en: 'Real Estate',
      fr: 'Immobilier',
    },
    RETAB: {
      ar: 'الخدمات المصرفية للأفراد',
      cs: 'Retail Banking',
      de: 'Privatkundengeschäft',
      en: 'Retail Banking',
      fr: 'Banque de détail',
    },
    RETAI: {
      ar: 'البيع بالتجزئة',
      cs: 'Retail',
      de: 'Einzelhandel',
      en: 'Retail',
      fr: 'Commerce de détail',
    },
    SAAPI: {
      ar: 'البرمجيات كخدمة/ المنصة كخدمة/ البنية التحتية كخدمة',
      cs: 'SaaS / PaaS / IasS',
      de: 'SaaS / PaaS / IasS',
      en: 'SaaS / PaaS / IasS',
      fr: 'SaaS / PaaS / IasS',
    },
    SMACI: {
      ar: 'التأمين التجاري الصغير',
      cs: 'Small Commercial Insurance',
      de: 'Small Commercial Insurance',
      en: 'Small Commercial Insurance',
      fr: 'Assurance des petites entreprises',
    },
    TECHN: {
      ar: 'التكنولوجيا',
      cs: 'Technology',
      de: 'Technologie',
      en: 'Technology',
      fr: 'Technologie',
    },
    TELEC: {
      ar: 'الاتصالات',
      cs: 'Telecoms',
      de: 'Telekommunikation',
      en: 'Telecoms',
      fr: 'Télécommunications',
    },
    TRANS: {
      ar: 'النقل',
      cs: 'Transportation',
      de: 'Verkehr',
      en: 'Transportation',
      fr: 'Transport',
    },
    WEALM: {
      ar: 'إدارة الثروة',
      cs: 'Wealth Management',
      de: 'Vermögensverwaltung',
      en: 'Wealth Management',
      fr: 'Gestion de patrimoine',
    },
  },
  tenants: {
    addNewTenant: {
      ar: 'إنشاء مستأجر جديد',
      cs: 'Založit nového tenanta',
      de: 'Neuen Tenant anlegen',
      en: 'Create a new tenant',
      fr: 'Créer un nouveau tenant',
    },
    allTenants: {
      ar: 'جميع المستأجرين',
      cs: 'Přehled tenantů',
      de: 'Tenants im Überblick',
      en: 'All Tenants',
      fr: 'Tous les tenants',
    },
    archivedTenant: {
      ar: 'المستأجرون المؤرشفون',
      cs: 'Archivovaní tenanti',
      de: 'Archivierte Tenants',
      en: 'Archived tenants',
      fr: 'Tenants archivés',
    },
    createOrEditTenantModal: {
      complete: {
        ar: 'مكتمل',
        cs: 'Dokončit',
        de: 'Fertigstellen',
        en: 'Complete',
        fr: 'Compléter',
      },
      createNewTenant: {
        ar: 'إنشاء مستأجر جديد',
        cs: 'Založení nového tenanta',
        de: 'Den Tenant neu anlegen',
        en: 'Create new tenant',
        fr: 'Créer un nouveau tenant',
      },
      editTenant: {
        ar: 'تعديل المستأجر',
        cs: 'Editace tenanta',
        de: 'Bearbeiten des Tenants',
        en: 'Edit tenant',
        fr: 'Modifier le tenant',
      },
      placeholder: {
        ar: 'إضافة وصف',
        cs: 'Vložte popis',
        de: 'Beschreibung einfügen',
        en: 'Enter description',
        fr: 'Insérer la description',
      },
    },
    notInitialized: {
      ar: 'لم تتم تهيئة قاعدة بيانات المستأجر بعد',
      cs: 'Databáze tenantů zatím nebyla inicializována',
      de: 'Die Tenant-Datenbank ist bislang nicht initialisiert worden.',
      en: 'Tenant database not initialized yet',
      fr: 'La base de données des tenants n’a pas encore été initialisée',
    },
    searchTenant: {
      ar: 'البحث عن مستأجر',
      cs: 'Vyhledat tenant',
      de: 'Tenant suchen',
      en: 'Search tenant',
      fr: 'Rechercher un locataire',
    },
    select: {
      ar: 'تحديد',
      cs: 'Vybrat',
      de: 'Wählen',
      en: 'Select',
      fr: 'Sélectionner',
    },
  },
  textController: {
    dateOfCreation: {
      ar: 'تاريخ الإنشاء',
      cs: 'Datum založení',
      de: 'Anlegedatum',
      en: 'Date of creation',
      fr: 'Date de création',
    },
    lastChange: {
      ar: 'آخر تغيير',
      cs: 'Poslední změna',
      de: 'Letzte Änderung',
      en: 'Last change',
      fr: 'Dernière modification',
    },
  },
  tutorials: {
    basicElements: {
      breadcrumbs: {
        ar: '',
        cs: '',
        de: '',
        en: '1.4.2 Breadcrumbs',
        fr: '',
      },
      header: {
        ar: '',
        cs: '',
        de: '',
        en: '1.4 Basic elements of the application',
        fr: '',
      },
      journeyCondition: {
        ar: '',
        cs: '',
        de: '',
        en: '1.4.3 Journey condition',
        fr: '',
      },
      reversingChanges: {
        example1: {
          ar: '',
          cs: '',
          de: '',
          en: 'A > B > C > D > [E]',
          fr: '',
        },
        example2: {
          ar: '',
          cs: '',
          de: '',
          en: 'A > [B]  > C > D > E',
          fr: '',
        },
        example3: {
          ar: '',
          cs: '',
          de: '',
          en: 'A > B > [C1]',
          fr: '',
        },
        header: {
          ar: '',
          cs: '',
          de: '',
          en: '1.4.4 Reversing changes within a journey/persona in time',
          fr: '',
        },
      },
      subHeader: {
        ar: '',
        cs: '',
        de: '',
        en: '1.4.1 Application menu',
        fr: '',
      },
      textEditing: {
        ar: '',
        cs: '',
        de: '',
        en: '1.4.5 Text editing',
        fr: '',
      },
      textFormatting: {
        ar: '',
        cs: '',
        de: '',
        en: '1.4.6 Text formatting (WYSIWYG field)',
        fr: '',
      },
    },
    exportImport: {
      header: {
        ar: '',
        cs: '',
        de: '',
        en: 'Export/Import of created journey',
        fr: '',
      },
    },
    glossary: {
      introductionGlossary: {
        ar: '',
        cs: '',
        de: '',
        en: '1.1 Glossary of terms and their meaning',
        fr: '',
      },
      introductionGlossaryAtlas: {
        ar: '',
        cs: '',
        de: '',
        en: 'Atlas',
        fr: '',
      },
      introductionGlossaryAtlasDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'The atlas represents a folder which help you and your teams keep journeys and folders organized and structured in one place.',
        fr: '',
      },
      introductionGlossaryFolder: {
        ar: '',
        cs: '',
        de: '',
        en: 'Folder',
        fr: '',
      },
      introductionGlossaryFolderDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'The folder represents a subfolder of above mentioned atlas. Users can create and organize their journeys within these folders to maintain a structured and easily accessible arrangement.',
        fr: '',
      },
      introductionGlossaryJourney: {
        ar: '',
        cs: '',
        de: '',
        en: 'Journey',
        fr: '',
      },
      introductionGlossaryJourneyDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'The journey represents a summary of customer interactions.',
        fr: '',
      },
      introductionGlossaryPhase: {
        ar: '',
        cs: '',
        de: '',
        en: 'Phase',
        fr: '',
      },
      introductionGlossaryPhaseDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'Refers to the broad stages of a customers journey, such as Awareness, Consideration, Purchase, Retention, and Advocacy.',
        fr: '',
      },
      introductionGlossarySubphase: {
        ar: '',
        cs: '',
        de: '',
        en: 'Subphase',
        fr: '',
      },
      introductionGlossarySubphaseDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'Each phase is further broken down into subphases. For example, the Purchase phase might include subphases like Selection, Transaction, and Confirmation.',
        fr: '',
      },
      introductionGlossaryTenant: {
        ar: '',
        cs: '',
        de: '',
        en: 'Tenant',
        fr: '',
      },
      introductionGlossaryTenantDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'The tenant represents the environment of a customer company that has purchased a license to use our software. Within this tenant, users can store and manage their customer journeys, providing a dedicated and customizable space for their specific needs and data.',
        fr: '',
      },
      introductionGlossaryTouchpoint: {
        ar: '',
        cs: '',
        de: '',
        en: 'Touchpoint',
        fr: '',
      },
      introductionGlossaryTouchpointDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'Each subphase is further broken down into touchpoints. These are specific interactions between the customer and the business, like visiting a website, calling customer service, or receiving a product.',
        fr: '',
      },
    },
    introductionBrowsers: {
      chrome: {
        ar: '',
        cs: '',
        de: '',
        en: 'Google Chrome',
        fr: '',
      },
      edge: {
        ar: '',
        cs: '',
        de: '',
        en: 'MS Edge (Chromium only)',
        fr: '',
      },
      mozilla: {
        ar: '',
        cs: '',
        de: '',
        en: 'Mozilla Firefox',
        fr: '',
      },
      safari: {
        ar: '',
        cs: '',
        de: '',
        en: 'Safari',
        fr: '',
      },
    },
    searchPlaceholder: {
      ar: 'البحث عن الفصل',
      cs: 'Hledat kapitolu',
      de: 'Das Kapitel suchen',
      en: 'Search chapter',
      fr: 'Recherche de chapitre',
    },
    sendUsEmail: {
      ar: 'ارسل لنا ايميل',
      cs: 'Pošlete nám e-mail',
      de: 'Schicken Sie uns eine E-Mail',
      en: 'Send us an email',
      fr: 'Envoyez-nous un courriel',
    },
    settings: {
      header: {
        ar: '',
        cs: '',
        de: '',
        en: 'Configuration settings (user side)',
        fr: '',
      },
    },
    signingIn: {
      signingInHeader: {
        ar: '',
        cs: '',
        de: '',
        en: '1.3 Signing in',
        fr: '',
      },
      signingInHeaderDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'Sign-in is handled using Azure Active Directory in the Microsoft Azure environment, which contains application users categorized into groups according to their classification to individual Tenants or to a KPMG user group.',
        fr: '',
      },
      signingInHeaderDescription2: {
        ar: '',
        cs: '',
        de: '',
        en: 'When the application loads, the custom login screen will appear.',
        fr: '',
      },
    },
    tutorialButtonTitle: {
      ar: 'الذهاب إلى قسم',
      cs: 'Přejít do sekce',
      de: 'Zur Sektion gehen',
      en: 'Go to section',
      fr: 'Aller à la section',
    },
    tutorialDiscovery: {
      ar: 'البرنامج التعليمي للاكتشاف',
      cs: 'Discovery Tutoriál',
      de: 'Discovery Anleitung',
      en: 'Discovery Tutorial',
      fr: 'Tutoriel de Discovery',
    },
    tutorialDownload: {
      ar: 'تحميل دليل المستخدم',
      cs: 'Stáhnout uživatelskou příručku',
      de: 'Benutzerhandbuch herunterladen',
      en: 'Download user manual',
      fr: 'Télécharger le manuel d’utilisation',
    },
    tutorialExportImportDescription: {
      ar: 'هل تعلم أنه يمكنك تصدير الرحلات واستيرادها، بحيث أنه يمكنك العمل بها أو تقديمها في أي مكان؟',
      cs: 'Věděli jste, že můžete exportovat a importovat cesty, abyste s nimi mohli pracovat nebo je prezentovat, kdekoliv potřebujete?',
      de: 'Wussten Sie, dass Sie Pfade exportieren und importieren können, um mit diesen arbeiten oder sie präsentieren zu können, wo auch immer vonnöten?',
      en: 'Did you know you can export and import journeys, so that you can work with or present them anywhere?',
      fr: 'Saviez-vous que vous pouvez exporter et importer des parcours, afin de pouvoir travailler avec eux ou les présenter n’importe où ?',
    },
    tutorialExportImportTitle: {
      ar: 'التصدير/ الاستراد',
      cs: 'Export/Import',
      de: 'Export/Import',
      en: 'Export/Import',
      fr: 'Exportation/importation',
    },
    tutorialGoToReport: {
      ar: 'الذهاب إلى نموذج الإبلاغ عن الأخطاء',
      cs: 'Přejít na formulář hlášení chyby',
      de: 'Zum Fehlerberichtsformular gehen',
      en: 'Go to a bug report form',
      fr: 'Accéder à un formulaire de rapport de bogue',
    },
    tutorialIntroductionDescription: {
      ar: 'تعرف على أساسيات Discovery بدءاً من تسجيل الدخول وحتى التفاعل مع العناصر والمصطلحات الأساسية التي ستحتاج إليها. ',
      cs: 'Naučte se základy Discovery od přihlášení až po práci se základními prvky a termíny, které budete potřebovat.',
      de: 'Lernen Sie die Grundlagen von Discovery, von der Anmeldung bis zur Arbeit mit den grundlegenden Elementen und Begriffen, die Sie benötigen werden.',
      en: 'Learn the basics of Discovery from signing in to interacting with basic elements and terms you’ll need.',
      fr: 'Apprenez les bases de Discovery, de la connexion à l’interaction avec les éléments de base et les termes dont vous aurez besoin.',
    },
    tutorialIntroductionDescriptionScreen: {
      ar: '',
      cs: '',
      de: '',
      en: 'KPMG Discovery is a comprehensive experience design and journey mapping tool designed to help your business consolidate all information from the far reaches of your organization into an comprehensive, organized, and simplified view to make the roadmap to your business transformation goals readable and actionable by everyone in your business.',
      fr: '',
    },
    tutorialIntroductionOptimized: {
      ar: '',
      cs: '',
      de: '',
      en: 'The application is not optimized for working with touch devices and devices with smaller viewports (Mobiles, tablets, ...). If the user comes to the app from such a device, they will see information that this device is not supported. In this case, a user is prevented from editing within journeys and personas. The application can still be used, although in these cases only in display mode.',
      fr: '',
    },
    tutorialIntroductionTitle: {
      ar: 'مقدمة',
      cs: 'Úvod',
      de: 'Startseite',
      en: 'Introduction',
      fr: 'Introduction',
    },
    tutorialIntroductionTitleCard: {
      ar: 'مقدمة التطبيق',
      cs: 'Úvod',
      de: 'Startseite',
      en: 'Application introduction',
      fr: 'Introduction de l’application',
    },
    tutorialIntroductionbrowsers: {
      ar: '',
      cs: '',
      de: '',
      en: 'The application is optimized for bellow mentioned browsers:',
      fr: '',
    },
    tutorialJourneysDescription: {
      ar: 'اكتشف ما هي رحلة العميل وكيفية إنشاءها وتحريرها مع جميع طبقاتها.',
      cs: 'Zjistěte, co je to zákaznická cesta, jak ji vytvořit a upravit se všemi jejími vrstvami.',
      de: 'Erfahren Sie, was eine Customer Journey ist, wie man sie erstellt und mit all ihren Layern bearbeitet.',
      en: 'Discover what is a customer journey and how to create and edit one along with all its layers.',
      fr: 'Découvrez ce qu’est un parcours client et comment en créer et en modifier un avec toutes ses couches.',
    },
    tutorialJourneysTitle: {
      ar: 'الرحلات',
      cs: 'Cesty',
      de: 'Pfade',
      en: 'Journeys',
      fr: 'Parcours',
    },
    tutorialJourneysTitleScreen: {
      ar: '',
      cs: '',
      de: '',
      en: 'Creation and editing of a journey',
      fr: '',
    },
    tutorialMoreHelpDescription: {
      ar: 'لم تجد ما كنت تبحث عنه؟',
      cs: 'Nenašli jste, co jste hledali?',
      de: 'Haben Sie nicht gefunden, wonach Sie gesucht haben?',
      en: "Didn't find what you were looking for?",
      fr: 'Vous n’avez pas trouvé ce que vous cherchiez ?',
    },
    tutorialMoreHelpLink: {
      ar: 'تحقق من المعلومة في دليل المستخدم الكامل.',
      cs: 'Prohlédněte si kompletní uživatelskou příručku.',
      de: 'Sehen Sie sich das vollständige Benutzerhandbuch an.',
      en: 'Check the full user manual.',
      fr: 'Consultez le manuel d’utilisation complet.',
    },
    tutorialMoreHelpTitle: {
      ar: 'هل تحتاج إلى المزيد من المساعدة؟',
      cs: 'Potřebujete další pomoc?',
      de: 'Benötigen Sie weitere Hilfe?',
      en: 'Need more help?',
      fr: 'Besoin d’aide ?',
    },
    tutorialNavigationDescription: {
      ar: 'تدرب على الإنشاء والتنقل عبر الأقسام الخاصة بـ Discovery بدءاً من المستأجر وحتى الرحلة . ',
      cs: 'Získejte přehled o vytváření a pohybu v jednotlivých sekcích Discovery od tenanta po cestu.',
      de: 'Verschaffen Sie sich einen Überblick über das Erstellen und Bewegen in den einzelnen Discovery-Sektionen, von Tenant bis zum Pfad.',
      en: 'Get a hang of creating and moving through Discovery’s respective sections from the tenant to the journey.',
      fr: 'Apprenez à créer et à vous déplacer dans les différentes sections de Discovery, du tenant au parcours.',
    },
    tutorialNavigationTitle: {
      ar: 'الملاحة',
      cs: 'Navigace',
      de: 'Navigation',
      en: 'Navigation',
      fr: 'Navigation',
    },
    tutorialNavigationTitleScreen: {
      ar: '',
      cs: '',
      de: '',
      en: 'Navigation and managing basic building blocks',
      fr: '',
    },
    tutorialPersonasDescription: {
      ar: 'كل ما تحتاج لمعرفته حول الشخصيات وكيفية إنشائها وتحريرها وإدارتها واستخدامها في الرحلات.',
      cs: 'Vše, co potřebujete vědět o personách, jak je vytvořit, upravit a spravovat a jak je využít v cestách.',
      de: 'Alles wissenswerte über die Personas, wie man sie erstellt, bearbeitet und verwaltet und wie man von ihnen in den Pfaden Gebrauch macht.',
      en: 'All you need to know about personas, how to create, edit and manage them and utilise them in journeys.',
      fr: 'Tout ce que vous devez savoir sur les personas, comment les créer, les éditer, les gérer et les utiliser dans les parcours.',
    },
    tutorialPersonasDescriptionScreen: {
      ar: '',
      cs: '',
      de: '',
      en: 'A persona is a semi-fictional representation of your ideal customer, his/her backgrounds, goals and challenges, based on market research and real data about your existing customers.',
      fr: '',
    },
    tutorialPersonasTitle: {
      ar: 'الشخصيات',
      cs: 'Persony',
      de: 'Personas',
      en: 'Personas',
      fr: 'Personas',
    },
    tutorialPersonasTitleScreen: {
      ar: '',
      cs: '',
      de: '',
      en: 'Creation and editing of a persona',
      fr: '',
    },
    tutorialReport: {
      ar: 'الإبلاغ عن مشكلة',
      cs: 'Reportovat chybu',
      de: 'Fehler melden',
      en: 'Report an issue',
      fr: 'Signaler un problème',
    },
    tutorialReportDescription: {
      ar: 'هل ترى خطأ أو خللاً في اكتشافك؟ إملاً استمارة قصيرة لإعلامنا بذلك ومساعدتنا في حل المشكلة قريباً. إذا كان لديك سؤال محدد، لا تتردد في مراسلتنا عبر البريد الالكتروني.',
      cs: 'Vidíte ve svém Discovery chybu nebo závadu? Vyplňte krátký formulář a dejte nám vědět a pomozte nám to vyřešit za vás dříve. V případě konkrétního dotazu nám neváhejte napsat.',
      de: 'Sehen Sie einen Fehler oder eine Störung in Ihrem Discovery? Füllen Sie das kurze Formular aus, um es uns mitzuteilen und uns zu helfen, das Problem für Sie schneller zu beheben. Wenn Sie eine konkrete Frage haben, zögern Sie nicht, uns zu schreiben.',
      en: 'See a bug or a glitch in your Discovery? Fill in a short form to let us know and help us solve it for you sooner. In case of specific question, feel free to email us.',
      fr: 'Vous avez constaté un bogue ou un problème dans votre Discovery ? Remplissez un court formulaire pour nous en informer et nous aider à résoudre le problème plus rapidement. En cas de questions spécifiques, n’hésitez pas à nous envoyer un courriel.',
    },
    tutorialReportPromiseDescription: {
      ar: 'نوعدك بأننا سنهتم بما يزعجك.',
      cs: 'Slibujeme, že se postaráme o to, co vás trápí.',
      de: 'Wir versprechen, dass wir uns um Ihre Sorgen kümmern.',
      en: 'We promise we’ll take care of what’s bugging you.',
      fr: 'Nous vous promettons de nous occuper de ce qui vous tracasse.',
    },
    tutorialScreenTitle: {
      ar: 'المساعدة والدعم ',
      cs: 'Pomoc a podpora',
      de: 'Hilfe und Unterstützung',
      en: 'Help and Support',
      fr: 'Aide et soutien',
    },
    tutorialScreenTitleDescription: {
      ar: 'هل تحتاج إلى مساعدة أو توجيه؟ سواء كنت بحاجة إلى معرفة المزيد عن الخطأ أو تريد توصيل التنبيه عنه لنا، فنحن هنا في خدمتك.',
      cs: 'Potřebujete pomoc nebo vedení? Ať už se potřebujete dozvědět nebo nás upozornit na chybu, jsme tu pro vás.',
      de: 'Brauchen Sie Hilfe oder Anleitung? Egal, ob Sie eine Information benötigen oder uns auf einen Fehler aufmerksam machen wollen, wir sind für Sie da.',
      en: 'Need help or guidance? Whether you need to learn or alert us about a bug, we’re here for you.',
      fr: 'Besoin d’aide ou de conseils ? Que vous ayez besoin de conseils ou de nous signaler un bogue, nous sommes là pour vous.',
    },
    tutorialSettingsDescription: {
      ar: 'تعرّف على ماذا وأين يمكنك تكوينه كمستخدم، حتى يعمل الاكتشاف Discovery تماماً حسب رغبتك.',
      cs: 'Zjistěte, co a kde můžete jako uživatel konfigurovat, aby Discovery fungovalo přesně podle vašich představ.',
      de: 'Finden Sie heraus, was und wo Sie als Benutzer konfigurieren können, damit Discovery genau so funktioniert, wie sie es sich wünschen.',
      en: 'Learn what and where you can configure as a user, so that Discovery works exactly to your liking.',
      fr: 'Découvrez ce que vous pouvez configurer en tant qu’utilisateur et où vous pouvez le faire, afin que Discovery fonctionne exactement comme vous le souhaitez.',
    },
    tutorialSettingsTitle: {
      ar: 'الإعداد',
      cs: 'Nastavení',
      de: 'Einstellung',
      en: 'Settings',
      fr: 'Paramètres',
    },
    tutorialSubtitleDescription: {
      ar: 'في هذا البرنامج  التعليمي ستجد إجابات تخص استخدام KPMG Discovery بما في ذلك إدارة الشخصية وإنشاء الرحلة وتصدير البيانات وغير ذلك الكثير. راجع الأقسام أدناه واختر الموضوع الذي تريد معرفة المزيد عنه.',
      cs: 'V tomto tutoriálu najdete odpovědi týkající se používání KPMG Discovery, včetně správy person, vytváření cest, exportu dat a mnoha dalších. Podívejte se na sekce níže a vyberte téma, o kterém se chcete dozvědět více.',
      de: 'In dieser Anleitung finden Sie Antworten zur Verwendung von KPMG Discovery, inklusive der Verwaltung von Personas, der Pfaderstellung, des Datenexports und vieles mehr. Sehen Sie sich die folgenden Sektionen an und wählen Sie das Thema aus, über das Sie mehr erfahren möchten.',
      en: 'In this tutorial, you will find answers regarding the usage of KPMG Discovery, including persona management, journey creation, data export, and much more. See the sections below and pick the topic you want to learn more about.',
      fr: 'Dans ce tutoriel, vous trouverez des réponses concernant l’utilisation de KPMG Discovery, y compris l’administration des personas, la création de parcours, l’exportation de données, et bien plus encore. Consultez les sections ci-dessous et choisissez le sujet sur lequel vous souhaitez en savoir plus.',
    },
    userRoles: {
      userRole: {
        ar: '',
        cs: '',
        de: '',
        en: '1.2 User roles',
        fr: '',
      },
      userRoleDefinition: {
        ar: '',
        cs: '',
        de: '',
        en: 'User roles define access permissions to data and features in an application. There are bellow mentioned roles in the application:',
        fr: '',
      },
      userRoleDescription: {
        ar: '',
        cs: '',
        de: '',
        en: 'The users roles and permissions are determined by:',
        fr: '',
      },
    },
  },
  usersKpmgManagement: {
    role: {
      ar: 'الدور',
      cs: 'Role',
      de: 'Aufgabe',
      en: 'Role',
      fr: 'Rôle',
    },
    user: {
      ar: 'المستخدم',
      cs: 'Uživatel',
      de: 'Benutzer',
      en: 'User',
      fr: 'Utilisateur',
    },
  },
  usersManagement: {
    admin: {
      ar: 'المشرف Admin',
      cs: 'Admin',
      de: 'Administrative',
      en: 'Admin',
      fr: 'Admin',
    },
    powerUser: {
      ar: 'مستخدم PowerUser',
      cs: 'PowerUser',
      de: 'PowerUser',
      en: 'PowerUser',
      fr: 'PowerUser',
    },
    role: {
      ar: 'الدور',
      cs: 'Role',
      de: 'Aufgabe',
      en: 'Role',
      fr: 'Rôle',
    },
    user: {
      ar: 'المستخدم',
      cs: 'Uživatel',
      de: 'Benutzer',
      en: 'User',
      fr: 'Utilisateur',
    },
  },
  wysiwyg: {
    linkModal: {
      openLinkIn: {
        newWindow: {
          ar: 'نافدة جديدة',
          cs: 'Nové okno',
          de: 'Neues Fenster',
          en: 'New window',
          fr: 'Nouvelle fenêtre',
        },
        title: {
          ar: 'فتح الرابط في',
          cs: 'Otevřit odkaz jako',
          de: 'Link öffnen als',
          en: 'Open link in',
          fr: 'Ouvrir le lien dans',
        },
      },
      title: {
        ar: 'إدراج أو تعديل رابط',
        cs: 'Vložit/Editovat odkaz',
        de: 'Link einfügen/bearbeiten',
        en: 'Insert/Edit link',
        fr: 'Insérer/modifier le lien',
      },
      url: {
        ar: 'عنوان URL',
        cs: 'Odkaz',
        de: 'Link',
        en: 'URL',
        fr: 'URL',
      },
      urlName: {
        ar: 'نص للعرض',
        cs: 'Text odkazu',
        de: 'Link-Text',
        en: 'Text to display',
        fr: 'Texte à afficher',
      },
    },
    maxCharError: {
      ar: 'تم تجاوز الحد الأقصى للأحرف{{count}}{{limit}}(/)!',
      cs: 'Překročen maximální počet znaků ({{count}}/{{limit}})!',
      de: 'Maximale Zeichenanzahl überschritten ({{count}}/{{limit}})!',
      en: 'Maximum characters exceeded ({{count}}/{{limit}})!',
      fr: 'Le nombre maximum de caractères a été dépassé ({{count}} / {{limit}}) !',
    },
  },
  yup: {
    array: {
      max: {
        ar: 'يجب أن يكون الحقل أقل من أو يساوي إلى {{max}} من العناصر',
        cs: 'Pole musí obsahova maximálně {{max}} položky',
        de: 'Das Feld darf höchstens {{max}} Elemente enthalten',
        en: 'Field must have less than or equal to {{max}} items',
        fr: "Le champ doit contenir un nombre d'éléments inférieur ou égal à {{max}}",
      },
      min: {
        ar: 'يجب أن يكون حقل على الأقل {{min}} من العناصر',
        cs: 'Pole musí obsahovat alespoň {{min}} položky',
        de: 'Das Feld muss mindestens {{min}} Elemente enthalten',
        en: 'Field must have at least {{min}} items',
        fr: 'Le champ doit contenir au moins {{min}} éléments',
      },
    },
    boolean: {
      isValue: {
        ar: 'يجب أن تكون {{value}}',
        cs: 'Musí být {{value}}',
        de: 'Es muss {{value}} sein.',
        en: 'Must be {{value}}',
        fr: 'Doit être {{value}}',
      },
    },
    date: {
      max: {
        ar: 'يجب أن يكون حقل في وقت سابق من {{max}}',
        cs: 'Musí být před {{max}}',
        de: 'Muss vor {{max}} stehen',
        en: 'Must be at earlier than {{max}}',
        fr: 'Doit être avant {{max}}',
      },
      min: {
        ar: 'يجب أن يكون حقل في وقت لاحق من {{min}}',
        cs: 'Musí být po {{min}}',
        de: 'Muss nach {{min}} folgen',
        en: 'Must be later than {{min}}',
        fr: 'Doit être après {{min}}',
      },
    },
    mixed: {
      default: {
        ar: 'غير صالح',
        cs: 'Hodnota je neplatná',
        de: 'Der Wert ist ungültig',
        en: 'Value is invalid',
        fr: 'Valeur invalide',
      },
      defined: {
        ar: 'يجب أن يتم تعريفها',
        cs: 'Musí být definováno',
        de: 'Es muss vordefiniert sein.',
        en: 'Must be defined',
        fr: 'Doit être défini',
      },
      notOneOf: {
        ar: 'لا يجب أن تكون واحدة من القيم التالية: {{values}}',
        cs: 'Nesmí obsahovat žádnou z následujících hodnot: {{values}}',
        de: 'Darf keinen der folgenden Werte enthalten: {{values}}',
        en: 'Must not be one of the following values: {{values}}',
        fr: "Ne doit pas être l'une des valeurs suivantes : {{values}}",
      },
      notType: {
        ar: 'نوع غير صالح',
        cs: 'Neplatný typ',
        de: 'Ungültiger Typ',
        en: 'Invalid type',
        fr: 'Type invalide',
      },
      oneOf: {
        ar: 'يجب أن تكون واحدة من القيم التالية: {{values}}',
        cs: 'Musí obsahovat jednu z následujících hodnot: {{values}}',
        de: 'Muss einen der folgenden Werte enthalten: {{values}}',
        en: 'Must be one of the following values: {{values}}',
        fr: "Doit être l'une des valeurs suivantes : {{values}}",
      },
      required: {
        ar: 'هو حقل مطلوب',
        cs: 'Je povinné',
        de: 'Ist ein Pflichtfeld',
        en: 'Is a required field',
        fr: 'Champ obligatoire',
      },
    },
    number: {
      integer: {
        ar: 'يجب أن يكون عدد صحيح رياضياً متكامل',
        cs: 'Musí být celé číslo',
        de: 'Muss eine ganze Zahl sein',
        en: 'Must be an integer',
        fr: 'Doit être un entier',
      },
      lessThan: {
        ar: 'يجب أن يكون أقل من {{less}}',
        cs: 'Musí být menší než {{less}}',
        de: 'Muss kleiner sein als {{less}}',
        en: 'Must be less than {{less}}',
        fr: 'Doit être inférieur à {{less}}',
      },
      max: {
        ar: 'يجب أن يكون أقل من أو يساوي {{max}}',
        cs: 'Musí být menší nebo rovno {{max}}',
        de: 'Muss kleiner als oder gleich wie {{max}} sein',
        en: 'Must be less than or equal to {{max}}',
        fr: 'Doit être inférieur ou égal à {{max}}',
      },
      min: {
        ar: 'يجب أن تكون أكبر من أو يساوي {{min}}',
        cs: 'Musí být větší nebo rovno {{min}}',
        de: 'Muss größer als oder gleich wie {{min}} sein',
        en: 'Must be greater than or equal to {{min}}',
        fr: 'Doit être supérieur ou égal à {{min}}',
      },
      moreThan: {
        ar: 'يجب أن تكون أكبر من {{more}}',
        cs: 'Musí být větší než {{more}}',
        de: 'Muss größer als {{more}} sein',
        en: 'Must be greater than {{more}}',
        fr: 'Doit être supérieur à {{more}}',
      },
      negative: {
        ar: 'يجب أن يكون رقما سالبا',
        cs: 'Musí být záporné číslo',
        de: 'Muss eine negative Zahl sein',
        en: 'Must be a negative number',
        fr: 'Doit être un nombre négatif',
      },
      positive: {
        ar: 'يجب أن يكون رقما موجبا',
        cs: 'Musí být kladné číslo',
        de: 'Muss eine positive Zahl sein',
        en: 'Must be a positive number',
        fr: 'Doit être un nombre positif',
      },
    },
    object: {
      noUnknown: {
        ar: 'حقل لا يمكن أن يكون مفاتيح غير محددة في شكل وجوه:{{unknown}}',
        cs: 'Nesmí obsahovat nespecifikované klíče: {{unknown}}',
        de: 'Darf keine unspezifizierten Schlüssel enthalten: {{unknown}}',
        en: 'Field has unspecified keys: {{unknown}}',
        fr: 'Le champ ne doit pas contenir de clés non spécifiées : {{unknown}}',
      },
    },
    string: {
      email: {
        ar: 'يجب أن يكون عنوان بريد إلكتروني صالح',
        cs: 'Musí být platná emailová adresa',
        de: 'Muss eine gültige E-Mail-Adresse enthalten',
        en: 'Must be a valid email',
        fr: 'Doit être un email valide',
      },
      length: {
        ar: 'يجب أن يكون بالضبط {{length}} حرفا',
        cs: 'Musí obsahovat přesně {{length}} znaků',
        de: 'Muss genau {{length}} Zeichen lang sein',
        en: 'Must be exactly {{length}} characters',
        fr: 'Doit être exactement {{length}} caractères',
      },
      lowercase: {
        ar: 'يجب أن تكون سلسلة صغيرة',
        cs: 'Musí obsahovat jen malá písmena',
        de: 'Darf nur Kleinschreibung enthalten',
        en: 'Must be a lowercase string',
        fr: 'Doit contenir uniquement des lettres minuscules',
      },
      matches: {
        ar: 'يجب أن يطابق ما يلي: "{{regex}}"',
        cs: 'Musí splňovat pravidlo: "{{regex}}"',
        de: 'Muss der Regel entsprechen: "{{regex}}"',
        en: 'Must match the following: "{{regex}}"',
        fr: 'Doit correspondre à ce qui suit : "{{regex}}"',
      },
      max: {
        ar: 'يجب أن تكون على الأكثر {{max}} حرفا',
        cs: 'Musí obsahovat maximálně {{max}} znaků',
        de: 'Darf höchstens {{max}} Zeichen lang sein',
        en: 'Must be at most {{max}} characters',
        fr: 'Doit être au plus {{max}} caractères',
      },
      min: {
        ar: 'يجب أن تكون على الأقل {{min}} حرفا',
        cs: 'Musí obsahovat minimálně {{min}} znaků',
        de: 'Muss mindestens {{min}} Zeichen lang sein',
        en: 'Must be at least {{min}} characters',
        fr: 'Doit être au moins {{min}} caractères',
      },
      trim: {
        ar: 'يجب أن تكون سلسلة قلص',
        cs: 'Nesmí obsahovat mezery',
        de: 'Darf keine Leerzeichen enthalten',
        en: 'Must be a trimmed string',
        fr: "Ne doit pas contenir d'espaces",
      },
      uppercase: {
        ar: 'يجب أن تكون سلسلة حالة الحروف الكبيرة',
        cs: 'Musí obsahovat jen velká písmena',
        de: 'Darf nur Großschreibung enthalten',
        en: 'Must be a upper case string',
        fr: 'Doit contenir uniquement des lettres majuscules',
      },
      url: {
        ar: 'يجب أن يكون عنوان URL صالح',
        cs: 'Musí být platná URL adresa',
        de: 'Muss eine gültige URL sein',
        en: 'Must be a valid URL',
        fr: 'Doit être une URL valide',
      },
      uuid: {
        ar: 'يجب أن يكون UUID صالح',
        cs: 'Musí to být platné UUID',
        de: 'Muss eine gültige UUID sein',
        en: 'Must be a valid UUID',
        fr: 'Doit être un UUID valide',
      },
    },
  },
}
